import { QueryKey } from '@tanstack/react-query'

import { PermissionsService } from '../../../openapi-generated/PermissionsService'

const staleTime = 10 * 60 * 1000

export const getPermissionsByOrganization = {
  queryKey: (organizationId?: string): QueryKey => ['permissions', organizationId],
  queryFn: async (organizationId: string) =>
    await PermissionsService.getOrganizationPermissions({ organizationId }),
  staleTime,
}

export const getPermissionsByReseller = {
  queryKey: (resellerHostname: string): QueryKey => ['resellerPermissions', resellerHostname],
  queryFn: async (resellerHostname: string) =>
    await PermissionsService.getResellerPermissions({ hostname: resellerHostname }),
  staleTime,
}
