import { t } from 'i18next'
import { toast, ToastContent, ToastContentProps } from 'react-toastify'

import { getErrorCodeFromAxios } from '@sherweb/core/utils/error'

export const infoNotification = (message: ToastContent, options?: ToastContentProps) => {
  toast.info(message, options)
}

export const successNotification = (message: ToastContent, options?: ToastContentProps) => {
  toast.success(message, options)
}

export const warningNotification = (message: ToastContent, options?: ToastContentProps) => {
  toast.warning(message, options)
}

export const errorNotification = (message: ToastContent, options?: ToastContentProps) => {
  toast.error(message, options)
}

export const showApiErrorToast = (errorArg: any, options?: ToastContentProps) => {
  const axiosError = getErrorCodeFromAxios(errorArg)

  if (axiosError) {
    let codeFromAxios: string | undefined = axiosError
    codeFromAxios = `core:errors.${codeFromAxios ?? 'generic'}`

    const errorTranslation = t(codeFromAxios as any)

    if (errorTranslation !== codeFromAxios) {
      errorNotification(t(errorTranslation), options)
      return
    }
  }

  errorNotification(t('core:errors.generic'), options)
}
