export enum CoreRoutes {
  InternalError = '/500',
  UnauthorizedAccess = '/401',
  AdminUnauthorizedAccess = '/admin/401',
  ForbiddenAccess = '/403',
  AdminNotFound = '/admin/404',
  NotFound = '/404',
  AdminForbiddenAccess = '/admin/403',
  Admin = '/admin',
}
