import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Card from '@sherweb/core/components/Card/Card'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import { Permission } from '@sherweb/core/modules/authorization'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import ServerError from '@ssp/components/ServerError/ServerError'
import { useSubscriptions } from '@ssp/modules/subscription'

import SubscriptionsTable from './SubscriptionsTable'
import SubscriptionsTableMobile from './SubscriptionsTableMobile'

const SubscriptionsPage: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: subscriptions = [], isFetched } = useSubscriptions()
  const noSubscriptions = isFetched && !isLoading && !error && !subscriptions?.length

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageSubscriptionsTitle">
              {t('ssp:pages.subscriptions.title')}
            </PageTitle>
          }
          subTitle={<PageSubTitle>{t('ssp:pages.subscriptions.pageDescription')}</PageSubTitle>}
        />
        {isLoading && <Spinner floating />}
        {error && <ServerError error={error} />}

        {noSubscriptions && (
          <div
            className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
            data-testid="containerNoSubscriptions"
          >
            <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
            <p>{t('ssp:pages.subscriptions.noSubscriptions')}</p>
          </div>
        )}

        {subscriptions?.length > 0 && (
          <>
            <div className="hidden lg:block" data-testid="containerSubscriptionsTable">
              <Card padded>
                <SubscriptionsTable />
              </Card>
            </div>

            <div className="mt-4 lg:hidden" data-testid="containerSubscriptionsTableMobile">
              <Card padded>
                <SubscriptionsTableMobile />
              </Card>
            </div>
          </>
        )}
      </div>
    </ContainedPage>
  )
}

export default protectPage(SubscriptionsPage, Permission.SubscriptionList)
