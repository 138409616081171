import { OrganizationHelpDeskService } from '@sherweb/core/openapi-generated/OrganizationHelpDeskService'
import { OrganizationTicketsService } from '@sherweb/core/openapi-generated/OrganizationTicketsService'

import { getLocalizedQueryKey } from '@sherweb/core/modules/reactQuery'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const helpDeskAccess = {
  queryKey: (organizationId?: string) => ['OrganizationHelpDeskService/getAccess', organizationId],
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await rejectMissingParameters()
    }

    return await OrganizationHelpDeskService.getAccess({ organizationId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const helpDeskTickets = {
  queryKey: (organizationId?: string) => ['OrganizationTicketsService/getTickets', organizationId],
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await rejectMissingParameters()
    }

    return await OrganizationTicketsService.getTickets({ organizationId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const organizationHelpDeskTicketTypes = {
  queryKey: (organizationId?: string) => [
    'OrganizationTicketsService/getOrganizationHelpDeskTicketTypes',
    organizationId,
  ],
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await rejectMissingParameters()
    }

    return await OrganizationTicketsService.getOrganizationHelpDeskTicketTypes({ organizationId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const organizationHelpDeskTicketStatuses = {
  queryKey: (organizationId?: string) =>
    getLocalizedQueryKey([
      'OrganizationTicketsService/getOrganizationHelpDeskTicketStatuses',
      organizationId,
    ]),
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await Promise.reject(new Error('Missing parameters'))
    }

    return await OrganizationTicketsService.getOrganizationHelpDeskTicketStatuses({
      organizationId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const organizationHelpDeskTicketDetail = {
  queryKey: (organizationId?: string, ticketId?: string) => [
    'OrganizationTicketsService/getTicketDetails',
    organizationId,
    ticketId,
  ],
  queryFn: async (organizationId?: string, ticketId?: string) => {
    if (!organizationId || !ticketId) {
      return await Promise.reject(new Error('Missing parameters'))
    }

    return await OrganizationTicketsService.getTicketDetails({ organizationId, ticketId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
