import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import { CreateOrderItemDto, Facet } from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationPurchaseOrdersService } from '@sherweb/core/openapi-generated/OrganizationPurchaseOrdersService'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { DEFAULT_TABLE_PAGE } from '@sherweb/core/utils/const'
import { missingParametersError } from '@sherweb/core/utils/error'

import { useSelectedOrganization, useSelectedOrganizationId } from '@ssp/modules/organization'

import { buildProduct } from './shop.builder'
import { DEFAULT_PAGE_SIZE, DEFAULT_SEARCH_OPTIONS } from './shop.const'
import { getMergedFacets, getNextPageParamForProducts } from './shop.helpers'
import { productById, search } from './shop.queries'
import { ISearchOptions } from './shop.types'

type UseGetProductsQueryProps = {
  searchOptions: ISearchOptions
  enabled?: boolean
}

type UseGetProductsFacetsReturnType = {
  data?: Facet[]
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

export const useGetProductsQuery = ({
  searchOptions,
  enabled = true,
}: UseGetProductsQueryProps) => {
  const { t } = useTranslation()
  const selectedOrganization = useSelectedOrganization()

  const { isLoggedIn } = useAuthenticationLoggedInState()

  return useInfiniteQuery({
    queryKey: search.queryKey(searchOptions, selectedOrganization?.id),
    queryFn: async ({ pageParam = DEFAULT_TABLE_PAGE }) =>
      await search.queryFn(searchOptions, pageParam, selectedOrganization?.id),
    enabled: isLoggedIn && !!selectedOrganization?.id && enabled,
    keepPreviousData: true,
    staleTime: search.staleTime,
    getNextPageParam: lastPage => getNextPageParamForProducts(lastPage, DEFAULT_PAGE_SIZE),
    meta: {
      errorMessage: t('ssp:errors.marketplace.generic'),
    },
  })
}

export const useGetProductsFacetsQuery = ({
  searchOptions,
  enabled = true,
}: UseGetProductsQueryProps): UseGetProductsFacetsReturnType => {
  const updatedSearchOptions = {
    ...DEFAULT_SEARCH_OPTIONS,
    ...searchOptions,
  }

  const initialProductQuery = useGetProductsQuery({
    searchOptions: DEFAULT_SEARCH_OPTIONS,
    enabled: !isEqual(DEFAULT_SEARCH_OPTIONS, updatedSearchOptions) && enabled,
  })

  const productQuery = useGetProductsQuery({
    searchOptions: updatedSearchOptions,
    enabled,
  })

  return {
    isLoading: initialProductQuery?.isLoading || productQuery?.isLoading,
    isError: initialProductQuery?.isError || productQuery?.isError,
    isSuccess: initialProductQuery?.isSuccess && productQuery?.isSuccess,
    data: getMergedFacets(
      initialProductQuery?.data?.pages?.[0]?.facets,
      productQuery?.data?.pages?.[0]?.facets
    ),
  }
}

export const useGetProductById = (productId?: string) => {
  return useQuery({
    queryKey: productById.queryKey(productId),
    queryFn: async () => await productById.queryFn(productId),
    enabled: !!productId,
    staleTime: productById.staleTime,
    select: data => buildProduct(data),
  })
}

export const usePlaceOrderMutation = () => {
  const selectedOrganizationId = useSelectedOrganizationId()

  return useMutation({
    mutationFn: async (data: { serviceProviderId?: string; orderItems: CreateOrderItemDto[] }) => {
      if (!data.serviceProviderId || !selectedOrganizationId) {
        throw missingParametersError()
      }

      if (data.orderItems.length === 0) {
        throw new Error('Must provide at least one order item')
      }

      await OrganizationPurchaseOrdersService.placeShoppingOrder({
        organizationId: selectedOrganizationId,
        command: {
          serviceProviderId: data.serviceProviderId,
          orderItems: data.orderItems,
        },
      })
    },
    // Empty onError need to not show by-default error toas
    onError: () => {},
  })
}
