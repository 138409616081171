import { useTranslation } from 'react-i18next'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { Permission } from '@sherweb/core/modules/authorization'
import { usePortalMetadata } from '@sherweb/core/modules/portalMetadata'
import { useCurrentUser } from '@sherweb/core/modules/user'

import { protectPage } from '@ssp/app/ProtectedPage'
import { SubscriptionCountWidget, UserCountWidget } from '@ssp/components/DashboardWidgets'
import CurrentUserLicenseCountWidget from '@ssp/components/DashboardWidgets/CurrentUserLicenseCountWidget'
import UnusedLicenseCountWidget from '@ssp/components/DashboardWidgets/UnusedLicenseCountWidget'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import { HasAccess } from '@ssp/modules/authorization'

const DashboardPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: user } = useCurrentUser()
  const { data: portalMetadata } = usePortalMetadata()
  const resellerName = portalMetadata?.resellerName
  const userFirstName = user?.firstName

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageDashboardTitle">
              {t('ssp:pages.dashboard.title', { userFirstName })}
            </PageTitle>
          }
          subTitle={
            <PageSubTitle>
              {t('ssp:pages.dashboard.pageDescription', { resellerName })}
            </PageSubTitle>
          }
        />
        <div
          className="grid grid-cols-1 gap-4 lg:gap-6 xl:grid-cols-1"
          data-testid="containerDashboard"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2  lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
            <HasAccess permission={Permission.UsersList}>
              <UserCountWidget />
            </HasAccess>
            <HasAccess permission={Permission.SubscriptionList}>
              <SubscriptionCountWidget />
            </HasAccess>
            <HasAccess permission={Permission.LicenseList}>
              <CurrentUserLicenseCountWidget />
              <UnusedLicenseCountWidget />
            </HasAccess>
          </div>
        </div>
      </div>
    </ContainedPage>
  )
}

export default protectPage(DashboardPage)
