import React from 'react'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'

import { mergeClassName } from '../../utils/mergeClassName'

interface SidebarMenuItemProps extends DataTestId {
  children?: React.ReactNode
  Icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>
  current?: boolean
}

const SidebarMenuItem = ({ children, Icon, current, dataTestId }: SidebarMenuItemProps) => {
  return (
    <div
      className={mergeClassName(
        'hover:bg-slate-800 group flex items-center rounded-md px-4 py-2 antialiased md:subpixel-antialiased text-sm font-normal text-slate-300 hover:text-slate-200 dark:hover:bg-slate-800',
        current &&
          'bg-secondary hover:bg-secondary-600 text-white dark:bg-slate-900 dark:bg-secondary dark:hover:bg-secondary-600 dark:text-white'
      )}
      data-testid={`${dataTestId ?? ''} ${current ? 'menuItemCurrent' : ''}`}
    >
      {Icon && <Icon className={mergeClassName('mr-3 h-6 w-6 shrink-0 stroke-1')} />}
      <span>{children}</span>
    </div>
  )
}

export default SidebarMenuItem
