import { useTranslation } from 'react-i18next'

import { InvoiceStatus as InvoicesStatusType } from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'

type InvoiceStatusProps = {
  invoiceStatus?: InvoicesStatusType | null
}

export const InvoiceStatus = ({ invoiceStatus }: InvoiceStatusProps) => {
  const { t } = useTranslation()

  const statusBadgeMapper = {
    [InvoicesStatusType.Paid]: (
      <Badge variant={Variant.Success}>{t('core:invoices.status.paid')}</Badge>
    ),
    [InvoicesStatusType.Unpaid]: (
      <Badge variant={Variant.Warning}>{t('core:invoices.status.unpaid')}</Badge>
    ),
    [InvoicesStatusType.Overdue]: (
      <Badge variant={Variant.Danger}>{t('core:invoices.status.overdue')}</Badge>
    ),
  }

  return invoiceStatus ? statusBadgeMapper[invoiceStatus] : null
}
