import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Badge, { Variant } from '@sherweb/core/components/Badge'
import Combobox from '@sherweb/core/components/Combobox'
import { ComboboxOptionProps } from '@sherweb/core/components/Combobox/types'

import { useNavigate } from '@ssp/modules/navigation'
import { Organization } from '@ssp/modules/organization/core/organization.model'

import {
  useAvailableOrganizations,
  useOrganizationPathSwitcher,
  useSelectedOrganization,
} from '../../core/organization.hooks'
import { OrganizationName } from './OrganizationName'

const OrganizationSwitcher: React.FC = () => {
  const { data: organizations } = useAvailableOrganizations()

  const selectedOrganization = useSelectedOrganization()

  const { t } = useTranslation()

  const getPath = useOrganizationPathSwitcher()

  const { navigate } = useNavigate()

  const getLabelToDisplay = (organization: Organization): ReactNode => {
    if (organization.isSuspended) {
      return (
        <>
          {organization.name}
          <Badge variant={Variant.Warning} className="ml-4">
            {t('ssp:organization.suspended')}
          </Badge>
        </>
      )
    }

    return organization.name
  }

  const groupedOrganizations = organizations.reduce<Record<string, ComboboxOptionProps[]>>(
    (organizationMapper, org) => {
      const mappedOrg = {
        ...org,
        label: getLabelToDisplay(org),
      }

      if (!Object.hasOwn(organizationMapper, org.subreseller)) {
        organizationMapper[org.subreseller] = [mappedOrg]
      } else {
        organizationMapper[org.subreseller] = [...organizationMapper[org.subreseller], mappedOrg]
      }

      return organizationMapper
    },
    {}
  )

  const options = useMemo(() => {
    return Object.fromEntries(
      organizations.map((organization: Organization) => [organization.id, organization.name])
    )
  }, [organizations])

  const onChange = (organizationId?: string) => {
    const selectedOrganization = organizations.find(
      ({ id }) => id === organizationId
    ) as Organization
    navigate(getPath(selectedOrganization.uniqueName))
  }

  if (Object.keys(options).length === 1) {
    return <OrganizationName organization={organizations[0]} />
  }

  return (
    <Combobox<ComboboxOptionProps>
      groupOptions={groupedOrganizations}
      searcheable
      value={selectedOrganization?.id}
      onChange={onChange}
      buttonClassName="border p-2 border-slate-800 lg:border-slate-200 dark:border dark:border-slate-800 shadow-none hover:bg-transparent hover:shadow-none focus-visible:outline-offset-0 focus-visible:outline-indigo-300 capitalize bg-transparent hover:bg-slate-800  lg:hover:bg-slate-50 dark:hover:bg-slate-900/50 dark:bg-slate-950s"
      buttonValueClassName="text-white lg:text-gray-900 text-sm max-w-[calc(100vw-240px)] lg:max-w-[calc(100vw-450px)] truncate  dark:text-white"
      buttonTitle="Organization switch combobox button"
      listClassName="max-h-[calc(100vh-112px)] lg:max-h-[600px] overflow-auto text-gray-900 font-regular"
      icon={
        <BuildingOfficeIcon className="h-4 w-4 text-white lg:text-gray-900 dark:lg:text-white" />
      }
      dataTestId="organization"
    />
  )
}

export default OrganizationSwitcher
