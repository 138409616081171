/* eslint-disable react-hooks/exhaustive-deps */
import { HubConnection } from '@microsoft/signalr'
import { useEffect } from 'react'

import { SspHubKeys } from '@sherweb/core/openapi-generated/index.defs'

import { useSelectedOrganizationId } from '@ssp/modules/organization'
import { useReseller } from '@ssp/modules/reseller'

export const useHubSender = (connection: null | HubConnection) => {
  const selectedOrgId = useSelectedOrganizationId()
  const reseller = useReseller()

  useEffect(() => {
    if (connection && selectedOrgId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      connection.send(SspHubKeys.AssignToOrganizationGroup, selectedOrgId)
    }
  }, [connection?.connectionId, selectedOrgId])

  useEffect(() => {
    if (connection && reseller?.data?.id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      connection.send(SspHubKeys.AssignToResellerGroup, reseller?.data?.id)
    }
  }, [connection?.connectionId, reseller?.data?.id])
}
