import { useTranslation } from 'react-i18next'

import { InvoiceStatus } from '@sherweb/core/openapi-generated/index.defs'

import { ScrollArea } from '@sherweb/core/components/ScrollArea'
import { Typography } from '@sherweb/core/components/Typography'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import {
  useGetInvoiceDetailsAsyncQuery,
  useGetPaymentMethodsByOrganizationIdQuery,
} from '@ssp/modules/invoices/core/invoices.hooks'

import { InvoiceDetailLayout } from './components/InvoiceDetailLayout'
import { InvoiceDetailPaymentMethodContainer } from './components/InvoiceDetailPaymentMethodContainer/InvoiceDetailPaymentMethodContainer'
import { InvoiceDetailSkeleton } from './components/InvoiceDetailSkeleton'
import { useGetPagetParams } from './hooks/useGetPagetParams'

export const InvoiceDetailContent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language)

  const formatMoneyAmount = useMoneyFormatter(language)

  const { invoiceId, integrationType } = useGetPagetParams()

  const invoiceDetailsAsyncQuery = useGetInvoiceDetailsAsyncQuery(invoiceId, integrationType)

  const isInvoiceNotPaid = invoiceDetailsAsyncQuery?.data?.status !== InvoiceStatus.Paid

  const paymentMethodsByOrganizationIdQuery = useGetPaymentMethodsByOrganizationIdQuery({
    enabled: isInvoiceNotPaid,
  })

  return (
    <ScrollArea className="flex flex-col overflow-y-auto">
      <div className="mx-auto flex max-w-4xl flex-col justify-between p-6">
        {isRequestLoading(invoiceDetailsAsyncQuery) ||
        isRequestLoading(paymentMethodsByOrganizationIdQuery) ? (
          <InvoiceDetailSkeleton />
        ) : (
          <div className="flex-col">
            <div className="flex flex-col">
              <div className="flex flex-col py-4">
                <Typography variant="heading6" weight="medium" className="mb-4">
                  {t('ssp:pages.invoices.detail.summary')}
                </Typography>
                <InvoiceDetailLayout className="grid grid-cols-12 gap-1 md:gap-8">
                  <InvoiceDetailLayout.Column className="col-span-6">
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.customer')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {invoiceDetailsAsyncQuery?.data?.externalClientName}
                      </InvoiceDetailLayout.Content>
                    </div>
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.dueDate')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {formatDate(invoiceDetailsAsyncQuery?.data?.dueDate)}
                      </InvoiceDetailLayout.Content>
                    </div>
                  </InvoiceDetailLayout.Column>
                  <div className="col-span-1" />
                  <InvoiceDetailLayout.Column className="col-span-5">
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.invoiceDate')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {formatDate(invoiceDetailsAsyncQuery?.data?.createdOn)}
                      </InvoiceDetailLayout.Content>
                    </div>
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.invoiceTotal')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content>
                        {formatMoneyAmount(invoiceDetailsAsyncQuery?.data?.amountDue)}
                      </InvoiceDetailLayout.Content>
                    </div>
                  </InvoiceDetailLayout.Column>
                </InvoiceDetailLayout>
              </div>
            </div>
            {isInvoiceNotPaid ? (
              <div className="flex flex-col gap-4 py-4" data-testid="containerTransaction">
                <Typography variant="heading6" weight="medium" className="md:mb-4">
                  {t('ssp:pages.invoices.detail.transactions')}
                </Typography>
                <InvoiceDetailLayout className="grid grid-cols-12 md:mt-0 md:gap-8">
                  <div className="col-span-12 md:col-span-6 md:mt-0">
                    <InvoiceDetailLayout.Label className="flex">
                      {t('ssp:pages.invoices.detail.paymentMethod')}
                    </InvoiceDetailLayout.Label>
                    <InvoiceDetailPaymentMethodContainer />
                  </div>
                  <div className="hidden md:col-span-1 md:block" />
                  <InvoiceDetailLayout.Column className="col-span-5 mt-4 md:mt-0">
                    <div>
                      <InvoiceDetailLayout.Label>
                        {t('ssp:pages.invoices.detail.amountDue')}
                      </InvoiceDetailLayout.Label>
                      <InvoiceDetailLayout.Content variant="heading5" weight="bold">
                        {formatMoneyAmount(invoiceDetailsAsyncQuery?.data?.amountRemaining)}
                      </InvoiceDetailLayout.Content>
                    </div>
                  </InvoiceDetailLayout.Column>
                </InvoiceDetailLayout>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </ScrollArea>
  )
}
