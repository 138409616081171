import { useEffect, useState } from 'react'

import { DEFAULT_DEBOUNCE_MS } from '@sherweb/core/utils/const'

export type DebouncedSearchInputProps = {
  value: string
  onChange: (value: string) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>

export const DebouncedSearchInput: React.FC<DebouncedSearchInputProps> = ({
  value: initialValue,
  onChange,
  debounce = DEFAULT_DEBOUNCE_MS,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />
}
