export type OrderCreatorProps = {
  firstName?: string | null
  lastName?: string | null
  email?: string | null
}

export const OrderCreator = ({ firstName, lastName, email }: OrderCreatorProps) => (
  <div className="flex flex-row items-center gap-4">
    <div className="mb-1 flex flex-col justify-between">
      {email ? (
        <>{email}</>
      ) : (
        <>
          {firstName} {lastName}
        </>
      )}
    </div>
  </div>
)
