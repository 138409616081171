/* eslint-disable @typescript-eslint/no-misused-promises */
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { StepWizardChildProps } from 'react-step-wizard'

import Button from '@sherweb/core/components/Button'

import SSpRoutes from '@ssp/app/Routes'

export type BootstrapingStepProps = Partial<StepWizardChildProps>

const BootstrapingStep: React.FC<BootstrapingStepProps> = ({ ...props }) => {
  const { t } = useTranslation()

  const redirectToRoot = () => {
    window.location.href = SSpRoutes.Root
  }

  return (
    <div className="mt-6 flex flex-col gap-8 md:mt-12" data-testid="bootstraping-step">
      <div className="flex flex-col gap-4 md:gap-9">
        <div className="flex justify-center">
          <div className="flex h-14 w-14 items-center justify-center rounded-full bg-slate-200 dark:border dark:border-slate-800 dark:bg-slate-950 md:h-28 md:w-28">
            <CheckIcon className="h-12 w-12 stroke-2 text-white md:h-24 md:w-24" />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-center text-3xl font-medium text-slate-900 dark:text-slate-200">
            {t('rsp:pages.onboarding.onboardingComplete.title')}
          </h1>
          <p className="text-center text-sm text-slate-500 dark:text-slate-200">
            {t('rsp:pages.onboarding.onboardingComplete.description')}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-0 text-sm dark:text-slate-100 md:px-16">
        <div className="flex items-center gap-2">
          <CheckCircleIcon className="ml-2 h-8 w-8 shrink-0 text-gray-900 dark:text-white" />
          {t('rsp:pages.onboarding.onboardingComplete.labels.seeOrganizations')}
        </div>
        <div className="flex items-center gap-2">
          <CheckCircleIcon className="ml-2 h-8 w-8 shrink-0 text-gray-900 dark:text-white" />
          {t('rsp:pages.onboarding.onboardingComplete.labels.inviteCustomers')}
        </div>
        <div className="flex items-center gap-2">
          <CheckCircleIcon className="ml-2 h-8 w-8 shrink-0 text-gray-900 dark:text-white" />
          {t('rsp:pages.onboarding.onboardingComplete.labels.moreFeature')}
        </div>
        <div className="mt-8 flex flex-row justify-center">
          <Button type="button" onClick={redirectToRoot} data-testid="btnAccessSelfServicePortal">
            {t('rsp:pages.onboarding.complete')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BootstrapingStep
