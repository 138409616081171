export type ColorParam = Color | string | ArrayLike<number> | number | Record<string, any>

export enum ColorVariable {
  Primary = 'primary',
  Secondary = 'secondary',
  BgGradient = 'bgGradient',
}

export type Color = {
  light: ColorParam
  dark?: ColorParam
}

export type Theme = Record<ColorVariable, Color>
