import { X } from 'lucide-react'
import React, { Dispatch, forwardRef, InputHTMLAttributes, SetStateAction, useState } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'
import Button from '../Button'
import { FormInput } from '../Form'

interface TagInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string
  tags: string[]
  setTags: Dispatch<SetStateAction<string[]>>
}

export const TagInput = forwardRef<HTMLInputElement, TagInputProps>(
  ({ placeholder, tags, setTags, className = '', ...restProps }, ref) => {
    const [inputValue, setInputValue] = useState('')

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      setInputValue(e.target.value)

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === ',') {
        e.preventDefault()

        const newTag = inputValue.trim()

        if (newTag && !tags.includes(newTag)) {
          setTags([...tags, newTag])
        }

        setInputValue('')
      }
    }

    const removeTag = (tagToRemove: string) => setTags(tags.filter(tag => tag !== tagToRemove))

    return (
      <div>
        <div
          className={`flex w-full flex-wrap gap-2 rounded-md ${tags.length !== 0 ? 'mb-2' : ''}`}
        >
          {tags.map(tag => (
            <span
              key={tag}
              className="inline-flex h-8 items-center rounded-md border bg-indigo-600 pl-2 text-sm text-white hover:bg-indigo-700"
            >
              {tag}
              <Button
                type="button"
                variant="None"
                onClick={() => removeTag(tag)}
                data-testid="remove-tag"
                className={mergeClassName('p-2 text-white h-full')}
              >
                <X size={16} />
              </Button>
            </span>
          ))}
        </div>
        <FormInput
          {...restProps}
          ref={ref}
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className={className}
        />
      </div>
    )
  }
)

TagInput.displayName = 'TagInput'
