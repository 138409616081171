import { FieldError } from 'react-hook-form/dist/types'

export const TICKET_ROWS_PER_PAGE = 10
export const TITLE_MAX_CHARACTERS = 100
export const DESCRIPTION_MAX_CHARACTERS = 700
export const MAX_CHARACTERS_ERROR_TYPE = 'too_big'

export const isMaxCharacterError = (error?: FieldError) => error?.type === MAX_CHARACTERS_ERROR_TYPE

export const lockBodyScroll = () => {
  document.body.setAttribute('data-scroll-locked', 'true')
}
