import * as z from 'zod'

const customizationsSettingsSchema = z.object({
  logo: z.custom<File>().nullable(),
  logoUrl: z.string(),
  favicon: z.custom<File>().nullable(),
  faviconUrl: z.string(),
})

export default customizationsSettingsSchema
