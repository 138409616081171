import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Card from '@sherweb/core/components/Card'
import { Checkbox } from '@sherweb/core/components/Checkbox'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  formMessageVariants,
} from '@sherweb/core/components/Form'
import List, { listItemVariants } from '@sherweb/core/components/List'
import Spinner from '@sherweb/core/components/Spinner'
import { Typography } from '@sherweb/core/components/Typography'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import { useGetConnectWiseTicketTypesQuery } from '@rsp/modules/integrations/helpdesk'

import { hasTicketBeenConfigured } from './boardConfiguration.helper'
import { BoardConfigurationFormType } from './hooks/useBoardConfigurationSchema'

export const BoardConfigurationTicketTypes = () => {
  const { t } = useTranslation()

  const form = useFormContext<BoardConfigurationFormType>()

  const [serviceBoard, ticketTypes] = form.watch(['serviceBoard', 'ticketTypes'])

  const connectWiseTicketTypesQuery = useGetConnectWiseTicketTypesQuery(serviceBoard)

  const configuredTicketTypes = serviceBoard ? ticketTypes?.[serviceBoard] : []

  const isConnectWiseTicketTypesQueryLoading =
    connectWiseTicketTypesQuery?.isLoading || !connectWiseTicketTypesQuery?.data

  const ticketTypesError = form.formState.errors.ticketTypes?.[serviceBoard ?? '']

  if (!serviceBoard) {
    return null
  }

  if (isConnectWiseTicketTypesQueryLoading) {
    return <Spinner floating />
  }

  if (connectWiseTicketTypesQuery?.data?.ticketTypes?.length === 0) {
    return null
  }

  return (
    <FormSplitScreen.Row
      contentContainerClassName="md:max-w-md max-w-full"
      leftContent={
        <FormSplitScreen.LeftContent
          label={t(
            'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.ticketTypes.title'
          )}
          subLabel={t(
            'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.ticketTypes.description'
          )}
        />
      }
    >
      <Card padded={false} className="px-6 py-8" data-testid="containerHelpdeskTicketTypes">
        <List>
          {connectWiseTicketTypesQuery?.data?.ticketTypes?.map(ticketType => (
            <List.Item
              key={ticketType.integrationId}
              className={mergeClassName('p-0', {
                'bg-indigo-100 dark:text-slate-200': hasTicketBeenConfigured(
                  ticketType.integrationId,
                  configuredTicketTypes
                ),
              })}
            >
              <FormField
                key={`ticketTypes.${serviceBoard}`}
                control={form.control}
                name={`ticketTypes.${serviceBoard}`}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel className={listItemVariants({ className: 'space-x-0' })}>
                      <List.Actions>
                        <FormControl>
                          <Checkbox
                            data-testid="checkboxTicketType"
                            variant="secondary"
                            checked={hasTicketBeenConfigured(
                              ticketType.integrationId,
                              configuredTicketTypes
                            )}
                            onCheckedChange={checked => {
                              checked
                                ? field.onChange([...(field.value ?? []), ticketType.integrationId])
                                : field.onChange(
                                    field.value?.filter(value => value !== ticketType.integrationId)
                                  )
                            }}
                          />
                        </FormControl>
                      </List.Actions>
                      <Typography variant="body2" weight="default" className="flex">
                        {ticketType.name}
                      </Typography>
                    </FormLabel>
                  </FormItem>
                )}
              />
            </List.Item>
          ))}
        </List>
        {ticketTypesError ? (
          <div className={mergeClassName(formMessageVariants(), 'mt-4')}>
            {t(
              'rsp:pages.integrations.helpdesk.connectWise.boardConfiguration.oneTicketTypeAtleast'
            )}
          </div>
        ) : null}
      </Card>
    </FormSplitScreen.Row>
  )
}
