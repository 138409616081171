import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'

import { UsersTable } from './UsersTable/UsersTable'
import { UsersTableMobile } from './UsersTableMobile/UsersTableMobile'

const UsersPage = () => {
  const { t } = useTranslation()

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={<PageTitle data-testid="pageUsersTitle">{t('rsp:pages.users.title')}</PageTitle>}
          subTitle={<PageSubTitle>{t('rsp:pages.users.description')}</PageSubTitle>}
        />
        <div className="hidden lg:block">
          <UsersTable />
        </div>
        <div className="mt-4 lg:hidden">
          <UsersTableMobile />
        </div>
      </div>
    </ContainedPage>
  )
}

export default protectPage(UsersPage, Permission.ResellerUsersList)
