import { useState } from 'react'

import { OrganizationPaymentMethodResponse } from '@sherweb/core/openapi-generated/index.defs'

import List from '@sherweb/core/components/List'

import { useGetPaymentMethodsByOrganizationIdQuery } from '@ssp/modules/invoices/core/invoices.hooks'

import { InvoiceDetailPaymentCard } from './InvoiceDetailPaymentCard'

export const InvoiceDetailPaymentMethodContainer = () => {
  const paymentMethodsByOrganizationIdQuery = useGetPaymentMethodsByOrganizationIdQuery()

  const primaryCard = paymentMethodsByOrganizationIdQuery?.data?.find(
    paymentMethod => paymentMethod.isPrimary
  )

  const [selectedCard, setSelectedCard] = useState<OrganizationPaymentMethodResponse | undefined>(
    primaryCard
  )

  const handleSelectPaymentMethod = (currentCard?: OrganizationPaymentMethodResponse) => {
    setSelectedCard(currentCard)
  }

  return (
    <List className="mt-1 w-full">
      {paymentMethodsByOrganizationIdQuery?.data?.map(paymentMethod => (
        <InvoiceDetailPaymentCard
          key={paymentMethod.displayText}
          selectedPaymentMethod={selectedCard}
          paymentMethod={paymentMethod}
          onSelectPaymentMethod={handleSelectPaymentMethod}
        />
      ))}
    </List>
  )
}
