import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { ScrollArea, ScrollBar } from '@sherweb/core/components/ScrollArea'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@sherweb/core/components/Tabs'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import ServerError from '@rsp/components/ServerError/ServerError'
import { useReseller, useResellerActions } from '@rsp/modules/reseller'

import CustomizationsSettings from './CustomizationsSettings'
import OrderSettings from './OrderSettings'
import ResellerSettings from './ResellerSettings'

const SettingsPage: React.FC = () => {
  const { isLoading, data: reseller, error } = useReseller()
  const { setSelectedReseller } = useResellerActions()
  const { t } = useTranslation()

  useEffect(() => {
    if (reseller) {
      setSelectedReseller(reseller.id)
    }
  }, [reseller, setSelectedReseller])

  if (isLoading) {
    return (
      <ContainedPage>
        <Spinner floating />
      </ContainedPage>
    )
  }

  if (error) {
    return (
      <ContainedPage>
        <ServerError error={error} />
      </ContainedPage>
    )
  }

  return (
    <ContainedPage>
      <div className="mb-8 sm:mb-16">
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageSettingsTitle">{t('rsp:pages.settings.title')}</PageTitle>
          }
          subTitle={<PageSubTitle>{t('rsp:pages.settings.subtitle')}</PageSubTitle>}
        />
      </div>
      <Tabs defaultValue="resellerSettings" className="w-full">
        <ScrollArea>
          <TabsList className="h-16 sm:h-auto">
            <TabsTrigger
              value="resellerSettings"
              data-testid="tabsTriggerResellerSettings"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.settings.resellerSettings.title')}
            </TabsTrigger>
            <TabsTrigger
              value="customizationsSettings"
              data-testid="tabsTriggerCustomizationsSettings"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.settings.customizationsSettings.title')}
            </TabsTrigger>
            <TabsTrigger
              value="orderSettings"
              data-testid="tabsTriggerOrderSettings"
              className="focus-visible:ring-indigo-300"
            >
              {t('rsp:pages.settings.orderSettings.title')}
            </TabsTrigger>
          </TabsList>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <TabsContent
          value="resellerSettings"
          data-testid="tabsContentResellerSettings"
          className="focus-visible:ring-indigo-300"
        >
          <ResellerSettings />
        </TabsContent>
        <TabsContent
          value="customizationsSettings"
          data-testid="tabsContentCustomizationsSettings"
          className="focus-visible:ring-indigo-300"
        >
          <CustomizationsSettings />
        </TabsContent>
        <TabsContent
          value="orderSettings"
          data-testid="tabsContentOrderSettings"
          className="focus-visible:ring-indigo-300"
        >
          <OrderSettings />
        </TabsContent>
      </Tabs>
    </ContainedPage>
  )
}

export default protectPage(SettingsPage)
