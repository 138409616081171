import { EyeIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { OrganizationOrderQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'

export type OrderActionsProps = {
  order: OrganizationOrderQueryResult
  className?: string
}

export const OrderActions = ({ order, className }: OrderActionsProps) => {
  const { t } = useTranslation()

  return (
    <div className={mergeClassName('flex flex-row gap-4', className)} data-testid="divOrderActions">
      <Button asChild variant={Variant.Outline} size="sm" data-testid="btnOrderViewDetail">
        <Link to={Routes.Order} params={{ orderId: order.orderId }}>
          <EyeIcon className="mr-1 h-5 w-5  hover:bg-slate-50" />
          {t('ssp:pages.orders.list.actions.viewDetailsMobile')}
        </Link>
      </Button>
    </div>
  )
}
