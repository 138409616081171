import * as React from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, className, ...props }, ref) => {
    return (
      <>
        {label && (
          <label
            id="textarea-label"
            className="text-sm font-semibold text-gray-900 dark:text-slate-200"
          >
            {label}
          </label>
        )}
        <textarea
          aria-labelledby="textarea-label"
          data-testid="inputTextarea"
          className={mergeClassName(
            'flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground',
            'disabled:cursor-not-allowed disabled:opacity-50',
            'dark:border-gray-700 dark:bg-slate-950 dark:text-slate-200 dark:placeholder:text-gray-600',
            className
          )}
          ref={ref}
          {...props}
        />
      </>
    )
  }
)
Textarea.displayName = 'Textarea'
