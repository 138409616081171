import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationOrderQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'

import Routes from '@ssp/app/Routes'
import { useNavigate } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'

export type OrdersTableRowActionsProps = {
  order: OrganizationOrderQueryResult
}

export const OrdersTableRowActions = ({ order }: OrdersTableRowActionsProps) => {
  const { t } = useTranslation()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { navigate } = useNavigate()

  const selectedOrganization = useSelectedOrganization()

  const handleShowDetails = () => {
    if (selectedOrganization?.uniqueName) {
      navigate(Routes.Order, {
        orderId: order.orderId,
        organizationUniqueName: selectedOrganization.uniqueName,
      })
    }
  }

  return (
    <div className="flex gap-x-4">
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild data-testid="dropdownViewOrderOptions">
          <Button variant="ghost" className="h-8 w-8 p-0">
            <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem data-testid="dropdownItemViewDetail" onClick={handleShowDetails}>
            {t('rsp:pages.orders.list.actions.viewDetails')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
