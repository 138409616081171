import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import { usePrevious } from '@sherweb/core/hooks'

import { NoResults } from '../../NoResults'
import { CollapsibleDataHeader } from './CollapsibleDataHeader'
import { CollapsibleColumn } from './types'

export type CollapsbileDataTableProps<TData, TNestedData> = {
  /**
   * The `columns` represents the
   * configuration for the columns of the collapsible data table. It is of type `CollapsibleColumn<TData,
   * TNestedData>`, which defines the structure and properties of the columns in the table.
   */
  columns: CollapsibleColumn<TData, TNestedData>
  /**
   * The `data` represents an data set of an array of `TData` items that will be displayed in the collapsible
   * component
   */
  data: TData[]
  /**
   * The `selectedRows` represents an array of `TNestedData` items that are currently
   * selected in the collapsible data table.
   */
  selectedRows?: TNestedData[]
  /**
   * The `nestedChildrenType` represents the key in the `TData` type that specifies the nested children data for each row in
   * the table. This key is used to access the nested children data within each row of the main data
   * array.
   */
  nestedChildrenType: keyof TData
  /**
   * The `nestedChildrenTotalCount` represents the total count of nested childrens in each row for the whole data set.
   */
  nestedChildrenTotalCount: number
  /**
   * The `emptyMessage` represents the message to be displayed when no data is available
   */
  emptyMessage?: string
  /**
   * The `emptyClassName` represents the classes to be applied on NoResults contaiener
   */
  emptyClassName?: string
  /**
   * The `initialSort` represents a property that allows you to specify the initial sorting configuration for the table.
   */
  initialSort?: Partial<{ id: keyof TNestedData | keyof TData; ascending: boolean }>
  /**
   * The `onSelectAll` is a
   * function that takes a boolean parameter `isSelectedAll` is intended to be called when a user selects or deselects all rows in the collapsible data
   * table.
   */
  onSelectAll?: (isSelectedAll: boolean) => void
  /**
   * The `isHeaderFixed` allows the header of the table to be fixed
   */
  isHeaderFixed?: boolean
  /**
   * renderCollapsibleRow - The `renderCollapsibleRow` is a function that takes an props and pass to CollapsibleDataContent
   * @returns
   */
  renderCollapsibleRow: ({
    data,
    setData,
    columns,
    nestedChildrenType,
    setOpenedRow,
    openedRow,
  }: {
    data: TData[]
    setData: Dispatch<SetStateAction<TData[]>>
    columns: CollapsibleColumn<TData, TNestedData>
    nestedChildrenType: keyof TData
    openedRow: Record<string, boolean>
    setOpenedRow: Dispatch<SetStateAction<Record<string, boolean>>>
  }) => React.ReactNode
}

export const CollapsibleDataTable = <
  TData extends { id?: string },
  TNestedData extends { id?: string },
>({
  columns = [],
  isHeaderFixed,
  emptyClassName,
  data = [],
  renderCollapsibleRow,
  emptyMessage,
  nestedChildrenType,
  initialSort,
  nestedChildrenTotalCount = 0,
  onSelectAll,
  selectedRows = [],
}: CollapsbileDataTableProps<TData, TNestedData>) => {
  const { t } = useTranslation()

  const [selectAll, setSelectAll] = useState(false)

  const [openedRow, setOpenedRow] = useState<Record<string, boolean>>({})

  const [updatedData, setUpdatedData] = useState(data)

  const previousData = usePrevious(data)

  useEffect(() => {
    if (!isEqual(data, previousData)) {
      setUpdatedData(data)
    }
  }, [data, previousData])

  const isPartiallSelectedTable = useMemo(
    () => ([...selectedRows].length !== 0 ? 'indeterminate' : false),
    [selectedRows]
  )

  const isAllSelected = useMemo(
    () => (nestedChildrenTotalCount === [...selectedRows].length ? true : isPartiallSelectedTable),
    [isPartiallSelectedTable, nestedChildrenTotalCount, selectedRows]
  )

  const previousIsAllSelected = usePrevious(isAllSelected)

  useEffect(() => {
    if (previousIsAllSelected !== isAllSelected) {
      onSelectAll?.(isAllSelected === true)
      setSelectAll(isAllSelected === true)
    }
  }, [isAllSelected, onSelectAll, previousIsAllSelected])

  const handleSelectAll = useCallback(() => {
    setSelectAll(previousSelectAll => !previousSelectAll)

    setUpdatedData(previousTableDatas => {
      return previousTableDatas?.map(previousTableData => ({
        ...previousTableData,
        [nestedChildrenType]: (previousTableData?.[nestedChildrenType] as any[])?.map(children => ({
          ...children,
          isSelected: !selectAll,
        })),
      }))
    })
  }, [nestedChildrenType, selectAll])

  const isPartiallySelectedChildren = useMemo(
    () => (data: TNestedData[]) =>
      data.some(children => [...selectedRows].some(selectedRow => isEqual(selectedRow, children)))
        ? 'indeterminate'
        : false,
    [selectedRows]
  )

  const updatedTableData = useMemo(
    () =>
      updatedData?.map(data => ({
        ...data,
        isSelected: (data?.[nestedChildrenType] as any[]).every(children =>
          [...selectedRows].some(selectedRow => isEqual(selectedRow, children))
        )
          ? true
          : isPartiallySelectedChildren(data?.[nestedChildrenType] as TNestedData[]),
      })),
    [isPartiallySelectedChildren, nestedChildrenType, selectedRows, updatedData]
  )

  if (data.length === 0) {
    return (
      <NoResults
        className={emptyClassName}
        emptyMessage={emptyMessage ?? t('core:dataTable.noResults')}
      />
    )
  }

  return (
    <>
      <CollapsibleDataHeader
        columns={columns}
        data={updatedTableData}
        setData={setUpdatedData}
        isHeaderFixed={isHeaderFixed}
        initialSort={initialSort}
        currentSelectedAll={selectAll}
        isAllSelected={isAllSelected}
        handleSelectAll={handleSelectAll}
      />
      {renderCollapsibleRow({
        data: updatedTableData,
        setData: setUpdatedData,
        columns,
        nestedChildrenType,
        setOpenedRow,
        openedRow,
      })}
    </>
  )
}

CollapsibleDataTable.DisplayName = 'CollapsibleDataTable'

export default CollapsibleDataTable
