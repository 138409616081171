import { ServiceProvidersConfigurationService } from '@sherweb/core/openapi-generated/ServiceProvidersConfigurationService'
import { ServiceProvidersService } from '@sherweb/core/openapi-generated/ServiceProvidersService'

import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const serviceProviders = {
  queryKey: () => ['ServiceProvidersService/getServiceProviders'],
  queryFn: async () => await ServiceProvidersService.getServiceProviders(),
  staleTime: 60 * 60 * 1000,
}

export const serviceProvidersConfigurations = {
  queryKey: (resellerId?: string) => [
    'ServiceProvidersConfigurationService/getServiceProvidersConfiguration',
    resellerId,
  ],
  queryFn: async (resellerId?: string) => {
    if (!resellerId) {
      return await rejectMissingParameters()
    }

    return await ServiceProvidersConfigurationService.getServiceProvidersConfiguration({
      resellerId,
    })
  },
  staleTime: 60 * 60 * 1000,
}
