import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormControl, FormField, FormItem } from '@sherweb/core/components/Form'
import { Separator } from '@sherweb/core/components/Separator'
import Switch from '@sherweb/core/components/Switch'
import { Typography } from '@sherweb/core/components/Typography'

import { OrganizationDetailFormType } from './validationSchema/useOrganizationDetailSchema'

export const OrganizationDetailUserSettings = () => {
  const { t } = useTranslation()

  const form = useFormContext<OrganizationDetailFormType>()

  return (
    <div className="mb-4 flex flex-col gap-y-2">
      <Typography variant="heading6" weight="bold">
        {t('rsp:pages.organizations.detail.userSettings.title')}
      </Typography>
      <Separator className="bg-slate-300 dark:bg-slate-700" />
      <div className="flex items-center justify-between">
        <Typography variant="body2" weight="default">
          {t('rsp:pages.organizations.detail.userSettings.toggles.orgAdminCanCreateUsers')}
        </Typography>
        <FormField
          control={form.control}
          name="orgAdminCanCreateUsers"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  data-testid="switchOrgAdminCanCreateUsers"
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}
