import * as Switch from '@radix-ui/react-switch'
import React, { forwardRef } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

const SwitchForm = forwardRef<
  React.ElementRef<typeof Switch.Root>,
  React.ComponentPropsWithoutRef<typeof Switch.Root>
>(({ children, className, ...props }, ref) => (
  <Switch.Root
    ref={ref}
    className={mergeClassName(
      'flex items-center dark:bg-slate-950 dark:border-slate-950 relative bg-slate-200 border-2 border-transparent shrink-0 shadow-inner focus:outline-none relative h-6 w-12 cursor-pointer rounded-full outline-none data-[state=checked]:bg-gradient-to-tr data-[state=checked]:from-indigo-600 data-[state=checked]:to-purple-700',
      className
    )}
    {...props}
  >
    {children}
    <Switch.Thumb className="shadow-blackA7 flex h-5 w-5 translate-x-0.5 items-center justify-center rounded-full bg-white transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-6" />
  </Switch.Root>
))

SwitchForm.displayName = Switch.Root.displayName

export default SwitchForm
