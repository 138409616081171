import { SetStateAction, useState } from 'react'
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'

import { TagInput } from '../TagInput'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from './Form'

interface FormTagInputProps<T> extends DataTestId {
  tags?: string[]
  fieldName: Path<T>
  placeholder: string
  label: string
  errorMessage?: string
  disabled?: boolean
}

export const FormTagInput = <T extends FieldValues>({
  dataTestId,
  disabled = false,
  fieldName,
  label,
  placeholder = '',
  tags: tagsProps = [],
}: FormTagInputProps<T>) => {
  const form = useFormContext<T>()

  const [tags, setTags] = useState(tagsProps)

  const handleTags = async (newTags: SetStateAction<string[]>) => {
    setTags(newTags)
    form.setValue(fieldName, newTags as PathValue<T, Path<T>>)
    await form.trigger(fieldName, { shouldFocus: true })
  }

  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <TagInput
              {...field}
              data-testid={dataTestId}
              placeholder={placeholder}
              tags={tags}
              disabled={disabled}
              setTags={async newTags => {
                await handleTags(newTags)
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
