import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { mergeClassName } from '../../utils/mergeClassName'

type SidebarProps = {
  children?: React.ReactNode
  open: boolean
  onClose: () => void
  className?: string
}

const Sidebar: React.FC<SidebarProps> = ({ children, open, onClose, className }) => {
  const { t } = useTranslation()

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className={mergeClassName('fixed inset-0 z-50 lg:hidden', className)}
          onClose={onClose}
        >
          {/* backdrop */}
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600/75" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative inline-flex">
              {children}

              {/* close button */}
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pl-2 pt-3" onClick={onClose}>
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full outline-none ring-2 ring-inset ring-white"
                  >
                    <span className="sr-only">{t('core:sidebar.closeSidebar')}</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:flex">{children}</div>
    </>
  )
}

export default Sidebar
