import { UserRole } from '@sherweb/core/openapi-generated/index.defs'

import { Language } from '@sherweb/core/common/language'

export const DEFAULT_USER = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  role: UserRole.OrganizationAdmin,
  preferredCulture: Language.EN,
}

export const userFormInputs = [
  {
    className: 'col-span-2 sm:col-span-1',
    type: 'firstName',
    placeholder: 'John',
    dataTestId: 'inputFirstName',
  },
  {
    className: 'col-span-2 sm:col-span-1',
    type: 'lastName',
    placeholder: 'Doe',
    dataTestId: 'inputLastName',
  },
  {
    className: 'col-span-2',
    type: 'email',
    placeholder: 'johndoe@example.com',
    dataTestId: 'inputEmail',
  },
  {
    className: 'col-span-2',
    type: 'jobTitle',
    placeholder: 'ie: CEO',
    dataTestId: 'inputTitle',
  },
] as const
