import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { useQueryParams } from '@sherweb/core/hooks'

import { HasAccess } from '@ssp/modules/authorization'

type ShopDetailCreateSubscriptionProps = {
  isLoading?: boolean
  disabled: boolean
  setConfirmationDialogOpened: (open: boolean) => void
}
export const ShopDetailCreateSubscription = ({
  isLoading = false,
  disabled,
  setConfirmationDialogOpened,
}: ShopDetailCreateSubscriptionProps) => {
  const { t } = useTranslation()

  const { getParams } = useQueryParams<'productQuantity'>()

  const quantity = Number(getParams('productQuantity'))

  return (
    <HasAccess permission={Permission.MarketplaceProductEdit}>
      <div className="mt-6 flex w-full flex-col gap-y-2">
        <LoaderButton
          className="w-full"
          variant="primary"
          dataTestId="btnCreateNewSubscription"
          onClick={() => setConfirmationDialogOpened(true)}
          disabled={disabled || quantity === 0 || isLoading}
          isLoading={isLoading}
          loadingChildren={t('ssp:pages.shop.detail.creating')}
        >
          {t('ssp:pages.shop.detail.create')}
        </LoaderButton>

        {disabled ? (
          <Alert variant={Variant.Warning} data-testid="alertAlreadyActiveSubscription">
            {t('ssp:pages.shop.detail.alreadyActiveSubscription')}
          </Alert>
        ) : null}
      </div>
    </HasAccess>
  )
}
