import { Trans, useTranslation } from 'react-i18next'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'

import { useGetCatalogQuery } from '@rsp/modules/martkeplace'

import { useGetCatalogId } from '../hooks/useGetCatalogId'

export type MarketplaceCatalogsAddProductsConfirmationModalProps = {
  open?: boolean
  changedProductsCount: number
  onClose: () => void
  onSubmit: () => void
}

export const MarketplaceCatalogsAddProductsConfirmationModal = ({
  open,
  changedProductsCount,
  onClose,
  onSubmit,
}: MarketplaceCatalogsAddProductsConfirmationModalProps) => {
  const { t } = useTranslation()

  const { catalogId } = useGetCatalogId()

  const catalogQuery = useGetCatalogQuery(catalogId)

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
      title={t('rsp:pages.marketplace.addProducts.confirmation.title')}
      confirmButtonText={t('rsp:pages.marketplace.addProducts.confirmation.updateCatalog')}
      cancelButtonText={t('rsp:actions.cancel')}
    >
      <Trans
        i18nKey="rsp:pages.marketplace.addProducts.confirmation.body"
        values={{
          catalogName: catalogQuery?.data?.name,
          count: changedProductsCount,
        }}
      />
    </ConfirmationDialog>
  )
}
