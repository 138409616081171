import { QueryKey } from '@tanstack/react-query'

import { AccountService } from '../../../openapi-generated/AccountService'

export const isLoggedIn = {
  queryKey: (): QueryKey => ['AccountService/accountIsSignedIn'],
  queryFn: async (): Promise<boolean> => {
    const promise = AccountService.accountIsSignedIn()
      .then(flag => flag)
      .catch(() => false)
    return await promise
  },
  staleTime: 5 * 60 * 1000,
}
