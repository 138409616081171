type PageTitleProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>

const PageTitle = ({ children, ...props }: PageTitleProps) => {
  return (
    <h1
      className="text-4xl font-extrabold text-gray-900 dark:text-slate-200 md:text-5xl md:leading-tight"
      {...props}
    >
      {children}
    </h1>
  )
}

export default PageTitle
