import { HelpDeskApiInformationService } from '@sherweb/core/openapi-generated/HelpDeskApiInformationService'
import { HelpDeskBoardConfigurationService } from '@sherweb/core/openapi-generated/HelpDeskBoardConfigurationService'
import { HelpDeskOrganizationsService } from '@sherweb/core/openapi-generated/HelpDeskOrganizationsService'
import { HelpDeskTicketsService } from '@sherweb/core/openapi-generated/HelpDeskTicketsService'

import { getLocalizedQueryKey } from '@sherweb/core/modules/reactQuery'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const connectWiseApiInformation = {
  queryKey: (resellerId?: string, integrationId?: string) => [
    'HelpDeskApiInformationService/getHelpDeskApiInformation',
    resellerId,
    integrationId,
  ],
  queryFn: async (resellerId?: string, integrationId?: string) => {
    if (!resellerId || !integrationId) {
      return await rejectMissingParameters()
    }

    return await HelpDeskApiInformationService.getHelpDeskApiInformation({
      resellerId,
      integrationId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const connectWiseServiceBoards = {
  queryKey: (resellerId?: string, integrationId?: string) => [
    'HelpDeskBoardConfigurationService/getHelpDeskServiceBoards',
    resellerId,
    integrationId,
  ],
  queryFn: async (resellerId?: string, integrationId?: string) => {
    if (!resellerId || !integrationId) {
      return await rejectMissingParameters()
    }

    return await HelpDeskBoardConfigurationService.getHelpDeskServiceBoards({
      resellerId,
      integrationId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const connectWiseTicketStatuses = {
  queryKey: (resellerId?: string, integrationId?: string) =>
    getLocalizedQueryKey([
      'HelpDeskTicketsService/getHelpDeskTicketStatuses',
      resellerId,
      integrationId,
    ]),
  queryFn: async (resellerId?: string, integrationId?: string) => {
    if (!resellerId || !integrationId) {
      return await rejectMissingParameters()
    }

    return await HelpDeskTicketsService.getHelpDeskTicketStatuses({ resellerId, integrationId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const connectWiseTicketStatusMappings = {
  queryKey: (resellerId?: string, integrationId?: string, serviceBoardId?: string) =>
    getLocalizedQueryKey([
      'HelpDeskBoardConfigurationService/getHelpDeskTicketStatusMappings',
      resellerId,
      integrationId,
      serviceBoardId,
    ]),
  queryFn: async (resellerId?: string, integrationId?: string, serviceBoardId?: string) => {
    if (!resellerId || !integrationId || !serviceBoardId) {
      return await rejectMissingParameters()
    }

    return await HelpDeskBoardConfigurationService.getHelpDeskTicketStatusMappings({
      resellerId,
      integrationId,
      serviceBoardId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const connectWiseTicketTypes = {
  queryKey: (resellerId?: string, integrationId?: string, serviceBoardId?: string) =>
    getLocalizedQueryKey([
      'HelpDeskBoardConfigurationService/getHelpDeskTicketTypes',
      resellerId,
      integrationId,
      serviceBoardId,
    ]),
  queryFn: async (resellerId?: string, integrationId?: string, serviceBoardId?: string) => {
    if (!resellerId || !integrationId || !serviceBoardId) {
      return await rejectMissingParameters()
    }

    return await HelpDeskBoardConfigurationService.getHelpDeskTicketTypes({
      resellerId,
      integrationId,
      serviceBoardId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const unMappedOrganizations = {
  queryKey: (resellerId?: string, integrationId?: string) => [
    'HelpDeskOrganizationsService/getUnmappedOrganizations',
    resellerId,
    integrationId,
  ],
  queryFn: async (resellerId?: string, integrationId?: string) => {
    if (!resellerId || !integrationId) {
      return await Promise.reject(new Error('Missing parameters'))
    }

    return await HelpDeskOrganizationsService.getUnmappedOrganizations({
      resellerId,
      integrationId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}
