import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button, { Variant } from '@sherweb/core/components/Button'
import {
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { SherwebServiceProviderFormType } from './hooks/useSherwebServiceProviderSchema'
import { SherwebServiceProviderConfigurationBlock } from './SherwebServiceProviderConfigurationBlock'

type SherwebServiceProviderFormProps = {
  isEditMode?: boolean
  isLoading?: boolean
  onResetEditMode: () => void
}

export const SherwebServiceProviderForm = ({
  isEditMode = false,
  isLoading = false,
  onResetEditMode,
}: SherwebServiceProviderFormProps) => {
  const { t } = useTranslation()

  const form = useFormContext<SherwebServiceProviderFormType>()

  return (
    <SherwebServiceProviderConfigurationBlock
      className={mergeClassName('md:max-w-md max-w-full dark:bg-slate-950 py-8', {
        'pb-12': !isEditMode,
      })}
    >
      <FormField
        control={form.control}
        name="clientId"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t('rsp:pages.integrations.serviceProvider.sherweb.form.clientIdKey')}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputSherwebServiceProviderClientId"
                disabled={isLoading}
                placeholder={t(
                  'rsp:pages.integrations.serviceProvider.sherweb.form.clientIdKeyPlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="clientSecret"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t('rsp:pages.integrations.serviceProvider.sherweb.form.clientSecretKey')}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputSherwebServiceProviderClientSecret"
                disabled={isLoading}
                placeholder={t(
                  'rsp:pages.integrations.serviceProvider.sherweb.form.clientSecretKeyPlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="subscriptionKey"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t('rsp:pages.integrations.serviceProvider.sherweb.form.subscriptionKey')}
            </FormLabel>
            <FormControl>
              <FormInput
                {...field}
                data-testid="inputSherwebServiceProviderSubscriptionKey"
                disabled={isLoading}
                placeholder={t(
                  'rsp:pages.integrations.serviceProvider.sherweb.form.subscriptionKeyPlaceholder'
                )}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      {isEditMode ? (
        <div className="flex justify-end pt-6">
          <Button
            className="px-4 py-2"
            variant={Variant.Outline}
            onClick={() => {
              onResetEditMode()
            }}
            data-testid="btnSherwebServiceProviderCancel"
          >
            {t('rsp:actions.cancel')}
          </Button>
        </div>
      ) : null}
    </SherwebServiceProviderConfigurationBlock>
  )
}
