import { useTranslation } from 'react-i18next'

import { Currency } from '@sherweb/core/common/money'
import { useQueryParams } from '@sherweb/core/hooks'
import { useCurrencyConverter, useMoneyFormatter } from '@sherweb/core/utils/money'

import { useGetOffersByCommitmentTerm } from './useGetOffersByCommitmentTerm'

export const useGetFormattedTotalAmount = () => {
  const { selectedOffer } = useGetOffersByCommitmentTerm()

  const {
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter(language)

  const { getParams } = useQueryParams<'productCommitment' | 'productSku' | 'productQuantity'>()

  const selectedQuantityParam = getParams('productQuantity')

  const currencyConverter = useCurrencyConverter()

  const totalAmount = (selectedOffer?.price.amount ?? 0) * Number(selectedQuantityParam)

  const currency = selectedOffer?.price.currency ?? Currency.CAD

  return {
    formattedTotalAmount: formatMoneyAmount({
      amount: totalAmount,
      currency,
    }),
    totalAmount,
    currency: currencyConverter(selectedOffer?.price.currency),
  }
}
