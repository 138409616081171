import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

import { UsersQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { Language } from '@sherweb/core/common/language'
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
  FormRadioList,
} from '@sherweb/core/components/Form'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { usePrompt } from '@sherweb/core/components/Prompt'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@sherweb/core/components/Select'
import { usePathGenerator } from '@sherweb/core/modules/browserRouter'

import Routes from '@ssp/app/Routes'
import { useNavigate } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'
import { useEditUser, useInviteUser, UserRole } from '@ssp/modules/user'

import { DEFAULT_USER, userFormInputs } from './userForm.helper'
import userFormSchema from './validationSchema/userFormSchema'

type UserFormProps = {
  user?: UsersQueryResult
}

const UserForm = ({ user }: UserFormProps) => {
  const { t } = useTranslation()

  const organization = useSelectedOrganization()
  const generatePath = usePathGenerator()
  const { navigate } = useNavigate()

  const inviteUserMutation = useInviteUser()
  const editUserMutation = useEditUser(user?.id)

  const isEditPage = !!user
  const isFormLoading = inviteUserMutation.isLoading || editUserMutation.isLoading

  const form = useForm<z.infer<typeof userFormSchema>>({
    resolver: zodResolver(userFormSchema),
    defaultValues: user ?? DEFAULT_USER,
  })

  useEffect(() => {
    if (inviteUserMutation.isSuccess) {
      form.reset(DEFAULT_USER)
      navigate(
        generatePath(Routes.Users, { organizationUniqueName: organization?.uniqueName ?? '' })
      )
    }
  }, [form, generatePath, inviteUserMutation.isSuccess, navigate, organization?.uniqueName])

  useEffect(() => {
    if (editUserMutation.isSuccess) {
      form.reset(user)
      navigate(
        generatePath(Routes.Users, { organizationUniqueName: organization?.uniqueName ?? '' })
      )
    }
  }, [editUserMutation.isSuccess, form, generatePath, navigate, organization?.uniqueName, user])

  const hasUnsavedChanges = form?.formState?.isDirty

  usePrompt({
    isDirty: hasUnsavedChanges,
  })

  const preferredCultures = [
    {
      value: Language.EN,
      label: t('ssp:layout.userMenu.languageToggle.languages.english'),
      dataTestId: 'selectItemEnglish',
    },
    {
      value: Language.FR,
      label: t('ssp:layout.userMenu.languageToggle.languages.french'),
      dataTestId: 'selectItemFrench',
    },
  ]

  const onSubmit = (values: z.infer<typeof userFormSchema>) => {
    isEditPage ? editUserMutation.mutate(values) : inviteUserMutation.mutate(values)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div className="grid grid-cols-4 gap-x-8 gap-y-6">
            <div className="col-span-4 lg:col-span-2">
              <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                {userFormInputs.map(userFormInput => (
                  <div className={userFormInput.className} key={userFormInput.type}>
                    <FormField
                      control={form.control}
                      name={userFormInput.type}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            {t(`ssp:pages.user.form.fields.${userFormInput.type}.label`)}
                          </FormLabel>
                          <FormControl>
                            <FormInput
                              disabled={userFormInput.type === 'email' && isEditPage}
                              placeholder={userFormInput.placeholder}
                              data-testid={userFormInput.dataTestId}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                ))}
                <div className="col-span-2">
                  <FormField
                    control={form.control}
                    name="preferredCulture"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('ssp:pages.user.form.fields.preferredCulture.label')}
                        </FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger data-testid="selectTriggerLanguage">
                              <SelectValue
                                placeholder={t(
                                  'ssp:pages.user.form.fields.preferredCulture.placeholder'
                                )}
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent side="bottom">
                            {preferredCultures.map(preferredCulture => (
                              <SelectItem
                                key={preferredCulture.value}
                                value={preferredCulture.value}
                                data-testid={preferredCulture.dataTestId}
                              >
                                {preferredCulture.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-4 lg:col-span-2">
              <FormRadioList<UserRole>
                label={t('ssp:pages.user.form.fields.role.label')}
                field="role"
                options={[
                  {
                    value: UserRole.OrganizationAdmin,
                    title: t('ssp:user.userRole.organizationAdmin'),
                    description: t(
                      'ssp:pages.user.form.fields.role.organizationAdmin.description',
                      { organizationName: organization?.name }
                    ),
                    dataTestId: 'radioListOrganizationAdmin',
                  },
                  {
                    value: UserRole.BasicUser,
                    title: t('ssp:user.userRole.basicUser'),
                    description: t('ssp:pages.user.form.fields.role.basicUser.description', {
                      organizationName: organization?.name,
                    }),
                    dataTestId: 'radioListBasicUser',
                  },
                ]}
              />
            </div>
          </div>
          <div className="col-span-2">
            <LoaderButton
              type="submit"
              disabled={isFormLoading}
              dataTestId="btnCreateOrUpdateUser"
              isLoading={isFormLoading}
              loadingChildren={
                isEditPage ? t('ssp:pages.user.edit.submitting') : t('ssp:pages.user.new.submit')
              }
            >
              {isEditPage ? t('ssp:pages.user.edit.submit') : t('ssp:pages.user.new.submit')}
            </LoaderButton>
          </div>
        </div>
      </form>
    </Form>
  )
}

export default UserForm
