import { ChevronDownIcon } from '@heroicons/react/24/outline'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import * as React from 'react'

import { mergeClassName } from '../../utils/mergeClassName'
import { AccordionContentList, AccordionContentListItem } from './AccordionList'

const Accordion = AccordionPrimitive.Root

type AccordionTriggerSubContentProps = {
  children?: React.ReactNode
  className?: string
}

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={mergeClassName('', className)} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={mergeClassName(
        'flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all [&[data-state=open]>div>svg]:rotate-180 dark:bg-slate-950',
        className
      )}
      {...props}
    >
      <div className="flex flex-row items-center gap-2 text-left">
        <ChevronDownIcon
          data-testid="accordionArrowIcon"
          className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200"
        />
        {children}
      </div>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={mergeClassName(
      'overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className
    )}
    {...props}
  >
    <div className="pb-4 pt-0">{children}</div>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

const AccordionTriggerSubContent: React.FC<AccordionTriggerSubContentProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={mergeClassName(
        'block mr-0 sm:absolute right-0 sm:mr-4 text-sm text-gray-500 dark:text-slate-300',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionTriggerSubContent,
}
