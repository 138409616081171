import {
  Currency as ApiCurrency,
  OrdersQueryResult,
  PaginatedListOfListOfOrdersQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import { Currency } from '@sherweb/core/common/money'

import { IPaginatedListOfListOfOrdersQueryResult, Order } from './orders.model'

export const buildOrders = (
  response?: PaginatedListOfListOfOrdersQueryResult
): IPaginatedListOfListOfOrdersQueryResult | undefined => {
  if (!response) {
    return undefined
  }

  return {
    ...response,
    results: response?.results?.map(buildOrder) ?? [],
  }
}

export const buildOrder = (response?: OrdersQueryResult): Order => {
  const orderValue = response?.value
    ? { currency: mapCurrency(response.value.currency), amount: response.value.amount }
    : undefined

  return {
    ...response,
    id: response?.orderId ?? '',
    organizationId: response?.organizationId ?? '',
    humanReadableId: response?.humanReadableId ?? '',
    createdOn: response?.createdAt ?? undefined,
    firstSubscriptionId: response?.items?.[0]?.subscriptionId,
    firstSubscriptionStatus: response?.items?.[0]?.subscriptionStatus,
    firstProductName: response?.items?.[0]?.productName,
    firstQuantity: response?.items?.[0]?.quantity ?? 0,
    orderValue,
    error: response?.errorResult
      ? {
          message: response?.errorResult.message,
          title: response?.errorResult.title,
          detail: response?.errorResult.detail,
        }
      : undefined,
  }
}

export const mapCurrency = (currency: ApiCurrency): Currency => {
  switch (currency) {
    case ApiCurrency.Cad:
      return Currency.CAD
    case ApiCurrency.Usd:
    default:
      return Currency.USD
  }
}
