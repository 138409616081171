import { useQueryClient } from '@tanstack/react-query'

import { getSignOutUrl } from '@config'

import { SspHubKeys } from '@sherweb/core/openapi-generated/index.defs'

import { getPermissionsByOrganization } from '@sherweb/core/modules/authorization'
import { removeUndefinedAndNullValues } from '@sherweb/core/utils/array'

import {
  ordersByResellerAsync,
  ordersByResellerAsyncWithInfiniteScroll,
} from '@rsp/modules/orders/core/orders.queries'

import { integrations } from '@ssp/modules/integrations/core/integrations.queries'
import {
  licensesByOrganizationId,
  unusedLicenseStatsByOrganizationId,
  usedLicenseStatsByOrganizationId,
} from '@ssp/modules/license/core/license.queries'
import {
  queryOrganizationOrdersAsync,
  queryOrganizationOrdersAsyncWithInfiniteScroll,
} from '@ssp/modules/orders/core/orders.queries'
import { useSelectedOrganizationId } from '@ssp/modules/organization'
import { availableOrganizations } from '@ssp/modules/organization/core/organization.queries'
import { useSelectedResellerId } from '@ssp/modules/reseller'
import { subscriptionsByOrganizationId } from '@ssp/modules/subscription/core/subscription.queries'
import { usersByOrganizationId } from '@ssp/modules/user/core/user.queries'

export interface IReceiver {
  key: string
  invokeMethod: (...args: any[]) => Promise<void>
}

const useRefreshOrganizationSubscriptionReceiver: () => IReceiver = () => {
  const queryClient = useQueryClient()
  return {
    key: SspHubKeys.RefreshOrganizationSubscriptions,
    invokeMethod: async (message: { organizationId: string }) => {
      await queryClient.invalidateQueries(
        subscriptionsByOrganizationId.queryKey(message.organizationId)
      )
    },
  }
}

const useRefreshOrganizationsReceiver: () => IReceiver = () => {
  const queryClient = useQueryClient()
  const selectedResellerId = useSelectedResellerId()
  return {
    key: SspHubKeys.RefreshOrganizations,
    invokeMethod: async (message?: { resellerId?: string }) => {
      const resellerIdToRefresh = message?.resellerId ?? selectedResellerId

      await queryClient.invalidateQueries(availableOrganizations.queryKey(resellerIdToRefresh))
    },
  }
}

export const useRefreshLicensesReceiver: () => IReceiver = () => {
  const queryClient = useQueryClient()
  return {
    key: SspHubKeys.RefreshLicenses,
    invokeMethod: async (message: { organizationId: string }) => {
      await queryClient.invalidateQueries(licensesByOrganizationId.queryKey(message.organizationId))
      await queryClient.invalidateQueries(usersByOrganizationId.queryKey(message.organizationId))
      await queryClient.invalidateQueries(
        usedLicenseStatsByOrganizationId.queryKey(message.organizationId)
      )
      await queryClient.invalidateQueries(
        unusedLicenseStatsByOrganizationId.queryKey(message.organizationId)
      )
    },
  }
}

const useLogOutUserReceiver: () => IReceiver = () => {
  return {
    key: SspHubKeys.LogOutUser,
    invokeMethod: async () => {
      const signOutUrl = getSignOutUrl()
      window.location.href = signOutUrl
    },
  }
}

export const useRefreshPermissionsReceiver: () => IReceiver = () => {
  const queryClient = useQueryClient()

  const selectedOrganizationId = useSelectedOrganizationId()

  return {
    key: SspHubKeys.RefreshPermissions,
    invokeMethod: async (message?: { organizationId?: string }) => {
      const organizationIdToRefresh = message?.organizationId ?? selectedOrganizationId

      await queryClient.invalidateQueries(
        getPermissionsByOrganization.queryKey(organizationIdToRefresh)
      )
      await queryClient.invalidateQueries(integrations.queryKey(selectedOrganizationId))
    },
  }
}

export const useRefreshAdminOrdersReceiver: () => IReceiver = () => {
  const queryClient = useQueryClient()
  const selectedResellerId = useSelectedResellerId()
  return {
    key: SspHubKeys.RefreshAdminOrders,
    invokeMethod: async (message?: { resellerId?: string }) => {
      const resellerIdToRefresh = message?.resellerId ?? selectedResellerId
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: removeUndefinedAndNullValues(
            ordersByResellerAsync.queryKey(resellerIdToRefresh)
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: removeUndefinedAndNullValues(
            ordersByResellerAsyncWithInfiniteScroll.queryKey(resellerIdToRefresh)
          ),
        }),
      ])
    },
  }
}

export const useRefreshOrdersReceiver: () => IReceiver = () => {
  const queryClient = useQueryClient()
  const selectedOrganizationId = useSelectedOrganizationId()
  return {
    key: SspHubKeys.RefreshOrders,
    invokeMethod: async (message?: { organizationId?: string }) => {
      const organizationIdToRefresh = message?.organizationId ?? selectedOrganizationId

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: removeUndefinedAndNullValues(
            queryOrganizationOrdersAsync.queryKey(organizationIdToRefresh)
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: removeUndefinedAndNullValues(
            queryOrganizationOrdersAsyncWithInfiniteScroll.queryKey(organizationIdToRefresh)
          ),
        }),
      ])
    },
  }
}

export const useReceivers: () => IReceiver[] = () => [
  useRefreshOrganizationSubscriptionReceiver(),
  useRefreshOrganizationsReceiver(),
  useRefreshLicensesReceiver(),
  useLogOutUserReceiver(),
  useRefreshPermissionsReceiver(),
  useRefreshAdminOrdersReceiver(),
  useRefreshOrdersReceiver(),
]
