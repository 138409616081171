import { Dispatch, SetStateAction, useCallback } from 'react'

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../../Collapsible'
import List from '../../List'
import { CollapsibleDataRow } from './CollapsibleDataRow'
import { CollapsibleDataTriggerRow } from './CollapsibleDataTriggerRow'
import { CollapsibleColumn } from './types'

type CollapsibleDataContentProps<TData, TNestedData> = {
  columns: CollapsibleColumn<TData, TNestedData>
  data: TData[]
  nestedChildrenType: keyof TData
  setData: Dispatch<SetStateAction<TData[]>>
  openedRow: Record<string, boolean>
  setOpenedRow: Dispatch<SetStateAction<Record<string, boolean>>>
}

export const CollapsibleDataContent = <
  TData extends { id: string },
  TNestedData extends { id?: string },
>({
  columns,
  data,
  nestedChildrenType,
  setData,
  openedRow,
  setOpenedRow,
}: CollapsibleDataContentProps<TData, TNestedData>) => {
  const handleOpenedRow = useCallback(
    (tableData: TData) => {
      setOpenedRow(previousOpenedRow => ({
        ...previousOpenedRow,
        [tableData?.id]: !previousOpenedRow[tableData?.id],
      }))
    },
    [setOpenedRow]
  )

  return (
    <>
      {data.map((tableData, index) => {
        const nestedChildrens = tableData[nestedChildrenType] as TNestedData[]

        if (Array.isArray(tableData?.[nestedChildrenType]) && nestedChildrens?.length === 1) {
          return (
            <List
              className="overflow grid grid-cols-12 rounded-l-lg border-none"
              key={`collapsible-single-row-${tableData.id}`}
              dataTestId="collapsible-row"
            >
              <CollapsibleDataRow
                columns={columns}
                parentData={tableData}
                childrens={nestedChildrens}
                setData={setData}
                className="justify-center"
              />
            </List>
          )
        }

        return (
          <Collapsible asChild key={`collapsible-${tableData?.id}`} open={openedRow[index]}>
            <>
              <CollapsibleTrigger
                className="group w-full"
                key={`collapsible-trigger-${tableData?.id}`}
                asChild
              >
                <div>
                  <List
                    dataTestId="collapsible-row"
                    className="overflow grid grid-cols-12 rounded-l-lg border-none"
                    data-testid="collapsible-trigger"
                    onClick={() => {
                      handleOpenedRow(tableData)
                    }}
                  >
                    <CollapsibleDataTriggerRow
                      columns={columns}
                      data={tableData}
                      setData={setData}
                    />
                  </List>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <List
                  className="overflow grid grid-cols-12 border-none"
                  dataTestId="collapsible-row"
                >
                  <CollapsibleDataRow
                    columns={columns}
                    childrens={nestedChildrens}
                    setData={setData}
                    className="justify-center pl-20"
                  />
                </List>
              </CollapsibleContent>
            </>
          </Collapsible>
        )
      })}
    </>
  )
}
