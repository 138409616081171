import { useTranslation } from 'react-i18next'

import {
  HelpDeskTicketQueryResult,
  HelpDeskTicketStatus as IHelpDeskTicketStatus,
  Permission,
} from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import DataTable from '@sherweb/core/components/DataTable'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { useDateFormatter } from '@sherweb/core/utils/date'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'
import {
  useGetHelpDeskOrganizationTicketTypeQuery,
  useGetHelpDeskTicketsQuery,
  useGetOrganizationHelpDeskTicketStatusesQuery,
} from '@ssp/modules/integrations/helpdesk'
import { HelpDeskTicketStatus } from '@ssp/modules/integrations/helpdesk/ui/HelpDeskTicketStatus'

import { TICKET_ROWS_PER_PAGE } from '../../helpdesk.const'
import { HelpDeskTicketTableActionRow } from './HelpDeskTicketTableActionRow'
import { HelpDeskTicketTableTitle } from './HelpDeskTicketTableTitle'

const renderStatus = (status?: IHelpDeskTicketStatus) => <HelpDeskTicketStatus status={status} />

const renderTitle = (subject?: string, openedByName?: string) => (
  <HelpDeskTicketTableTitle subject={subject} openedByName={openedByName} />
)

const renderActionRowContent = (ticketId?: string) => (
  <HelpDeskTicketTableActionRow ticketId={ticketId} />
)

export const HelpDeskTicketTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language)

  const helpDeskTicketsQuery = useGetHelpDeskTicketsQuery()

  const isHelpDeskTicketsQueryLoading =
    helpDeskTicketsQuery?.isLoading || !helpDeskTicketsQuery?.data

  const helpDeskOrganizationTicketStatusesQuery = useGetOrganizationHelpDeskTicketStatusesQuery()

  const isHelpDeskOrganizationTicketStatusesQueryLoading =
    helpDeskOrganizationTicketStatusesQuery?.isLoading ||
    !helpDeskOrganizationTicketStatusesQuery?.data

  const helpDeskOrganizationTicketTypeQuery = useGetHelpDeskOrganizationTicketTypeQuery()

  const isHelpDeskOrganizationTicketTypesQueryLoading =
    helpDeskOrganizationTicketTypeQuery.isLoading || !helpDeskOrganizationTicketTypeQuery?.data

  const columns: Columns<HelpDeskTicketQueryResult> = [
    {
      accessorKey: 'id',
      header: t('ssp:pages.helpdesk.list.columns.ticketId'),
      maxSize: 20,
      meta: { className: 'text-center align-top' },
    },
    {
      id: 'subject',
      header: t('ssp:pages.helpdesk.list.columns.subject'),
      minSize: 800,
      meta: { className: 'max-w-xl' },
      cell: ({ row }) => renderTitle(row?.original?.subject, row?.original?.openedByName),
    },
    {
      accessorKey: 'lastUpdated',
      header: t('ssp:pages.helpdesk.list.columns.lastUpdated'),
      enableSorting: true,
      cell: ({ getValue }) => formatDate(getValue<string>()),
      minSize: 180,
      meta: { className: 'whitespace-nowrap' },
    },
    {
      accessorKey: 'ticketTypeId',
      header: t('ssp:pages.helpdesk.list.columns.ticketType'),
      enableSorting: true,
      cell: ({ getValue }) => helpDeskOrganizationTicketTypeQuery?.data?.[getValue<string>()]?.name,
      minSize: 145,
      meta: { className: 'whitespace-nowrap' },
    },
    {
      accessorKey: 'statusId',
      header: t('ssp:pages.helpdesk.list.columns.status'),
      enableSorting: true,
      cell: ({ row }) =>
        renderStatus(helpDeskOrganizationTicketStatusesQuery?.data?.[row.original?.statusId]),
      minSize: 145,
    },
    {
      id: 'actions',
      cell: ({ row }) => renderActionRowContent(row.original?.id),
      maxSize: 145,
    },
  ]

  return (
    <Card padded>
      {isHelpDeskOrganizationTicketStatusesQueryLoading ||
      isHelpDeskOrganizationTicketTypesQueryLoading ||
      isHelpDeskTicketsQueryLoading ? (
        <SkeletonTable />
      ) : (
        <DataTable
          columns={columns}
          data={helpDeskTicketsQuery?.data}
          rowsPerPage={TICKET_ROWS_PER_PAGE}
          optionalActions={
            <HasAccess permission={Permission.HelpDeskEdit}>
              <div className="flex w-full justify-end">
                <Button variant="primary" data-testid="btnHelpDeskCreateTicket" size="sm" asChild>
                  <Link
                    to={Routes.HelpDeskCreate}
                    className="rounded-lg focus-visible:outline-indigo-300"
                  >
                    {t('ssp:pages.helpdesk.create.newRequest')}
                  </Link>
                </Button>
              </div>
            </HasAccess>
          }
          dataTestId="tableHelpdeskTickets"
        />
      )}
    </Card>
  )
}
