import { useTranslation } from 'react-i18next'

import { UserRole } from '@sherweb/core/openapi-generated/index.defs'

type UserRolesProps = {
  roles?: UserRole | UserRole[]
}

export const UserRoles = ({ roles }: UserRolesProps) => {
  const { t } = useTranslation()

  const getRoleTranslation = (role?: UserRole) => {
    if (!role) {
      return ''
    }

    return t(`core:user.role.${role}`)
  }

  if (Array.isArray(roles)) {
    return <>{roles?.map(role => getRoleTranslation(role)).join(', ')}</>
  }

  return <>{getRoleTranslation(roles)}</>
}
