import { ReactElement, useEffect, useState } from 'react'

import Button from '@sherweb/core/components/Button'

type ConditionalActionButtonProps = {
  items: string[]
  value: string | undefined
  onHandle: (value: string | undefined) => void
  childFlagged: ReactElement
  childNotFlagged: ReactElement
  disabled: boolean
}

const ConditionalActionButton: React.FC<ConditionalActionButtonProps> = ({
  items,
  value,
  onHandle,
  childFlagged,
  childNotFlagged,
  disabled,
}) => {
  const [flagged, setFlagged] = useState(value ? items.includes(value) : false)

  useEffect(() => {
    setFlagged(value ? items.includes(value) : false)
  }, [items, value])

  return (
    <Button disabled={disabled} variant="none" onClick={() => onHandle(value)} className="p-1">
      {!flagged && childNotFlagged}
      {flagged && childFlagged}
    </Button>
  )
}

export default ConditionalActionButton
