// based on: https://ui.shadcn.com/docs/components/checkbox#installation

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, VariantProps } from 'class-variance-authority'
import { Check, Minus } from 'lucide-react'
import React from 'react'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

export const checkboxVariants = cva(
  'group peer h-4 w-4 shrink-0 rounded-sm border ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:border-slate-400 data-[state=checked]:text-primary-foreground data-[state=indeterminate]:text-white dark:text-white',
  {
    variants: {
      variant: {
        primary:
          'border-primary data-[state=checked]:bg-primary data-[state=indeterminate]:bg-primary',
        secondary:
          'border-indigo data-[state=checked]:bg-indigo-600 data-[state=indeterminate]:bg-indigo-600',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
)

type ChecboxPropsType = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
  VariantProps<typeof checkboxVariants>

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  ChecboxPropsType
>(({ className, variant, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={mergeClassName(checkboxVariants({ variant }), className)}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={mergeClassName('flex items-center justify-center text-current')}
    >
      <Minus className="hidden h-4 w-4 group-data-[state=indeterminate]:block" />
      <Check className="hidden h-4 w-4 group-data-[state=checked]:block" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
