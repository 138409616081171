import { OrganizationIntegrationsService } from '@sherweb/core/openapi-generated/OrganizationIntegrationsService'

import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

export const integrations = {
  queryKey: (organizationId?: string) => [
    'OrganizationIntegrationsService/getIntegrations',
    organizationId,
  ],
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await rejectMissingParameters()
    }

    return await OrganizationIntegrationsService.getIntegrations({ organizationId })
  },
  staleTime: DEFAULT_STALE_TIME,
}
