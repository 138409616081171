import { MoneyAmount } from '@sherweb/core/common/money'

export enum SubscriptionBillingCycle {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum SubscriptionCommitmentType {
  Annual = 'Annual',
  Biennial = 'Biennial',
  Triennial = 'Triennial',
  Monthly = 'Monthly',
}

export enum SubscriptionStatus {
  New = 'New',
  Processing = 'Processing',
  Active = 'Active',
  Suspended = 'Suspended',
  Inactive = 'Inactive',
  Failed = 'Failed',
  Pending = 'Pending',
  WaitingForApproval = 'WaitingForApproval',
}

export enum OrderStatus {
  Completed = 'Completed',
  Pending = 'Pending',
  Failed = 'Failed',
  WaitingForApproval = 'WaitingForApproval',
  Rejected = 'Rejected',
}

export enum OrderDecision {
  Approve = 'Approve',
  Reject = 'Reject',
}

export type Subscription = {
  id: string
  sku?: string | null
  productName?: string | null
  description?: string | null
  commitmentType?: SubscriptionCommitmentType | null
  billingCycle?: SubscriptionBillingCycle | null
  commitmentEndDate?: Date | null
  renewalDate?: Date | null
  scheduledQuantity?: number | null
  quantity?: number | null
  status?: SubscriptionStatus | null
  statusWithOrders?: SubscriptionStatus | null
  price?: MoneyAmount | null
  total: MoneyAmount | null
  lastPendingOrFailedOrder?: LastPendingOrFailedOrder | null
  orderApprovalSettings: OrderApprovalSettings | null
  committedMinimalQuantity?: number | null
}

export type LastPendingOrFailedOrder = {
  orderId: string
  humanReadableId?: string | null
  quantity: number | null
  initialQuantity: number | null
  status?: OrderStatus | null
  decisionNote?: string
  error: {
    content?: string | null
    dismissed: boolean
  } | null
  createdAt: Date | null
}

export type OrderApprovalSettings = {
  isOrderApprovalRequired: boolean
}
