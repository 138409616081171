import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission, ResellerUserQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu/DropdownMenu'

import { HasAccess } from '@rsp/modules/authorization'

export type UserTableActionRowProps = {
  user: ResellerUserQueryResult
  setSelectedUser: (user: ResellerUserQueryResult | null) => void
  deleteUserMutation: UseMutationResult<void, unknown, string | undefined, unknown>
}

export const UserTableActionRow = ({
  user,
  setSelectedUser,
  deleteUserMutation,
}: UserTableActionRowProps) => {
  const { t } = useTranslation()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  return (
    <HasAccess permission={Permission.ResellerUsersDelete}>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild data-testid="dropdownViewUserOptions">
          <Button variant="ghost" className="h-8 w-8 p-0">
            <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            data-testid="dropdownItemDeleteUser"
            onClick={() => {
              setSelectedUser(user)
            }}
            disabled={deleteUserMutation.isLoading}
          >
            {t('core:actions.delete')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </HasAccess>
  )
}
