import { useTranslation } from 'react-i18next'

import { OrganizationPaymentMethodResponse } from '@sherweb/core/openapi-generated/index.defs'

import { Checkbox } from '@sherweb/core/components/Checkbox'
import List from '@sherweb/core/components/List'
import { Typography } from '@sherweb/core/components/Typography'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { formatCreditCardNumber } from '../../invoiceDetail.utils'

type InvoiceDetailPaymentCardProps = {
  selectedPaymentMethod?: OrganizationPaymentMethodResponse
  paymentMethod: OrganizationPaymentMethodResponse
  onSelectPaymentMethod: (paymentMethodResult?: OrganizationPaymentMethodResponse) => void
}

export const InvoiceDetailPaymentCard = ({
  selectedPaymentMethod,
  paymentMethod,
  onSelectPaymentMethod,
}: InvoiceDetailPaymentCardProps) => {
  const { t } = useTranslation()

  const isChecked = paymentMethod.id === selectedPaymentMethod?.id

  const handleSelectCardPaymentMethod = () => {
    onSelectPaymentMethod(paymentMethod)
  }

  return (
    <List.Item
      data-testid="cardPaymentMethod"
      className={mergeClassName('p-3', {
        'bg-indigo-100 dark:text-slate-200': isChecked,
      })}
      onClick={handleSelectCardPaymentMethod}
    >
      <List.Actions>
        <Checkbox
          data-testid="checkboxTicketType"
          variant="secondary"
          checked={isChecked}
          onCheckedChange={handleSelectCardPaymentMethod}
        />
      </List.Actions>
      <div className="flex flex-col gap-1">
        <Typography variant="label" weight="default" className="flex">
          {formatCreditCardNumber(paymentMethod.displayText?.replaceAll('X', '•'))}
        </Typography>
        <Typography variant="label" weight="default" colors="light" className="flex">
          {t('ssp:pages.invoices.detail.expiresOn', {
            monthAndYear: `${String(paymentMethod?.expirationDate?.month)}/${String(paymentMethod?.expirationDate?.year)}`,
          })}
        </Typography>
      </div>
    </List.Item>
  )
}
