import { DetailedOrganizationResult } from '@sherweb/core/openapi-generated/index.defs'

import { DetailedOrganizationType } from './organizations.model'

export const buildDetailedOrganization = (
  organization?: DetailedOrganizationResult
): DetailedOrganizationType => {
  if (!organization?.settings) {
    return {
      organizationId: '',
      organizationName: '',
      userCount: 0,
      isSuspended: false,
      isPriceVisible: false,
      isOrderApprovalRequired: false,
      showUsers: false,
      showHelpDesk: false,
      showLicenses: false,
      showSubscriptions: false,
      allowSubscriptionCancellation: false,
      orgAdminCanCreateUsers: false,
      showBilling: false,
      showMarketplace: false,
    }
  }

  return {
    ...organization,
    isPriceVisible: organization?.settings?.isPriceVisible,
    isOrderApprovalRequired: organization?.settings?.isOrderApprovalRequired,
    showUsers: organization?.settings?.showUsers,
    showHelpDesk: organization?.settings?.showHelpDesk,
    showLicenses: organization?.settings?.showLicenses,
    showSubscriptions: organization?.settings?.showSubscriptions,
    allowSubscriptionCancellation: organization?.settings?.allowSubscriptionCancellation,
    orgAdminCanCreateUsers: organization?.settings?.orgAdminCanCreateUsers,
    showBilling: organization?.settings?.showBilling,
    showMarketplace: organization?.settings?.showMarketplace,
  }
}
