import { ResellerCatalogsService } from '@sherweb/core/openapi-generated/ResellerCatalogsService'

import { getLocalizedQueryKey } from '@sherweb/core/modules/reactQuery'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { missingParametersError } from '@sherweb/core/utils/error'

import { getCatalogOrganizationsQueryOptions } from './marketplace.const'

export const availableCatalogs = {
  queryKey: (resellerId?: string) =>
    getLocalizedQueryKey(['ResellerCatalogsService/getAvailableCatalogsByResellerId', resellerId]),
  queryFn: async (resellerId?: string) => {
    if (!resellerId) {
      return await Promise.reject(missingParametersError())
    }

    return await ResellerCatalogsService.getAvailableCatalogsByResellerId({ resellerId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const catalogProducts = {
  queryKey: (resellerId?: string, catalogId?: string) =>
    getLocalizedQueryKey(['ResellerCatalogsService/getProducts', resellerId, catalogId]),
  queryFn: async (resellerId?: string, catalogId?: string) => {
    if (!resellerId || !catalogId) {
      return await Promise.reject(missingParametersError())
    }

    return await ResellerCatalogsService.getProducts({ resellerId, catalogId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const availableProducts = {
  queryKey: (resellerId?: string, catalogId?: string) =>
    getLocalizedQueryKey(['ResellerCatalogsService/getAvailableProducts', resellerId, catalogId]),
  queryFn: async (resellerId?: string, catalogId?: string) => {
    if (!resellerId || !catalogId) {
      return await Promise.reject(missingParametersError())
    }

    return await ResellerCatalogsService.getAvailableProducts({ resellerId, catalogId })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const queryCatalogOrganizations = {
  queryKey: (
    resellerId?: string,
    catalogId?: string,
    queryOptions = getCatalogOrganizationsQueryOptions()
  ) =>
    getLocalizedQueryKey([
      'ResellerCatalogsService/queryCatalogOrganizations',
      resellerId,
      catalogId,
      queryOptions,
    ]),
  queryFn: async (
    resellerId?: string,
    catalogId?: string,
    queryOptions = getCatalogOrganizationsQueryOptions()
  ) => {
    if (!resellerId || !catalogId) {
      return await Promise.reject(missingParametersError())
    }

    return await ResellerCatalogsService.queryCatalogOrganizations({
      resellerId,
      catalogId,
      command: queryOptions,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const getAssignedCatalogOrganizationIds = {
  queryKey: (resellerId?: string, catalogId?: string) => [
    'ResellerCatalogsService/getAssignedCatalogOrganizationIds',
    resellerId,
    catalogId,
  ],
  queryFn: async (resellerId?: string, catalogId?: string) => {
    if (!resellerId || !catalogId) {
      return await Promise.reject(missingParametersError())
    }

    return await ResellerCatalogsService.getAssignedCatalogOrganizationIds({
      resellerId,
      catalogId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}
