import { TabsProps, TabsTriggerProps } from '@radix-ui/react-tabs'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import React from 'react'
import { Link, Outlet } from 'react-router-dom'

import { useLocation } from '@sherweb/core/modules/browserRouter'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { Tabs, TabsTrigger } from './Tabs'

interface TabsTriggerLinkProps extends Omit<TabsTriggerProps, 'value'> {
  to: string
}

export const TabsLink = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Root>, TabsProps>(
  ({ children }, ref) => {
    const location = useLocation()

    return (
      <Tabs value={location.pathname} ref={ref}>
        {children}
      </Tabs>
    )
  }
)

export const TabsTriggerLink = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabsTriggerLinkProps
>(({ to, children, disabled, ...restProps }, ref) => (
  <TabsTrigger asChild value={to} disabled={disabled} {...restProps} ref={ref}>
    <Link
      className={mergeClassName({
        'pointer-events-none': disabled,
      })}
      to={to}
    >
      {children}
    </Link>
  </TabsTrigger>
))

export const TabsLinkContent = () => <Outlet />
