import { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'

import { BillingCycle, CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import { usePrevious, useQueryParams } from '@sherweb/core/hooks'

import { BuildAvailableProductsReturn } from '@rsp/modules/martkeplace/core/marketplace.builder'
import { ICatalogOffer } from '@rsp/modules/martkeplace/core/marketplace.model'

export const useGetFilteredProducts = (availableProducts?: BuildAvailableProductsReturn) => {
  const [selectedOffers, setSelectedOffers] = useState<ICatalogOffer[]>(
    availableProducts?.selectedOffers ?? []
  )

  const { getParams, getArrayParam } = useQueryParams<
    | 'productsQuery'
    | 'productsBillingCycle'
    | 'productsCommitment'
    | 'productsVendor'
    | 'productsCategories'
  >()

  const searchInputValue = getParams<string>('productsQuery')

  const billingCycleSelectedValue = getArrayParam<BillingCycle>('productsBillingCycle')

  const commitmentSelectedValue = getArrayParam<CommitmentTermType>('productsCommitment')

  const categoriesSelectedValue = getArrayParam<string>('productsCategories')

  const vendorSelectedValue = getArrayParam<string>('productsVendor')

  const previousSelectedOffers = usePrevious(availableProducts?.selectedOffers)

  useEffect(() => {
    if (
      !isEqual(availableProducts?.selectedOffers, previousSelectedOffers) &&
      availableProducts?.selectedOffers.length !== 0
    ) {
      setSelectedOffers(availableProducts?.selectedOffers ?? [])
    }
  }, [availableProducts?.selectedOffers, previousSelectedOffers])

  const hasFilters =
    searchInputValue.length !== 0 ||
    vendorSelectedValue.length !== 0 ||
    billingCycleSelectedValue.length !== 0 ||
    commitmentSelectedValue.length !== 0 ||
    categoriesSelectedValue.length !== 0

  const hasSearchQuery = (offer: ICatalogOffer) => {
    return searchInputValue.length !== 0
      ? (offer?.name?.toLowerCase().includes(searchInputValue.toLowerCase()) ?? false) ||
          (offer?.vendor?.toLowerCase().includes(searchInputValue.toLowerCase()) ?? false) ||
          offer.sku?.toLowerCase().includes(searchInputValue.toLowerCase())
      : true
  }

  const hasCommitmentQuery = (offer: ICatalogOffer) => {
    return commitmentSelectedValue.length !== 0
      ? commitmentSelectedValue.includes(offer.commitmentTermType)
      : true
  }

  const hasVendorQuery = (offer: ICatalogOffer) => {
    return vendorSelectedValue.length !== 0
      ? vendorSelectedValue.includes(offer.vendor ?? '')
      : true
  }

  const hasBillingCycleQuery = (offer: ICatalogOffer) => {
    return billingCycleSelectedValue.length !== 0
      ? billingCycleSelectedValue.includes(offer.billingCycle)
      : true
  }

  return {
    hasFilters,
    filteredProducts: availableProducts?.products
      .map(product => {
        if (hasFilters) {
          return {
            ...product,
            offers: product.offers.filter(offer => {
              return (
                hasSearchQuery(offer) &&
                hasCommitmentQuery(offer) &&
                hasBillingCycleQuery(offer) &&
                hasVendorQuery(offer)
              )
            }),
          }
        }

        return product
      })
      ?.filter(product => product.offers.length !== 0)
      ?.filter(product =>
        product.categories?.every(category =>
          categoriesSelectedValue.length !== 0
            ? categoriesSelectedValue.includes(String(category?.name))
            : true
        )
      ),
    totalOffersCount: availableProducts?.totalOffersCount ?? 0,
    selectedOffers,
    setSelectedOffers,
  }
}
