import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { SheetPage } from '@sherweb/core/components/Sheet'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { Typography } from '@sherweb/core/components/Typography'

import { protectPage } from '@ssp/app/ProtectedPage'
import {
  useGetHelpDeskEnabledQuery,
  useGetHelpDeskOrganizationTicketTypeQuery,
  useGetOrganizationHelpDeskTicketDetailQuery,
  useGetOrganizationHelpDeskTicketStatusesQuery,
} from '@ssp/modules/integrations/helpdesk'
import { useNavigateToHelpDeskPage } from '@ssp/modules/integrations/helpdesk/core/helpdesk.helpers'
import Error404 from '@ssp/pages/errors/Error404'

import { HelpDeskTicketContent } from './HelpDeskTicketContent'

const HelpDeskTicketDetailPage = () => {
  const { helpDeskTicketId } = useParams()

  const helpDeskEnabledQuery = useGetHelpDeskEnabledQuery()

  const oganizationHelpDeskTicketDetailQuery = useGetOrganizationHelpDeskTicketDetailQuery(
    {
      refetchOnMount: true,
      staleTime: 0,
    },
    helpDeskTicketId
  )

  const organizationHelpDeskTicketStatusesQuery = useGetOrganizationHelpDeskTicketStatusesQuery()

  const isHelpDeskOrganizationTicketStatusesQueryLoading =
    organizationHelpDeskTicketStatusesQuery?.isLoading ||
    !organizationHelpDeskTicketStatusesQuery?.data

  const helpDeskOrganizationTicketTypeQuery = useGetHelpDeskOrganizationTicketTypeQuery()

  const isHelpDeskOrganizationTicketTypesQueryLoading =
    helpDeskOrganizationTicketTypeQuery.isLoading || !helpDeskOrganizationTicketTypeQuery?.data

  const navigateToHelpDeskPage = useNavigateToHelpDeskPage()

  const handleRedirectToHelpDesk = useCallback(() => {
    navigateToHelpDeskPage()
  }, [navigateToHelpDeskPage])

  const isLoading =
    isHelpDeskOrganizationTicketStatusesQueryLoading ||
    isHelpDeskOrganizationTicketTypesQueryLoading ||
    oganizationHelpDeskTicketDetailQuery.isLoading

  if (
    helpDeskEnabledQuery?.data !== undefined &&
    !helpDeskEnabledQuery?.data &&
    !helpDeskEnabledQuery.isLoading
  ) {
    return <Error404 />
  }

  return (
    <SheetPage
      isLoading={isLoading}
      title={
        <Typography variant="heading6" weight="bold" className="text-wrap">
          {oganizationHelpDeskTicketDetailQuery?.data?.subject}
        </Typography>
      }
      onClose={handleRedirectToHelpDesk}
    >
      {oganizationHelpDeskTicketDetailQuery.isFetching ? (
        <Spinner
          dataTestId="layoverSpinner"
          className={spinnerStyles({ type: 'layOverSpinner' })}
        />
      ) : null}
      <HelpDeskTicketContent />
    </SheetPage>
  )
}

export default protectPage(HelpDeskTicketDetailPage, Permission.HelpDeskDetails)
