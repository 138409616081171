import { useEffect } from 'react'

import { CatalogOrganizationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { useUpdateCatalogOrganizationsMutation } from '@rsp/modules/martkeplace'

import { useGetCatalogId } from '../../hooks/useGetCatalogId'

export type UseUpdateCatalogOrganizationsProps = {
  catalogOrganizations: CatalogOrganizationQueryResult[] | undefined
  onCatalogUpdateSuccess?: () => void
  onPartialCatalogUpdateSuccess?: () => void
}

export const useUpdateCatalogOrganizations = ({
  catalogOrganizations,
  onCatalogUpdateSuccess,
  onPartialCatalogUpdateSuccess,
}: UseUpdateCatalogOrganizationsProps) => {
  const { catalogId } = useGetCatalogId()

  const setCatalogOrganizationsMutation = useUpdateCatalogOrganizationsMutation(catalogId)

  const failedOrganizationIds = setCatalogOrganizationsMutation?.data?.failedOrganizations ?? []

  const mapFailedOrganizations = (failedOrganizationIds?: string[]) => {
    return (
      catalogOrganizations?.filter(organization =>
        failedOrganizationIds?.includes(organization.organizationId)
      ) ?? []
    )
  }

  useEffect(() => {
    if (setCatalogOrganizationsMutation.isSuccess) {
      if (failedOrganizationIds?.length > 0) {
        onPartialCatalogUpdateSuccess?.()
      } else {
        onCatalogUpdateSuccess?.()
      }
    }
  }, [
    failedOrganizationIds?.length,
    onCatalogUpdateSuccess,
    onPartialCatalogUpdateSuccess,
    setCatalogOrganizationsMutation.isSuccess,
  ])

  const onUpdateCatalogOrganizations = (
    organizationsToAdd: string[],
    organizationsToRemove: string[]
  ) => {
    setCatalogOrganizationsMutation.mutate({
      organizationsToAdd,
      organizationsToRemove,
    })
  }

  return {
    isLoading: setCatalogOrganizationsMutation.isLoading,
    onUpdateCatalogOrganizations,
    failedOrganizations: mapFailedOrganizations(failedOrganizationIds),
    successfulOrganizationsNumber:
      setCatalogOrganizationsMutation?.data?.successfulOrganizations?.length ?? 0,
  }
}
