// based on https://ui.shadcn.com/docs/components/skeleton

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Skeleton = ({ className, ...props }: SkeletonProps) => (
  <div
    data-testid="skeleton"
    className={mergeClassName(
      'animate-pulse rounded-md bg-slate-200 dark:bg-slate-950 rounded-md',
      className
    )}
    {...props}
  />
)
