import { EyeIcon, TicketIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { HelpDeskTicketQueryResult, Permission } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
} from '@sherweb/core/components/Accordion'
import Button from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import Tooltip from '@sherweb/core/components/Tooltip'
import { Typography } from '@sherweb/core/components/Typography'
import { useDateFormatter } from '@sherweb/core/utils/date'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'
import {
  useGetHelpDeskOrganizationTicketTypeQuery,
  useGetHelpDeskTicketsQuery,
  useGetOrganizationHelpDeskTicketStatusesQuery,
} from '@ssp/modules/integrations/helpdesk'
import { HelpDeskTicketStatus } from '@ssp/modules/integrations/helpdesk/ui/HelpDeskTicketStatus'
import { useSelectedOrganization } from '@ssp/modules/organization'

export const HelpDeskTicketTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatDate = useDateFormatter(language)

  const organization = useSelectedOrganization()

  const helpDeskTicketsQuery = useGetHelpDeskTicketsQuery()

  const isHelpDeskTicketsQueryLoading =
    helpDeskTicketsQuery?.isLoading || !helpDeskTicketsQuery?.data

  const helpDeskOrganizationTicketStatusesQuery = useGetOrganizationHelpDeskTicketStatusesQuery()

  const isHelpDeskOrganizationTicketStatusesQueryLoading =
    helpDeskOrganizationTicketStatusesQuery.isLoading ||
    !helpDeskOrganizationTicketStatusesQuery?.data

  const helpDeskOrganizationTicketTypeQuery = useGetHelpDeskOrganizationTicketTypeQuery()

  const isHelpDeskOrganizationTicketTypesQueryLoading =
    helpDeskOrganizationTicketTypeQuery.isLoading || !helpDeskOrganizationTicketTypeQuery?.data

  return (
    <Card padded>
      {isHelpDeskOrganizationTicketTypesQueryLoading ||
      isHelpDeskOrganizationTicketStatusesQueryLoading ||
      isHelpDeskTicketsQueryLoading ? (
        <SkeletonTable count={2} />
      ) : (
        <div className="flex flex-col gap-4">
          <HasAccess permission={Permission.HelpDeskEdit}>
            <div className="mb-4 flex justify-end gap-4">
              <Button
                variant="primary"
                size="sm"
                data-testid="btnHelpDeskCreateTicketMobile"
                asChild
              >
                <Link
                  to={Routes.HelpDeskCreate}
                  className="rounded-lg focus-visible:outline-indigo-300"
                >
                  <TicketIcon className="h-4 w-4" />
                </Link>
              </Button>
            </div>
          </HasAccess>
          {helpDeskTicketsQuery?.data?.map((ticket: HelpDeskTicketQueryResult) => {
            return (
              <Accordion key={ticket.id} type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger
                    className="relative justify-between
                    truncate rounded-xl bg-white p-4 [&>div]:overflow-hidden
                    [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none"
                  >
                    <Typography variant="body1" className="truncate">
                      {ticket.subject}
                    </Typography>
                  </AccordionTrigger>
                  <AccordionContent>
                    <AccordionContentList>
                      <AccordionContentListItem
                        label={t('ssp:pages.helpdesk.list.columns.createdBy')}
                        value={ticket?.openedByName ?? ticket.openedByEmail}
                        dataTestId="accCreatedByHelpDeskTicket"
                      />
                      <AccordionContentListItem
                        label={t('ssp:pages.helpdesk.list.columns.lastUpdated')}
                        value={formatDate(ticket.lastUpdated)}
                        dataTestId="accLastUpdatedHelpDeskTicket"
                      />
                      <AccordionContentListItem
                        label={t('ssp:pages.helpdesk.list.columns.ticketType')}
                        value={
                          helpDeskOrganizationTicketTypeQuery?.data?.[ticket.ticketTypeId]?.name
                        }
                        dataTestId="accTicketTypeHelpDeskTicket"
                      />
                      <AccordionContentListItem
                        label={t('ssp:pages.helpdesk.list.columns.status')}
                        value={
                          <HelpDeskTicketStatus
                            status={
                              helpDeskOrganizationTicketStatusesQuery?.data?.[ticket.statusId]
                            }
                          />
                        }
                        dataTestId="accStatusHelpDeskTicket"
                      />
                      <AccordionContentListItem
                        label=" "
                        value={
                          <HasAccess permission={Permission.HelpDeskDetails}>
                            <Tooltip tooltip="View detail">
                              <Button
                                asChild
                                className="gap-1 p-0"
                                variant="none"
                                data-testid="btnHelpDeskTicketMobileShowDetail"
                              >
                                <Link
                                  to={Routes.HelpDeskTicketDetail}
                                  params={{
                                    organizationUniqueName: organization?.uniqueName ?? '',
                                    helpDeskTicketId: ticket?.id ?? '',
                                  }}
                                >
                                  <EyeIcon className="h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                                  <span className="text-indigo-500">
                                    {t('ssp:pages.helpdesk.detail.show')}
                                  </span>
                                </Link>
                              </Button>
                            </Tooltip>
                          </HasAccess>
                        }
                      />
                    </AccordionContentList>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            )
          })}
        </div>
      )}
    </Card>
  )
}
