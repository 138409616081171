import {
  HelpDeskTicketStatus,
  HelpDeskTicketStatusMapping,
  HelpDeskTicketType,
} from '@sherweb/core/openapi-generated/index.defs'

import {
  IBuildRequiredTicketStatus,
  IBuildTicketStatusMapping,
  IBuildTicketType,
} from '../core/helpdesk.model'

export const buildTicketStatuses = (
  ticketStatuses?: HelpDeskTicketStatus[]
): IBuildRequiredTicketStatus => {
  if (!ticketStatuses) {
    return { requiredStatuses: [], optionalStatuses: [] }
  }

  const requiredStatuses: HelpDeskTicketStatus[] = []
  const optionalStatuses: HelpDeskTicketStatus[] = []

  ticketStatuses.forEach(ticketStatus => {
    if (ticketStatus.isRequired) {
      requiredStatuses.push(ticketStatus)
    } else {
      optionalStatuses.push(ticketStatus)
    }
  })

  return {
    requiredStatuses,
    optionalStatuses,
  }
}

export const buildTicketStatusMapping = (
  ticketStatusMapping?: HelpDeskTicketStatusMapping,
  serviceBoardId?: string
): IBuildTicketStatusMapping => {
  if (!ticketStatusMapping || !serviceBoardId) {
    return { ticketStatusMappingList: {} }
  }

  return {
    ...ticketStatusMapping,
    ticketStatusMappingList: {
      [serviceBoardId]:
        ticketStatusMapping?.ticketStatusMappingList?.reduce<Record<string, string[]>>(
          (dataMapper, item) => {
            const key = item.ticketStatusId

            dataMapper[key] = dataMapper[key] || []

            dataMapper[key].push(item?.integrationTicketStatusId ?? '')

            return dataMapper
          },
          {}
        ) ?? {},
    },
  }
}

export const buildTicketTypes = (
  ticketTypes?: HelpDeskTicketType[],
  serviceBoardId?: string
): IBuildTicketType => {
  if (!ticketTypes || !serviceBoardId) {
    return { ticketTypes: [] }
  }

  return {
    ticketTypes,
    configured: {
      [serviceBoardId]:
        ticketTypes
          .filter(ticketTypes => ticketTypes.configured)
          .map(ticketType => ticketType.integrationId ?? '') ?? [],
    },
  }
}
