import {
  CatalogOrganizationFilter,
  CatalogOrganizationSearchCommand,
  CatalogOrganizationSort,
} from '@sherweb/core/openapi-generated/index.defs'

import { DEFAULT_TABLE_PAGE } from '@sherweb/core/utils/const'

export const getCatalogOrganizationsQueryOptions = (
  queryOptions?: Partial<CatalogOrganizationSearchCommand>
): CatalogOrganizationSearchCommand => ({
  page: DEFAULT_TABLE_PAGE,
  pageSize: 20,
  query: '',
  sortBy: CatalogOrganizationSort.NameAscending,
  filter: CatalogOrganizationFilter.All,
  ...queryOptions,
})

// FIX ME - Use the type defined by the backend
export const CATALOG_ORGANIZATIONS_INFINITY_PAGE_SIZE = 10000000
