import * as z from 'zod'

import { looseOptional } from '@sherweb/core/types/zod'

export type SherwebServiceProviderFormType = z.infer<
  ReturnType<typeof useSherwebServiceProviderSchema>
>

const inputFieldsMapper = (values: SherwebServiceProviderFormType) => [
  {
    id: 'clientId',
    value: values.clientId,
  },
  {
    id: 'clientSecret',
    value: values.clientSecret,
  },
  {
    id: 'subscriptionKey',
    value: values.subscriptionKey,
  },
]

export const useSherwebServiceProviderSchema = () => {
  return z
    .object({
      clientSecret: looseOptional(z.string()),
      subscriptionKey: looseOptional(z.string()),
      serviceProviderId: looseOptional(z.string()),
      clientId: looseOptional(
        z.string().uuid('rsp:pages.integrations.serviceProvider.sherweb.form.errorClientIdFormat')
      ),
    })
    .superRefine((values, context) => {
      const errorInputFieldIds = inputFieldsMapper(values)
        .filter(inputField => !inputField.value)
        ?.map(inputField => inputField.id)

      errorInputFieldIds.forEach(errorInputField => {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'rsp:validations.mixed.required',
          path: [errorInputField],
        })
      })
    })
}
