import { Bars3Icon as HamburgerIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { mergeClassName } from '../../utils/mergeClassName'

type HeaderProps = {
  children?: React.ReactNode
  rightSection?: React.ReactNode
  onHamburgerButtonClick?: () => void
  className?: string
}

const Header: React.FC<HeaderProps> = ({
  children,
  onHamburgerButtonClick,
  rightSection,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <div
      className={mergeClassName(
        'bg-primary sticky top-0 z-40 flex h-16 w-full shrink-0 border-b-gray-100 shadow dark:border-b dark:border-slate-800 dark:bg-slate-950 lg:bg-white lg:shadow-sm',
        className
      )}
      data-testid="header"
    >
      {/* hamburger button */}
      {onHamburgerButtonClick && (
        <button
          type="button"
          className="border-r border-transparent px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 dark:border-transparent dark:text-gray-100 lg:hidden"
          onClick={onHamburgerButtonClick}
        >
          <span className="sr-only">{t('core:header.openMenu')}</span>
          <HamburgerIcon className="h-6 w-6 text-white" />
        </button>
      )}

      {/* left + right header sections */}
      <div className="flex flex-1 justify-between lg:px-8 lg:py-4">
        <div className="flex grow items-center">{children}</div>
        <div className="flex grow items-center justify-end gap-6 px-4 lg:gap-7 lg:pr-0">
          {rightSection}
        </div>
      </div>
    </div>
  )
}

export default Header
