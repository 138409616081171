import { cssVariableDarkName } from './theme.const'
import { Theme } from './theme.type'
import { buildColors } from './theme.utils'

export const buildTheme = (theme: Theme) => {
  const variables = Object.entries(theme)
    .map(([colorName, color]) => [
      ...buildColors(colorName, color.light),
      ...buildColors(`${colorName}-${cssVariableDarkName}`, color.dark),
    ])
    .flat()
  return variables.join(' ')
}
