import { Avatar, AvatarFallback, AvatarImage } from '@sherweb/core/components/Avatar'
import { getInitials } from '@sherweb/core/utils/initials'

type PersonAvatarProps = {
  fullName?: string
  avatar?: string
}

const PersonAvatar = ({ fullName, avatar }: PersonAvatarProps) => (
  <Avatar>
    {avatar && <AvatarImage src={avatar} />}
    <AvatarFallback className="bg-slate-200 dark:border dark:border-slate-800 dark:bg-slate-950">
      {getInitials(fullName)}
    </AvatarFallback>
  </Avatar>
)

export default PersonAvatar
