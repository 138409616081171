import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { CheckedState } from '@radix-ui/react-checkbox'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import List from '../../List'
import { Typography } from '../../Typography'
import {
  CollapsibleColumn,
  CollapsibleColumnWithAccessorKey,
  CollapsibleColumnWithId,
  CommonCollapsibleColum,
} from './types'

type CollapsibleDataHeaderProps<TData, TNestedData> = {
  columns: CollapsibleColumn<TData, TNestedData>
  handleSelectAll: () => void
  data: TData[]
  isHeaderFixed?: boolean
  isAllSelected: CheckedState
  currentSelectedAll: boolean
  initialSort?: Partial<{ id: keyof TNestedData | keyof TData; ascending: boolean }>
  setData?: Dispatch<SetStateAction<TData[]>>
}

const isSortingDefined = <TData, TNestedData>(column: CommonCollapsibleColum<TData, TNestedData>) =>
  typeof column?.onSort === 'function'

export const CollapsibleDataHeader = <TData, TNestedData>({
  isAllSelected,
  currentSelectedAll,
  isHeaderFixed,
  columns,
  setData,
  data,
  initialSort,
  handleSelectAll,
}: CollapsibleDataHeaderProps<TData, TNestedData>) => {
  const [sorting, setSorting] = useState<Record<string, boolean | undefined>>(
    initialSort?.id ? { [initialSort?.id]: initialSort?.ascending } : {}
  )

  const handleSortingColumn = useCallback(
    (
      column:
        | CollapsibleColumnWithAccessorKey<TData, TNestedData>
        | CollapsibleColumnWithId<TData, TNestedData>
    ) => {
      if (isSortingDefined(column)) {
        setSorting(previousSorting => {
          const key = (column?.accessorKey ?? column?.id) as string
          const value = !previousSorting?.[key]

          column?.onSort?.({
            data,
            setData,
            ascending: value,
          })

          return {
            ...previousSorting,
            [key]: value,
          }
        })
      }
    },
    [data, setData]
  )

  return (
    <div className="relative">
      <List
        className={mergeClassName('overflow grid grid-cols-12 rounded-l-lg border-none w-full', {
          'fixed border-b border-t-0 border-r-0 border-l-0 border-solid rounded-none dark:bg-slate-800':
            isHeaderFixed,
        })}
        dataTestId="collapsibe-header"
      >
        {columns.map(column => {
          const key = (column?.accessorKey ?? column?.id) as string
          if (column.id === 'select' && typeof column.header === 'function') {
            return (
              <List.Item
                key={column.id}
                className="col-span-2 flex items-center justify-center hover:cursor-default"
              >
                {column.header({
                  checked: isAllSelected,
                  handleSelectAll,
                  data,
                  currentSelectedAll,
                })}
              </List.Item>
            )
          }

          return (
            <List.Item
              key={key}
              data-testid={`collapsible-header collapsible-header-${key}`}
              className={mergeClassName(
                'col-span-2 border-none px-4 py-4 md:px-8 group hover:cursor-default',
                {
                  'hover:cursor-pointer': isSortingDefined(column),
                },
                column.className
              )}
              onClick={() => handleSortingColumn(column)}
            >
              <Typography variant="body2" weight="semiBold" className="relative" as="div">
                {column.header as string}
                {isSortingDefined(column) ? (
                  <>
                    <ChevronUpIcon
                      key={`chevron-up-${column.accessorKey as string}`}
                      data-testid={`chevron-up-${column.accessorKey as string}`}
                      className={mergeClassName(
                        'absolute opacity-0 w-4 h-4 top-[2px] -right-[20px]',
                        {
                          'group-hover:opacity-100': sorting[key],
                        }
                      )}
                    />
                    <ChevronDownIcon
                      key={`chevron-down-${column.accessorKey as string}`}
                      data-testid={`chevron-down-${column.accessorKey as string}`}
                      className={mergeClassName(
                        'absolute opacity-0 w-4 h-4 top-[2px] -right-[20px]',
                        {
                          'group-hover:opacity-100': !sorting[key],
                        }
                      )}
                    />
                  </>
                ) : null}
              </Typography>
            </List.Item>
          )
        })}
      </List>
    </div>
  )
}
