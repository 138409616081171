import { ColorVariable } from './theme.type'

export const colors = Object.values(ColorVariable)

export const colorVariants = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950]

export const defaultColorVariant = 500

export const cssVariablePrefix = 'color'
export const cssVariableDarkName = 'dark'
export const tailwindDefaultColorName = 'DEFAULT'
