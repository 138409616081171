import { Trans, useTranslation } from 'react-i18next'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import { Permission } from '@sherweb/core/modules/authorization'
import { useDateFormatter } from '@sherweb/core/utils/date'

import Link from '@ssp/components/Link/Link'
import { usePermissionCheck } from '@ssp/modules/authorization'

import { useGetSubscription } from './hooks/useGetSubscription'

type SubscriptionFailedAlertProps = {
  supportEmail?: string
  updatingError: boolean
  onDismissError: () => Promise<void>
}

export const SubscriptionFailedAlert = ({
  supportEmail,
  updatingError,
  onDismissError,
}: SubscriptionFailedAlertProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language)

  const subscription = useGetSubscription()

  const { hasAccess } = usePermissionCheck()

  return subscription?.showLicenseUpdateFailedAlert && !updatingError ? (
    <div>
      <Alert
        variant={Variant.Danger}
        onClose={hasAccess(Permission.SubscriptionEdit) ? onDismissError : undefined}
      >
        <Trans
          i18nKey="ssp:pages.subscription.quantityForm.lastOrderPendingQuantityError"
          values={{
            subscriptionName: subscription?.productName,
            subscriptionUpdateDelta: subscription?.lastOrderFailedQuantityDelta,
            subscriptionUpdateOperation: subscription?.subscriptionUpdateOperation,
            subscriptionUpdateDate: formatDate(subscription?.lastPendingOrFailedOrder?.createdAt),
          }}
        >
          {supportEmail && (
            <Link to={`mailto: ${supportEmail}`} className="font-semibold hover:underline" />
          )}
        </Trans>
      </Alert>
    </div>
  ) : null
}
