import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { CatalogOrganizationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { Checkbox } from '@sherweb/core/components/Checkbox'
import { Label } from '@sherweb/core/components/Label'
import List, { listItemVariants } from '@sherweb/core/components/List'
import { NoResults } from '@sherweb/core/components/NoResults'
import { ScrollArea } from '@sherweb/core/components/ScrollArea'
import { SheetPage } from '@sherweb/core/components/Sheet'
import { Typography } from '@sherweb/core/components/Typography'

type MarketplaceCatalogsAssignedOrganizationsContentProps = {
  selectedAssignedOrganizationIds: string[]
  selectedRemovedOrganizationIds: string[]
  catalogOrganizations?: CatalogOrganizationQueryResult[]
  onAssignOrganizations: Dispatch<SetStateAction<string[]>>
  onUnAssignOrganizations: Dispatch<SetStateAction<string[]>>
  infiniteScrollComponent?: React.ReactNode
  title?: React.ReactNode
}

const isChecked = (
  organization: CatalogOrganizationQueryResult,
  selectedAssignedOrganizationIds: string[],
  selectedRemovedOrganizationIds: string[]
) => {
  return selectedAssignedOrganizationIds.includes(organization?.organizationId ?? '') ||
    organization.isAssigned
    ? !selectedRemovedOrganizationIds.includes(organization?.organizationId)
    : organization.isAssigned
}

export const MarketplaceCatalogsAssignedOrganizationsContent = ({
  infiniteScrollComponent,
  catalogOrganizations,
  selectedAssignedOrganizationIds,
  selectedRemovedOrganizationIds,
  title,
  onAssignOrganizations,
  onUnAssignOrganizations,
}: MarketplaceCatalogsAssignedOrganizationsContentProps) => {
  const { t } = useTranslation()

  const handleCheckOrganization = (organizationId: string) => {
    onAssignOrganizations(prevOrganizationIds => [...prevOrganizationIds, organizationId])

    onUnAssignOrganizations(prevOrganizationIds =>
      prevOrganizationIds?.filter(id => id !== organizationId)
    )
  }

  const handleUnCheckOrganization = (organizationId: string) => {
    onAssignOrganizations(prevOrganizationIds =>
      prevOrganizationIds?.filter(id => id !== organizationId)
    )

    onUnAssignOrganizations(prevOrganizationIds => [...prevOrganizationIds, organizationId])
  }

  return (
    <SheetPage.Layout className="flex grow flex-col overflow-y-auto py-4 pt-9">
      {title}
      {catalogOrganizations?.length === 0 ? (
        <NoResults
          emptyMessage={t('rsp:pages.marketplace.manageOrganizations.notFound')}
          className="my-4"
        />
      ) : (
        <ScrollArea>
          <List className="border-none">
            {catalogOrganizations?.map(organization => (
              <List.Item
                key={organization?.organizationId}
                data-testid="listOrganization"
                className="border-b-1 flex h-16 w-full items-center p-0"
              >
                <Label
                  htmlFor={organization?.organizationId}
                  className="flex h-full w-full hover:cursor-pointer"
                >
                  <List.Actions className={listItemVariants({ className: 'space-x-0' })}>
                    <Checkbox
                      id={organization?.organizationId}
                      data-testid="checkboxAssignedOrganization"
                      variant="secondary"
                      checked={isChecked(
                        organization,
                        selectedAssignedOrganizationIds,
                        selectedRemovedOrganizationIds
                      )}
                      onCheckedChange={checked => {
                        if (organization?.organizationId) {
                          if (checked) {
                            handleCheckOrganization(organization.organizationId)

                            return
                          }

                          handleUnCheckOrganization(organization?.organizationId)
                        }
                      }}
                    />
                  </List.Actions>
                  <Typography variant="body2" weight="default" className="flex items-center">
                    {organization?.name}
                  </Typography>
                </Label>
              </List.Item>
            ))}
          </List>
          {infiniteScrollComponent}
        </ScrollArea>
      )}
    </SheetPage.Layout>
  )
}
