import { useTranslation } from 'react-i18next'

import Alert, { Variant } from '@sherweb/core/components/Alert'

import { ERROR_CODE } from '@rsp/utils/error/const'

type ApiInformationPermissionFailedProps = {
  apiInformationError?: string
}

export const ApiInformationPermissionFailed = ({
  apiInformationError,
}: ApiInformationPermissionFailedProps) => {
  const { t } = useTranslation()

  if (
    !apiInformationError ||
    apiInformationError !== ERROR_CODE.CONNECTWISE_IN_SUFFICIENT_PERMISSION
  ) {
    return null
  }

  return (
    <Alert
      variant={Variant.Danger}
      className="items-start [&>svg]:top-4"
      data-testid="alertConnectWiseApiInformationPermissionError"
    >
      <div className="mb-2">
        {t('rsp:pages.integrations.helpdesk.connectWise.apiInformation.permssionError.title')}
      </div>
      <ul className="flex list-outside list-disc flex-col gap-2 pl-5 [&_ul]:list-[revert]">
        <li>
          {t(
            'rsp:pages.integrations.helpdesk.connectWise.apiInformation.permssionError.companyPermssion.missing'
          )}
          <ul className="ml-2">
            <li>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.apiInformation.permssionError.companyPermssion.label'
              )}
            </li>
          </ul>
        </li>
        <li>
          {t(
            'rsp:pages.integrations.helpdesk.connectWise.apiInformation.permssionError.servicePermission.missing'
          )}
          <ul className="ml-2">
            <li>
              {t(
                'rsp:pages.integrations.helpdesk.connectWise.apiInformation.permssionError.servicePermission.label'
              )}
            </li>
          </ul>
        </li>
      </ul>
    </Alert>
  )
}
