import { useTranslation } from 'react-i18next'

import Backlink from '@sherweb/core/components/Backlink'
import { Skeleton } from '@sherweb/core/components/Skeleton'

import Routes from '@rsp/app/Routes'
import Link from '@rsp/components/Link'

import { MarketplaceCardsLoader } from '../components/MarketplaceCardsLoader'

export const MarketplaceCatalogProductPageLoader = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-12 md:gap-16">
      <div className="flex flex-col gap-2">
        <Link to={Routes.Marketplace} data-testid="linkBackToCatalogs">
          <Backlink>{t('rsp:pages.marketplace.catalogs.backToCatalogs')}</Backlink>
        </Link>
        <div>
          <Skeleton className="h-[60px] w-80" />
          <Skeleton className="mt-2 h-[14px] w-96" />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <Skeleton className="h-[36px] w-[300px]" />
          <Skeleton className="h-[36px] w-[130px]" />
        </div>
        <Skeleton className="h-[34px] w-[390px]" />
      </div>
      <div className="mt-4 grid grid-cols-1 gap-4 lg:gap-6 xl:grid-cols-1">
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-6 xl:grid-cols-4">
          <MarketplaceCardsLoader className="h-56">
            <div className="mt-3 flex flex-col gap-2">
              <Skeleton className="h-[20px] w-[90px]" />
              <Skeleton className="h-[20px] w-[180px]" />
            </div>
          </MarketplaceCardsLoader>
        </div>
      </div>
    </div>
  )
}
