import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { Typography } from '@sherweb/core/components/Typography'
import { usePrevious, useQueryParams } from '@sherweb/core/hooks'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { useBillingCycleTranslation } from '@ssp/modules/shop/core/shop.helpers'
import { ShopOffer } from '@ssp/modules/shop/core/shop.model'

import { useGetOffersByCommitmentTerm } from '../hooks/useGetOffersByCommitmentTerm'

export const ShopDetailBillingCycle = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter(language)
  const { getParams, setSearchParams } = useQueryParams<
    'productQuantity' | 'productCommitment' | 'productSku'
  >()
  const selectedCommitmentTerm = getParams<CommitmentTermType>('productCommitment')

  const sku = getParams('productSku')

  const [availableOffers, setAvailableOffers] = useState<ShopOffer[]>([])

  const previousAvailableOffers = usePrevious(availableOffers)

  const getBillingCycleTranslation = useBillingCycleTranslation()

  const { offersByCommitmentTerm, selectedOffer } = useGetOffersByCommitmentTerm()

  const hasAvailableOffersChanged = previousAvailableOffers !== availableOffers

  useEffect(() => {
    if (selectedCommitmentTerm && offersByCommitmentTerm) {
      setAvailableOffers(offersByCommitmentTerm[selectedCommitmentTerm])
    }
  }, [selectedCommitmentTerm, offersByCommitmentTerm])

  useEffect(() => {
    if ((hasAvailableOffersChanged || !sku) && availableOffers?.length > 0) {
      setSearchParams('productSku', availableOffers?.[0]?.sku)
    }
  }, [availableOffers, hasAvailableOffersChanged, setSearchParams, sku])

  return (
    <>
      <Typography variant="body1" weight="medium" className="text-wrap">
        {t('ssp:pages.shop.detail.billingCycle')}
      </Typography>
      <div className="mb-6 mt-2 flex flex-wrap gap-4">
        {availableOffers.map(offer => {
          const formattedPrice = formatMoneyAmount(offer.price)
          return (
            <Button
              className={mergeClassName({
                'border-2 border-indigo-600 dark:border-indigo-200':
                  selectedOffer?.sku === offer.sku,
              })}
              key={(offer.sku ?? '') + '-billing-cycle'}
              variant="outline"
              size="sm"
              type="button"
              onClick={() => {
                setSearchParams('productSku', offer?.sku ?? '')
              }}
            >
              <div className="flex flex-col gap-y-0.5">
                <Typography variant="body2" weight="bold" as="div">
                  {getBillingCycleTranslation(offer.billingCycle)}
                </Typography>
                <div>{formattedPrice}</div>
              </div>
            </Button>
          )
        })}
      </div>
    </>
  )
}
