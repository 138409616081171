import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IntegrationListQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { resellerIntegrations } from './integrations.queries'

export const useGetResellerIntegrationsQuery = <TResult = IntegrationListQueryResult>(
  options?: Omit<UseQueryOptions<IntegrationListQueryResult, Error, TResult>, 'queryKey'>
) => {
  const resellerId = useSelectedResellerId()

  return useQuery<IntegrationListQueryResult, Error, TResult>({
    queryKey: resellerIntegrations.queryKey(resellerId),
    queryFn: async () => await resellerIntegrations.queryFn(resellerId),
    enabled: !!resellerId,
    staleTime: resellerIntegrations.staleTime,
    ...options,
  })
}
