import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '@sherweb/core/components/Tooltip'
import { useChangeLanguageAction, useLanguage, useLanguages } from '@sherweb/core/modules/i18n'

import Link, { LinkProps } from '@ssp/components/Link'

type LanguageSelectorProps = Omit<LinkProps, 'to'>

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ onClick, ...linkProps }) => {
  const { t } = useTranslation()
  const language = useLanguage()
  const languages = useLanguages()
  const changeLanguage = useChangeLanguageAction()

  const languageSwitchTooltip = (language: string, otherLanguage: string) => {
    if (language === 'en') {
      return t('ssp:layout.userMenu.languageToggle.switchToFrenchTooltip')
    }
    return t('ssp:layout.userMenu.languageToggle.switchToEnglishTooltip')
  }

  const languageDisplayName = (language: string) => {
    if (language === 'en') {
      return t('ssp:layout.userMenu.languageToggle.languages.english')
    }
    return t('ssp:layout.userMenu.languageToggle.languages.french')
  }

  const onChangeLanguage = (event: React.MouseEvent<HTMLAnchorElement>, newLanguage: string) => {
    event.preventDefault()
    changeLanguage(newLanguage)
    onClick?.(event)
  }

  if (!language) {
    return null
  }

  return (
    <>
      {languages
        .filter(l => l !== language)
        .map((otherLanguage: string) => (
          <Link
            {...linkProps}
            to="#"
            key={otherLanguage}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              onChangeLanguage(event, otherLanguage)
            }}
            className="group flex items-center text-inherit dark:text-inherit"
            data-testid="linkLanguageSwitch"
          >
            <Tooltip tooltip={languageSwitchTooltip(language, otherLanguage)}>
              <div
                data-testid="btnLanguageSwitch"
                className="flex items-center justify-center gap-1 text-sm font-normal antialiased md:subpixel-antialiased "
              >
                <GlobeAltIcon
                  data-testid="btnLanguageSwitchIcon"
                  className="w-5 stroke-1 text-inherit dark:text-inherit"
                />
                {languageDisplayName(language)}
              </div>
            </Tooltip>
          </Link>
        ))}
    </>
  )
}

export default LanguageSelector
