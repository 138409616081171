type ReactButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

type HeaderButtonProps = {
  Icon: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>
  description?: string
  unreadItemsCount?: number
} & ReactButtonProps

const HeaderButton: React.FC<HeaderButtonProps> = ({
  Icon,
  description,
  unreadItemsCount,
  ...buttonProps
}) => {
  return (
    <button
      type="button"
      className="relative rounded-full border border-slate-50 p-2 text-gray-400 hover:bg-slate-200 hover:text-gray-500 focus:bg-slate-200 focus:outline-none dark:border-slate-800 dark:bg-transparent dark:text-gray-100 md:h-9 md:w-9"
      {...buttonProps}
    >
      {description && <span className="sr-only">{description}</span>}
      <Icon className="h-5 w-5" />
      {unreadItemsCount && (
        <div className="absolute right-0 top-0 -mr-1 -mt-1 flex h-4 w-4">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-purple-400 opacity-75" />
          <div className="relative inline-flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-[10px] font-light text-white">
            {unreadItemsCount}
          </div>
        </div>
      )}
    </button>
  )
}

export default HeaderButton
