import { useTranslation } from 'react-i18next'

import { useDateFormatter } from '@sherweb/core/utils/date'

import { ProductAvatarWithName } from '@ssp/components/Avatars'

export type SubscriptionTableProductNameRowProps = {
  renewalDate?: Date | null
  fullName?: string
}

export const SubscriptionTableProductNameRow = ({
  renewalDate,
  fullName,
}: SubscriptionTableProductNameRowProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language)

  return (
    <ProductAvatarWithName fullName={fullName}>
      {renewalDate ? (
        <div className="font-light">
          {t('ssp:pages.subscriptions.list.renewsOn')} {formatDate(renewalDate)}
        </div>
      ) : null}
    </ProductAvatarWithName>
  )
}
