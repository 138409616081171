import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import { useQueryParams } from '@sherweb/core/hooks'

import { useGetProductById } from '@ssp/modules/shop'

export const useGetOffersByCommitmentTerm = () => {
  const { getParams } = useQueryParams<'productCommitment' | 'productSku'>()

  const selectedCommitmentTerm = getParams<CommitmentTermType>('productCommitment')

  const sku = getParams('productSku')

  const { shopProductId } = useParams()

  const productQuery = useGetProductById(shopProductId ?? '')

  const offersByCommitmentTerm = productQuery.data?.offersByCommitmentTerm

  const selectedOffer = offersByCommitmentTerm?.[selectedCommitmentTerm]?.find(
    offer => offer.sku === sku
  )

  const offersByCommitmentTermKeys = useMemo(
    () =>
      offersByCommitmentTerm
        ? (Object.keys(offersByCommitmentTerm) as Array<keyof typeof offersByCommitmentTerm>)
        : [],
    [offersByCommitmentTerm]
  )

  const hasOnlyNoCommitmentTerm = useMemo(() => {
    const offerByCommitmentTermRecord = offersByCommitmentTerm

    for (const key of offersByCommitmentTermKeys) {
      if (key !== CommitmentTermType.Unknown && offerByCommitmentTermRecord?.[key]?.length !== 0) {
        return false
      }
    }

    return true
  }, [offersByCommitmentTerm, offersByCommitmentTermKeys])

  return {
    offersByCommitmentTerm,
    offersByCommitmentTermKeys,
    selectedOffer,
    hasOnlyNoCommitmentTerm,
  }
}
