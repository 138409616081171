export enum ACTION_TYPES {
  SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION',
}

export const setSelectedOrganization = (id: string) => ({
  type: ACTION_TYPES.SET_SELECTED_ORGANIZATION as const,
  id,
})

export type Actions = ReturnType<typeof setSelectedOrganization>
