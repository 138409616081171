type ServerErrorProps = {
  error?: Error | string
}

const ServerError: React.FC<ServerErrorProps> = ({ error }) => {
  if (!error) {
    return null
  }
  return (
    <div data-testid="server-error">
      An error occured
      <br />
      <pre>{JSON.stringify(error, undefined, 2)}</pre>
    </div>
  )
}

export default ServerError
