import { useInfiniteQuery, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  InvoicesQuery,
  OrganizationInvoiceQueryResult,
  OrganizationPaymentMethodResponse,
} from '@sherweb/core/openapi-generated/index.defs'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { DEFAULT_TABLE_PAGE } from '@sherweb/core/utils/const'

import { useSelectedOrganization } from '@ssp/modules/organization'

import { getOrganizationInvoicesQueryOptions } from './invoices.helpers'
import {
  invoiceDetailsAsyncQueryOptions,
  paymentMethodsByOrganizationIdQueryOptions,
  queryOrganizationInvoicesAsync,
  queryOrganizationInvoicesAsyncWithInfiniteScroll,
} from './invoices.queries'

export const useGetInvoicesQuery = (
  queryOptions: InvoicesQuery = getOrganizationInvoicesQueryOptions()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: queryOrganizationInvoicesAsync.queryKey(selectedOrganization?.id, queryOptions),
    queryFn: async () =>
      await queryOrganizationInvoicesAsync.queryFn(selectedOrganization?.id, queryOptions),
    select: data => data.results,
    enabled: isLoggedIn && !!selectedOrganization?.id,
    staleTime: queryOrganizationInvoicesAsync.staleTime,
  })
}

export const useGetInfiniteScrollInvoicessQuery = (
  queryOptions: InvoicesQuery = getOrganizationInvoicesQueryOptions()
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useInfiniteQuery({
    queryKey: queryOrganizationInvoicesAsyncWithInfiniteScroll.queryKey(
      selectedOrganization?.id,
      queryOptions
    ),
    queryFn: async ({ pageParam = DEFAULT_TABLE_PAGE }) =>
      await queryOrganizationInvoicesAsyncWithInfiniteScroll.queryFn(selectedOrganization?.id, {
        ...queryOptions,
        page: pageParam,
      }),
    enabled: isLoggedIn && !!selectedOrganization?.id,
    staleTime: queryOrganizationInvoicesAsyncWithInfiniteScroll.staleTime,
    getNextPageParam: lastPage => (lastPage?.hasNextPage ? lastPage.pageIndex + 1 : null),
  })
}

export const useGetInvoiceDetailsAsyncQuery = <TResult = OrganizationInvoiceQueryResult>(
  invoiceId?: string,
  integrationType?: string,
  options: Omit<UseQueryOptions<OrganizationInvoiceQueryResult, Error, TResult>, 'queryKey'> = {}
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: invoiceDetailsAsyncQueryOptions.queryKey(
      selectedOrganization?.id,
      invoiceId,
      integrationType
    ),
    queryFn: async () =>
      await invoiceDetailsAsyncQueryOptions.queryFn(
        selectedOrganization?.id,
        invoiceId,
        integrationType
      ),
    enabled: isLoggedIn && !!selectedOrganization?.id && !!invoiceId,
    staleTime: invoiceDetailsAsyncQueryOptions.staleTime,
    ...options,
  })
}

export const useGetPaymentMethodsByOrganizationIdQuery = <
  TResult = OrganizationPaymentMethodResponse[],
>(
  options: Omit<
    UseQueryOptions<OrganizationPaymentMethodResponse[], Error, TResult>,
    'queryKey'
  > = {}
) => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: paymentMethodsByOrganizationIdQueryOptions.queryKey(selectedOrganization?.id),
    queryFn: async () =>
      await paymentMethodsByOrganizationIdQueryOptions.queryFn(selectedOrganization?.id),
    enabled: isLoggedIn && !!selectedOrganization?.id,
    staleTime: paymentMethodsByOrganizationIdQueryOptions.staleTime,
    ...options,
  })
}
