import { Permission } from '@sherweb/core/modules/authorization'

import { usePermissionCheck } from '../core/authorization.hooks'

export interface HasAccessProps {
  permission: Permission
  children?: React.ReactNode
}

export const HasAccess: React.FC<HasAccessProps> = props => {
  const { hasAccess } = usePermissionCheck()

  if (hasAccess(props.permission)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>
  }

  return null
}
