import { PortalType } from '../../../openapi-generated/index.defs'
import { PortalMetadataService } from '../../../openapi-generated/PortalMetadataService'
import { getLocalizedQueryKey } from '../../reactQuery/core/hooks'
import { PortalMode } from './portalMetadata.utils'

export const portalMetadataQuery = {
  queryKey: (portalMode?: PortalMode) =>
    getLocalizedQueryKey(['PortalMetadataService/getPortalMetadata', portalMode]),
  queryFn: async (mode: PortalMode) =>
    await PortalMetadataService.getPortalMetadata({
      portalType: mode === PortalMode.SSP ? PortalType.SSP : PortalType.RSP,
    }),
  staleTime: 60 * 60 * 1000,
}
