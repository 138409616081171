import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage'

import InvoicesTable from './InvoicesTable/InvoicesTable'
import InvoicesTableMobile from './InvoicesTableMobile/InvoicesTableMobile'

const InvoicesPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContainedPage>
        <div className="flex flex-col gap-12 md:gap-16">
          <PageTitleWrapper
            title={
              <PageTitle data-testid="pageInvoicesTitle">{t('ssp:pages.invoices.title')}</PageTitle>
            }
            subTitle={<PageSubTitle>{t('ssp:pages.invoices.subtitle')}</PageSubTitle>}
          />
          <div className="hidden lg:block">
            <InvoicesTable />
          </div>
          <div className="mt-4 lg:hidden">
            <InvoicesTableMobile />
          </div>
        </div>
      </ContainedPage>
      <Outlet />
    </>
  )
}

export default protectPage(InvoicesPage, Permission.InvoicesList)
