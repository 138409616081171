import * as z from 'zod'

import i18n from '@i18n'

const phoneRegex = /^\+?(\d{0,2})\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

const UNSUPPORTED_DOMAINS = ['sherweb.com']

const supportInformationSchema = z.object({
  supportEmail: z
    .string()
    .email({ message: `${i18n.t('rsp:validations.email.format')}` })
    .refine(
      email => !UNSUPPORTED_DOMAINS.some(unsupportedDomain => email?.endsWith(unsupportedDomain)),
      {
        message: i18n.t('rsp:pages.onboarding.supportInformation.error.supportEmail'),
      }
    )
    .optional()
    .or(z.literal('')),
  supportPhoneNumber: z
    .string()
    .regex(phoneRegex, `${i18n.t('rsp:validations.phoneNumber.invalid')}`)
    .optional()
    .or(z.literal('')),
})

export default supportInformationSchema
