import { AxiosRequestHeaders } from 'axios'

import { CoreRoutes } from '../../../common/coreRoutes'
import { IRequestOptions } from '../../../openapi-generated/index.defs'

const httpResponse400 = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
}

export const httpResponse400Codes = Object.values(httpResponse400)

export const responseMapper = {
  [httpResponse400.UNAUTHORIZED]: {
    status: httpResponse400.UNAUTHORIZED,
    adminLocation: CoreRoutes.AdminUnauthorizedAccess,
    nonAdminLocation: CoreRoutes.UnauthorizedAccess,
  },
  [httpResponse400.FORBIDDEN]: {
    status: httpResponse400.FORBIDDEN,
    adminLocation: CoreRoutes.AdminForbiddenAccess,
    nonAdminLocation: CoreRoutes.ForbiddenAccess,
  },
  [httpResponse400.NOT_FOUND]: {
    status: httpResponse400.NOT_FOUND,
    adminLocation: CoreRoutes.AdminNotFound,
    nonAdminLocation: CoreRoutes.NotFound,
  },
}

export const setLocation = (statusCode: number, whiteListed401Paths?: string[]) => {
  const currentResponse = responseMapper[statusCode]
  const currentUrl = window.location.href

  if (
    currentResponse.status === httpResponse400.UNAUTHORIZED &&
    whiteListed401Paths?.includes(window.location.pathname)
  ) {
    return
  }

  document.location = currentUrl.includes(CoreRoutes.Admin)
    ? currentResponse.adminLocation
    : currentResponse.nonAdminLocation
}

const skip404Key = 'skip404'

export const skipHeaders = (options?: Partial<IRequestOptions>): IRequestOptions => {
  return {
    ...options,
    headers: {
      [skip404Key]: true,
    },
  }
}

export const getCodesToSkip = (headers?: AxiosRequestHeaders): number[] => {
  const map = {
    [skip404Key]: 404,
  }
  const codesToSkip: number[] = []

  if (headers) {
    Object.entries(map).forEach(([key, code]) => {
      if (headers.has(key) && headers.get(key)) {
        codesToSkip.push(code)
      }
    })
  }
  return codesToSkip
}
