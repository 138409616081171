import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '@sherweb/core/components/Drawer'
import { Skeleton } from '@sherweb/core/components/Skeleton'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { protectPage } from '@ssp/app/ProtectedPage'
import { useGetInvoiceDetailsAsyncQuery } from '@ssp/modules/invoices/core/invoices.hooks'
import { InvoiceStatus } from '@ssp/modules/invoices/ui/InvoiceStatus'

import { useGetPagetParams } from './hooks/useGetPagetParams'
import { useRedirectToInvoicesPage } from './hooks/useRedirectToInvoicesPage'
import { InvoiceDetailContent } from './InvoiceDetailContent'
import { InvoiceDetailFooter } from './InvoiceDetailFooter'

const InvoiceDetailPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [isOpen, setIsOpen] = useState(true)

  const formatMoneyAmount = useMoneyFormatter(language)

  const { invoiceId, integrationType } = useGetPagetParams()

  const invoiceDetailsAsyncQuery = useGetInvoiceDetailsAsyncQuery(invoiceId, integrationType)

  const handleRedirectToInvoicesPage = useRedirectToInvoicesPage()

  const handleCloseDrawer = () => {
    setIsOpen(false)
    handleRedirectToInvoicesPage()
  }

  return (
    <Drawer open={isOpen} onClose={handleCloseDrawer}>
      <DrawerContent
        className="ml-0 flex h-5/6 flex-col outline-none lg:ml-[260px] lg:mr-[4px]"
        data-testid="pageInvoiceDetail"
        aria-describedby=""
        onEscapeKeyDown={handleCloseDrawer}
      >
        <>
          <DrawerHeader className="mx-auto max-w-4xl p-6 pb-2 md:pb-4" onClose={handleCloseDrawer}>
            <DrawerTitle className="flex items-center gap-2 dark:text-white">
              {isRequestLoading(invoiceDetailsAsyncQuery) ? (
                <Skeleton className="h-[18px] w-[264px]" />
              ) : (
                <div className="flex items-center gap-2" data-testid="pageInvoiceDetailTitle">
                  {t('ssp:pages.invoices.detail.title', {
                    invoiceId: invoiceDetailsAsyncQuery?.data?.externalInvoiceId,
                  })}
                  <div className="flex md:hidden">
                    - {formatMoneyAmount(invoiceDetailsAsyncQuery?.data?.amountDue)}
                  </div>
                  <InvoiceStatus invoiceStatus={invoiceDetailsAsyncQuery?.data?.status} />
                </div>
              )}
            </DrawerTitle>
          </DrawerHeader>
          <InvoiceDetailContent />
          <InvoiceDetailFooter />
        </>
      </DrawerContent>
    </Drawer>
  )
}

export default protectPage(InvoiceDetailPage, Permission.InvoiceDetails)
