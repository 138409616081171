import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'

import OrganizationsTable from './OrganizationsTable/OrganizationsTable'
import OrganizationsTableMobile from './OrganizationsTableMobile/OrganizationsTableMobile'

const OrganizationsPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <ContainedPage>
        <div className="flex flex-col gap-12 md:gap-16">
          <PageTitleWrapper
            title={
              <PageTitle data-testid="pageOrganizationsTitle">
                {t('rsp:pages.organizations.title')}
              </PageTitle>
            }
            subTitle={<PageSubTitle>{t('rsp:pages.organizations.subtitle')}</PageSubTitle>}
          />
          <div className="hidden lg:block">
            <OrganizationsTable />
          </div>
          <div className="mt-4 lg:hidden">
            <OrganizationsTableMobile />
          </div>
        </div>
      </ContainedPage>
      <Outlet />
    </>
  )
}

export default protectPage(OrganizationsPage)
