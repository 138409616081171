import ProductAvatar from './ProductAvatar'

export type ProductAvatarWithNameProps = {
  fullName?: string
  children?: React.ReactNode
}

const ProductAvatarWithName = ({ fullName, children }: ProductAvatarWithNameProps) => (
  <div className="flex flex-row items-center gap-4">
    {fullName ? <ProductAvatar fullName={fullName} /> : null}
    <div className="mb-1 flex flex-col justify-between text-sm font-medium sm:text-sm md:mb-0">
      <div className="font-semibold">{fullName}</div>
      {children}
    </div>
  </div>
)

export default ProductAvatarWithName
