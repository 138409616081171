import { useTranslation } from 'react-i18next'

import {
  InvoiceSortField,
  InvoiceStatus as InvoiceStatusType,
  OrganizationInvoiceQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import Card from '@sherweb/core/components/Card'
import DataTable from '@sherweb/core/components/DataTable'
import { useServerPagination } from '@sherweb/core/components/DataTable/hooks/useServerPagination'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import { isRequestLoading } from '@sherweb/core/modules/reactQuery'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { getInvoicesPaginationDefaultValues } from '@ssp/modules/invoices/core/invoices.helpers'
import { useGetInvoicesQuery } from '@ssp/modules/invoices/core/invoices.hooks'
import { InvoiceStatus } from '@ssp/modules/invoices/ui/InvoiceStatus'

import { InvoicesTableActionRow } from './InvoicesTableActionRow'

const defaultMeta = { className: 'align-top text-center' }

const renderId = (invoiceId?: string) => <div className="font-medium">#{invoiceId}</div>

const renderStatus = (status?: InvoiceStatusType) => <InvoiceStatus invoiceStatus={status} />

const InvoicesTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { options } = useServerPagination<InvoiceSortField>(getInvoicesPaginationDefaultValues())

  const renderActionRowContent = (invoice?: OrganizationInvoiceQueryResult) => (
    <InvoicesTableActionRow invoice={invoice} />
  )

  const invoicesQuery = useGetInvoicesQuery(options)

  const formatMoneyAmount = useMoneyFormatter(language)

  const formatDate = useDateFormatter(language)

  const columns: Columns<OrganizationInvoiceQueryResult> = [
    {
      accessorKey: 'externalInvoiceId',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.id'),
      enableSorting: true,
      cell: ({ row }) => renderId(row.original.externalInvoiceId),
    },
    {
      accessorKey: 'dueDate',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.dueDate'),
      enableSorting: true,
      cell: ({ row }) => formatDate(row.original.dueDate),
    },
    {
      accessorKey: 'externalClientName',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.customer'),
      enableSorting: true,
      cell: ({ row }) => row.original.externalClientName,
    },
    {
      accessorKey: 'amountDue',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.total'),
      enableSorting: false,
      cell: ({ row }) => formatMoneyAmount(row.original.amountDue),
    },
    {
      accessorKey: 'amountRemaining',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.outstanding'),
      enableSorting: false,
      cell: ({ row }) => formatMoneyAmount(row.original.amountRemaining),
    },
    {
      accessorKey: 'status',
      meta: { ...defaultMeta },
      header: t('ssp:pages.invoices.list.status'),
      enableSorting: false,
      cell: ({ row }) => renderStatus(row.original.status),
    },
    {
      id: 'actions',
      meta: { ...defaultMeta },
      enableSorting: false,
      cell: ({ row }) => renderActionRowContent(row.original),
      minSize: 20,
    },
  ]

  return (
    <Card padded>
      {isRequestLoading(invoicesQuery) ? (
        <SkeletonTable />
      ) : (
        <DataTable
          columns={columns}
          data={invoicesQuery?.data}
          dataTestId="tableInvoices"
          emptyMessage={t('ssp:pages.invoices.noInvoice')}
          filterableFields={['externalInvoiceId']}
          filterPlaceholder={t('ssp:pages.invoices.filterPlaceholder')}
          fieldDescription={t('ssp:pages.invoices.fieldDescription')}
          defaultSortingState={[{ id: 'dueDate', desc: true }]}
        />
      )}
    </Card>
  )
}

export default InvoicesTable
