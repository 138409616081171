import { QueryKey } from '@tanstack/react-query'

import { OrganizationSubscriptionsService } from '@sherweb/core/openapi-generated/OrganizationSubscriptionsService'

import { getLocalizedQueryKey } from '@sherweb/core/modules/reactQuery'

export const subscriptionsByOrganizationId = {
  queryKey: (orgId?: string): QueryKey =>
    getLocalizedQueryKey(['subscriptionsByOrganizationId', orgId]),
  queryFn: async (orgId?: string) =>
    orgId
      ? await OrganizationSubscriptionsService.getSubscriptionsByOrganizationId({
          organizationId: orgId,
        })
      : [],
  staleTime: 5 * 1000,
}

export const subscriptionCountByOrganizationId = {
  queryKey: (orgId?: string): QueryKey =>
    getLocalizedQueryKey(['subscriptionCountByOrganizationId', orgId]),
  queryFn: async (orgId?: string) =>
    orgId
      ? await OrganizationSubscriptionsService.getSubscriptionCountByOrganizationAsync({
          organizationId: orgId,
        })
      : { count: 0, isRecalculating: true },
  staleTime: 6 * 10 * 1000,
}
