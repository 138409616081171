/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as z from 'zod'

import i18n from '@i18n'

const VALIDATION_REQUIRED_TRANSLATION_KEY = i18n.t('rsp:validations.mixed.required')

const serviceProviderConfigurationSchema = z.object({
  serviceProvider: z.string().min(1, {
    message: VALIDATION_REQUIRED_TRANSLATION_KEY,
  }),
  publicApiKey: z.string().min(1, {
    message: VALIDATION_REQUIRED_TRANSLATION_KEY,
  }),
  privateApiKey: z.string().min(1, {
    message: VALIDATION_REQUIRED_TRANSLATION_KEY,
  }),
  subscriptionKey: z.string().min(1, {
    message: VALIDATION_REQUIRED_TRANSLATION_KEY,
  }),
})

export default serviceProviderConfigurationSchema
