import {
  HelpDeskTicketStatusType,
  HelpDeskTicketStatus as IHelpDeskTicketStatusType,
} from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'

type HelpdeskTicketStatusProps = {
  status?: IHelpDeskTicketStatusType
}

export const HelpDeskTicketStatus = ({ status }: HelpdeskTicketStatusProps) => {
  const ticketStatusMapper = {
    [HelpDeskTicketStatusType.New]: <Badge variant={Variant.Success}>{status?.name}</Badge>,
    [HelpDeskTicketStatusType.Closed]: <Badge variant={Variant.Default}>{status?.name}</Badge>,
    [HelpDeskTicketStatusType.WaitingForCustomer]: (
      <Badge variant={Variant.Warning}>{status?.name}</Badge>
    ),
    [HelpDeskTicketStatusType.Resolved]: (
      <Badge
        variant={Variant.Default}
        className="border-blue-600/10 bg-blue-100 dark:border-blue-800 dark:text-blue-600"
      >
        {status?.name}
      </Badge>
    ),
    [HelpDeskTicketStatusType.Processing]: <Badge variant={Variant.Info}>{status?.name}</Badge>,
  }

  return status ? ticketStatusMapper[status.type] : null
}
