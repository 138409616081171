import { Typography } from '@sherweb/core/components/Typography'
import { convertToCamelCase } from '@sherweb/core/utils/string'

type VendorProps = {
  className?: string
  vendorType?: string
}

export const Vendor = ({ vendorType, className }: VendorProps) => {
  if (!vendorType) {
    return null
  }

  const renderVendor = () => {
    // TODO: This is a quick solution to show logo for microsoft / google until API is able to provide the url for the vendors
    switch (vendorType) {
      case 'Microsoft':
        return (
          <img
            src="/microsoft-vendor.png"
            alt="Microsoft vendor"
            data-testid="vendorMicrosoft"
            className="h-full"
          />
        )
      case 'Google':
        return (
          <img
            src="/google-vendor.png"
            alt="Google vendor"
            data-testid="vendorGoogle"
            className="h-full"
          />
        )
      default:
        return (
          <Typography
            variant="body2"
            colors="light"
            data-testid={`vendor${convertToCamelCase(vendorType)}`}
          >
            {vendorType}
          </Typography>
        )
    }
  }

  return <div className={className}>{renderVendor()}</div>
}
