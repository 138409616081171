import { useTranslation } from 'react-i18next'

import Backlink from '@sherweb/core/components/Backlink'
import Card from '@sherweb/core/components/Card'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import { Permission } from '@sherweb/core/modules/authorization'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import Link from '@ssp/components/Link'
import UserForm from '@ssp/modules/user/ui'

const UserInvitePage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <div className="flex flex-col gap-2">
          <Link to={Routes.Users} data-testid="linkBackToUsers">
            <Backlink>{t('ssp:pages.user.backlink')}</Backlink>
          </Link>
          <PageTitleWrapper
            title={
              <PageTitle data-testid="pageInviteUserTitle">
                {t('ssp:pages.user.new.title')}
              </PageTitle>
            }
            subTitle={<PageSubTitle>{t('ssp:pages.user.new.pageDescription')}</PageSubTitle>}
          />
        </div>
        <Card padded>
          <UserForm />
        </Card>
      </div>
    </ContainedPage>
  )
}

export default protectPage(UserInvitePage, Permission.UsersCreate)
