import { useTranslation } from 'react-i18next'

import { Label } from '@sherweb/core/components/Label'
import { Tooltip } from '@sherweb/core/components/Tooltip'

import {
  useCommitmentRenewalTranslation,
  useCycleBillingTranslation,
  useSubscription,
} from '@ssp/modules/subscription'

type MetaInformationProps = {
  subscriptionId?: string
}

const MetaInformation: React.FC<MetaInformationProps> = ({ subscriptionId }) => {
  const { t } = useTranslation()
  const { data: subscription } = useSubscription(subscriptionId)
  const getCycleBillingTranslation = useCycleBillingTranslation()
  const getCommitmentRenewalTranslation = useCommitmentRenewalTranslation()

  const getSubscriptionValueOrDefault = (
    value: any,
    formatAction?: (val: any) => React.ReactNode
  ): React.ReactNode => {
    if (value) {
      if (formatAction) {
        return formatAction(value)
      }
      return value
    }
    return (
      <Tooltip tooltip={t('ssp:pages.subscription.fieldNotAvailable')}>
        {t('ssp:pages.subscription.fieldNA')}
      </Tooltip>
    )
  }

  const cycle = getCycleBillingTranslation(subscription?.billingCycle)
  const commitmentRenewalTranslation = getCommitmentRenewalTranslation(subscription?.commitmentType)

  return (
    <div className="grid grid-cols-3 gap-2 md:gap-8">
      <div className="flex flex-col gap-2">
        <Label className="leading-5 text-slate-500 dark:text-slate-200">
          {t('ssp:pages.subscription.bilingCycle')}
        </Label>
        <div className="text-sm text-gray-900 dark:text-white">
          {getSubscriptionValueOrDefault(cycle)}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Label className="leading-5 text-slate-500 dark:text-slate-200">
          {t('ssp:pages.subscription.commitmentTerm')}
        </Label>
        <div className="text-sm text-gray-900 dark:text-white">
          {getSubscriptionValueOrDefault(commitmentRenewalTranslation)}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Label className="leading-5 text-slate-500 dark:text-slate-200">
          {t('ssp:pages.subscription.currentQuantity')}
        </Label>
        <div className="text-sm text-gray-900 dark:text-white">{subscription?.quantity}</div>
      </div>
    </div>
  )
}

export default MetaInformation
