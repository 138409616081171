import { OrderSortField } from '@sherweb/core/openapi-generated/index.defs'

import { getDefaultParameters } from '@sherweb/core/components/DataTable/const'
import { Pagination } from '@sherweb/core/components/DataTable/types'

export const getOrdersPaginationDefaultValues = (options?: Partial<Pagination<OrderSortField>>) => {
  return getDefaultParameters<OrderSortField>({
    sort: {
      field: OrderSortField.CreatedOn,
      ascending: false,
    },
    ...options,
  })
}
