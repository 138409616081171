import { mergeClassName } from '../../utils/mergeClassName'

type MainGradientBackgroundProps = {
  className?: string
}

const MainGradientBackground = ({ className = '' }: MainGradientBackgroundProps) => (
  <div
    className={mergeClassName('fixed h-screen w-screen dark:bg-slate-950 dark:bg-none', className)}
    data-testid="main-gradient"
  />
)

export default MainGradientBackground
