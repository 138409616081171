import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Typography } from '@sherweb/core/components/Typography'

type SubscriptionCancelProps = {
  onShowCancelConfirmation: () => void
}

export const SubscriptionCancel = ({ onShowCancelConfirmation }: SubscriptionCancelProps) => {
  const { t } = useTranslation()

  const handleCancel = () => {
    onShowCancelConfirmation()
  }

  return (
    <div className="flex flex-row gap-4 px-4 pt-10 hover:cursor-pointer" onClick={handleCancel}>
      <div className="inline-flex h-11 w-11 items-center justify-center rounded-lg bg-red-100">
        <ArchiveBoxXMarkIcon className="h-7 w-7 text-red-500" />
      </div>
      <div className="flex flex-col">
        <Typography variant="body2" weight="medium">
          {t('ssp:pages.subscription.details.tabs.manageSubscription.cancel.title')}
        </Typography>
        <Typography variant="body2" colors="light">
          {t('ssp:pages.subscription.details.tabs.manageSubscription.cancel.description')}
        </Typography>
      </div>
    </div>
  )
}
