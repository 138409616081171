import { createContext, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmationDialog from '../ConfirmationDialog'

export interface PromptOptions {
  title?: string
  confirmButtonText?: string
  cancelButtonText?: string
  children?: React.ReactNode
  onConfirm?: () => void
  onCancel?: () => void
}

export type ConfirmContextType = {
  show: (options: PromptOptions) => void
}

type PromptProviderProps = {
  children: React.ReactNode
}

export const PromptContext = createContext<ConfirmContextType | null>(null)

export const PromptProvider = ({ children }: PromptProviderProps) => {
  const { t } = useTranslation()

  const [prompt, setPrompt] = useState<PromptOptions | null>(null)

  const [isOpen, setIsOpen] = useState(false)

  const handleShow = useCallback(
    (promptOptions: PromptOptions) => {
      setPrompt(promptOptions)
      setIsOpen(true)
    },
    [setIsOpen]
  )

  const handleConfirm = useCallback(() => {
    prompt?.onConfirm?.()
    setIsOpen(false)
  }, [prompt])

  const handleClose = useCallback(() => {
    prompt?.onCancel?.()
    setIsOpen(false)
  }, [prompt])

  const value = useMemo(
    () => ({
      show: handleShow,
    }),
    [handleShow]
  )

  return (
    <PromptContext.Provider value={value}>
      <ConfirmationDialog
        open={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title={prompt?.title ?? t('core:actions.discardChanges')}
        confirmButtonText={prompt?.confirmButtonText ?? t('core:actions.confirm')}
        cancelButtonText={prompt?.cancelButtonText ?? t('core:actions.cancel')}
      >
        {prompt?.children}
      </ConfirmationDialog>
      {children}
    </PromptContext.Provider>
  )
}
