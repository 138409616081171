import clsx from 'clsx'
import React, { useState } from 'react'

import MainGradientBackground from '@sherweb/core/components/MainGradientBackground'
import Sidebar from '@sherweb/core/components/Sidebar'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import FullWidthPage from '@ssp/components/layout/FullWidthPage'
import Header from '@ssp/components/layout/Header'
import SidebarMenu from '@ssp/components/layout/SidebarMenu'

type ContainedPageProps = {
  children?: React.ReactNode
  padded?: boolean
  centered?: boolean
  showSidebar?: boolean
  showCaseChildren?: React.ReactNode
  className?: string
}

const ContainedPage: React.FC<ContainedPageProps> = ({
  children,
  padded = true,
  centered = true,
  showSidebar = true,
  showCaseChildren,
  className = '',
}) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = () => {
    setMenuOpen(true)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  return (
    <>
      <MainGradientBackground className="bgGradCircles" />
      <FullWidthPage className={className}>
        <div
          className={mergeClassName('flex min-h-screen', {
            'lg:grid lg:grid-cols-[16rem_auto]': showSidebar,
          })}
        >
          {showSidebar ? (
            <Sidebar open={menuOpen} onClose={closeMenu}>
              <SidebarMenu />
            </Sidebar>
          ) : null}
          <div className="z-20 flex w-full flex-col">
            <Header onHamburgerButtonClick={openMenu} />
            <div className="relative lg:w-full">
              {showCaseChildren ? (
                <div className="pointer-events-none absolute z-30 h-full w-full bg-gradient-to-b from-slate-900/50 to-slate-900">
                  {showCaseChildren}
                </div>
              ) : null}
              <div
                className={clsx(
                  'text-gray-900 dark:text-slate-200',
                  padded && 'px-4 py-8 sm:px-6 sm:py-10 lg:px-10 lg:py-8',
                  centered && 'mx-auto w-full max-w-screen-2xl',
                  showCaseChildren && 'pointer-events-none select-none grayscale'
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </FullWidthPage>
    </>
  )
}

export default ContainedPage
