import { Reducer } from 'redux'
import { persistReducer as reduxPersistReducer } from 'redux-persist'
import { PersistedState } from 'redux-persist/es/types'
import localStorageStorage from 'redux-persist/lib/storage'

export type PersistReducerOptions = {
  key: string
  shared?: boolean
  version: number
  persistFields?: string[]
  migrate?: (state: any, currentVersion?: number) => Promise<any>
}

const performMigration =
  (migrate: any) => async (state: PersistedState, currentVersion: number) => {
    const version = state?._persist.version
    /* istanbul ignore if  */
    if (currentVersion === version) {
      return state
    }
    return migrate(state, version)
  }

export const persister =
  (appName: string) =>
  <T>(reducer: T, { key, shared, version, persistFields, migrate }: PersistReducerOptions): T =>
    reduxPersistReducer(
      {
        storage: localStorageStorage,
        key,
        keyPrefix: shared ? undefined : `persist:${appName}_`,
        version,
        whitelist: persistFields,
        migrate: migrate ? performMigration(migrate) : undefined,
      },
      reducer as Reducer<any, any>
    ) as T
