import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CookiesProvider } from 'react-cookie'
import { I18nextProvider } from 'react-i18next'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import i18n from '@i18n'

import { PromptProvider } from '@sherweb/core/components/Prompt'
import { ErrorBoundary } from '@sherweb/core/modules/datadog'
import { HubProvider } from '@sherweb/core/modules/signalR'

import ToastContainer from '@ssp/components/ToastContainer/ToastContainer'
import { persistor, store } from '@ssp/modules/redux'
import { HubService } from '@ssp/modules/signalR'
import { ThemeProvider } from '@ssp/modules/theme'
import Error500 from '@ssp/pages/errors/Error500'

import config from './config'
import reactQueryClient from './reactQueryClient'
import { router } from './router'

const Main = () => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CookiesProvider>
        <QueryClientProvider client={reactQueryClient}>
          <ThemeProvider>
            <I18nextProvider i18n={i18n}>
              <ErrorBoundary fallback={<Error500 />}>
                <PromptProvider>
                  <HubProvider bffUrl={config.bff.sspUrl} hubService={HubService}>
                    <RouterProvider router={router} />
                  </HubProvider>
                  <ToastContainer />
                </PromptProvider>
              </ErrorBoundary>
            </I18nextProvider>
            {config.isDevelopment && <ReactQueryDevtools />}
          </ThemeProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </PersistGate>
  </ReduxProvider>
)

export default Main
