import { useTranslation } from 'react-i18next'

import Badge, { Variant } from '@sherweb/core/components/Badge'

export const useRenderStatusBadge = () => {
  const { t } = useTranslation()

  return (isSuspended: boolean) => {
    if (isSuspended) {
      return (
        <Badge variant={Variant.Warning} className="ml-4">
          {t('rsp:pages.organizations.list.statuses.suspended')}
        </Badge>
      )
    }
    return (
      <Badge variant={Variant.Success} className="ml-4">
        {t('rsp:pages.organizations.list.statuses.active')}
      </Badge>
    )
  }
}

export const useRenderSettingsType = () => {
  const { t } = useTranslation()

  return (isDefault?: boolean) => {
    if (isDefault) {
      return <div>{t('rsp:pages.organizations.list.settingsTypes.default')}</div>
    }
    return <div>{t('rsp:pages.organizations.list.settingsTypes.custom')}</div>
  }
}
