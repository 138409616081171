import { QueryKey } from '@tanstack/react-query'

import { OrganizationUsersService } from '@sherweb/core/openapi-generated/OrganizationUsersService'

export const usersByOrganizationId = {
  queryKey: (orgId?: string): QueryKey => ['usersByOrganizationId', orgId],
  queryFn: async (orgId?: string) =>
    orgId ? await OrganizationUsersService.getUsersAsync({ organizationId: orgId }) : [],
  staleTime: 10 * 60 * 1000,
}

export const userByOrganizationId = {
  queryKey: (organizationId?: string, userId?: string): QueryKey => [
    'OrganizationUsersService/getUser',
    organizationId,
    userId,
  ],
  queryFn: async (organizationId: string, userId: string) => {
    return await OrganizationUsersService.getUser({
      organizationId,
      userId,
    })
  },
}

export const userCountByOrganizationId = {
  queryKey: (orgId?: string): QueryKey => ['userCountByOrganizationId', orgId],
  queryFn: async (orgId?: string) =>
    orgId
      ? await OrganizationUsersService.getCountUsersAsync({ organizationId: orgId })
      : { count: 0, isRecalculating: true },
  staleTime: 10 * 60 * 1000,
}
