import {
  SubscriberType as ApiSubscriberType,
  UnusedLicensesStatistics as ApiUnusedLicenseStatistics,
  UsedLicenseStatistics as ApiUsedLicenseStatistics,
  LicenseQueryResult,
  LicensesByVendorQueryResult,
  SubscriberQueryResult,
  SubscribersByVendorQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import {
  DomainByVendor,
  License,
  Subscriber,
  SubscriberType,
  UnusedLicenseStatistics,
  UsedLicenseStatistics,
} from './license.model'

export const buildLicense = (
  license: LicenseQueryResult,
  lastSyncedOn: Date,
  vendorName?: string
): License => {
  return {
    ...license,
    vendorName,
    lastSyncedOn,
  }
}

export const buildLicenses = (vendor: LicensesByVendorQueryResult): License[] =>
  vendor?.licenses
    ? vendor.licenses.map(license => buildLicense(license, vendor.lastSyncedOn, vendor.vendorName))
    : []

export const buildLicensesByVendor = (
  licensesByVendor?: LicensesByVendorQueryResult[]
): License[] => (licensesByVendor ? licensesByVendor.flatMap(buildLicenses) : [])

export const buildSubscriber = (subscriber: SubscriberQueryResult): Subscriber => {
  return {
    ...subscriber,
    type: mapSubscriberType(subscriber.type),
  }
}

export const buildSubscribers = (vendor: SubscribersByVendorQueryResult): Subscriber[] =>
  vendor?.subscribers ? vendor.subscribers.map(subscriber => buildSubscriber(subscriber)) : []

export const buildSubscribersByVendor = (
  subscribersByVendor?: SubscribersByVendorQueryResult[]
): Subscriber[] => (subscribersByVendor ? subscribersByVendor.flatMap(buildSubscribers) : [])

const mapSubscriberType = (type?: ApiSubscriberType): SubscriberType =>
  SubscriberType[type as keyof typeof SubscriberType]

export const buildDomainsByVendor = (
  subscribersByVendor?: SubscribersByVendorQueryResult[],
  vendorName?: string | null
): DomainByVendor[] =>
  subscribersByVendor
    ?.find(subscriberByVendor => subscriberByVendor.vendorName === vendorName)
    ?.domains?.map(domain => ({
      ...domain,
      vendorName,
    })) ?? []

export const buildUsedLicenseStatistics = (
  data?: Partial<ApiUsedLicenseStatistics>
): UsedLicenseStatistics => ({
  isRecalculating: data?.isRecalculating ?? true,
  currentUserLicensesCount: data?.currentUserLicensesCount ?? 0,
  currentUserLicenses: data?.currentUserLicenses ?? [],
})

export const buildUnusedLicenseStatistics = (
  data?: Partial<ApiUnusedLicenseStatistics>
): UnusedLicenseStatistics => ({
  isRecalculating: data?.isRecalculating ?? true,
  unusedLicensesCount: data?.unusedLicensesCount ?? 0,
  unusedLicenses:
    data?.unusedLicenses?.map(p => ({
      name: p?.name ?? '',
      unusedLicenseCount: p?.unusedLicenseCount ?? 0,
    })) ?? [],
})
