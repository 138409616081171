import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type PageTitleWrapperProps = {
  title: React.ReactNode
  subTitle?: React.ReactNode
  className?: string
}

export const PageTitleWrapper = ({ title, subTitle, className }: PageTitleWrapperProps) => (
  <div className={mergeClassName('flex flex-col gap-2', className)}>
    {title}
    {subTitle}
  </div>
)
