import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import {
  HelpDeskTicketDetailsQueryResult,
  HelpDeskTicketNoteQueryResult,
  HelpDeskTicketQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import Routes from '@ssp/app/Routes'
import { useNavigate, usePathGenerator } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'

export const useGetHelpDeskRouteParams = (params?: Record<string, string>) => {
  const organization = useSelectedOrganization()

  const organizationUniqueName = organization?.uniqueName

  if (params && organizationUniqueName) {
    return { organizationUniqueName, ...params }
  }

  if (organizationUniqueName) {
    return { organizationUniqueName }
  }
}

export const useNavigateToHelpDeskPage = () => {
  const generatePath = usePathGenerator()

  const { navigate } = useNavigate()

  const helpDeskRouteParams = useGetHelpDeskRouteParams()

  return useCallback(() => {
    if (helpDeskRouteParams?.organizationUniqueName) {
      navigate(
        generatePath(Routes.HelpDesk, {
          organizationUniqueName: helpDeskRouteParams?.organizationUniqueName,
        })
      )
    }
  }, [generatePath, helpDeskRouteParams?.organizationUniqueName, navigate])
}

export const useOptimisticUpdateDetailNote = () => {
  const queryClient = useQueryClient()

  const setNote = (queryKey: Array<string | undefined>, data: HelpDeskTicketNoteQueryResult) => {
    const previousDetail = queryClient.getQueryData<HelpDeskTicketDetailsQueryResult>(queryKey)

    queryClient.setQueryData(queryKey, {
      ...previousDetail,
      notes: [...(previousDetail?.notes ?? []), data],
    })

    return previousDetail
  }

  return { setNote }
}

export const useOptimisticUpdateTickets = () => {
  const queryClient = useQueryClient()

  const updateTickets = (queryKey: Array<string | undefined>, data: HelpDeskTicketQueryResult) => {
    const previousTickets = queryClient.getQueryData<HelpDeskTicketQueryResult[]>(queryKey) ?? []

    queryClient.setQueryData(queryKey, [data, ...previousTickets])

    return previousTickets
  }

  return { updateTickets }
}
