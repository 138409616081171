import { Trans, useTranslation } from 'react-i18next'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'

type CoreUserType = {
  id: string
  firstName?: string | null
  lastName?: string | null
  email?: string | null
}

export type UserDeleteConfirmationDialogProps<T> = {
  open: boolean
  selectedUser: T | null
  onConfirm: () => void
  onClose: () => void
}

export const UserDeleteConfirmationDialog = <T extends CoreUserType>({
  open,
  selectedUser,
  onConfirm,
  onClose,
}: UserDeleteConfirmationDialogProps<T>) => {
  const { t } = useTranslation()

  return (
    <ConfirmationDialog
      open={open}
      title={t('core:user.userDeletionDialog.title')}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmButtonText={t('core:actions.delete')}
      cancelButtonText={t('core:actions.cancel')}
      destructive
    >
      <Trans
        i18nKey="core:user.userDeletionDialog.description"
        values={{
          firstName: selectedUser?.firstName,
          lastName: selectedUser?.lastName,
          emailAddress: selectedUser?.email,
        }}
        components={{ 1: <br /> }}
      />
    </ConfirmationDialog>
  )
}
