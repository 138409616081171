import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { SheetPage } from '@sherweb/core/components/Sheet'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { Typography } from '@sherweb/core/components/Typography'
import { OrderDetailContent } from '@sherweb/core/modules/orders/ui/OrderDetailContent'

import Routes from '@ssp/app/Routes'
import { useNavigate } from '@ssp/modules/navigation'
import { useGetOrderQuery } from '@ssp/modules/orders'
import { useSelectedOrganization } from '@ssp/modules/organization'

const OrderDetailPage = () => {
  const { t } = useTranslation()

  const { orderId } = useParams()

  const selectedOrganization = useSelectedOrganization()

  const orderQuery = useGetOrderQuery(orderId)

  const { navigate } = useNavigate()

  const isLoading = orderQuery.isLoading || !orderQuery?.data

  const handleClose = () => {
    if (selectedOrganization?.uniqueName) {
      navigate(Routes.Orders, {
        organizationUniqueName: selectedOrganization?.uniqueName ?? '',
      })
    }
  }

  return (
    <SheetPage
      title={
        isLoading ? null : (
          <Typography variant="heading6" weight="bold" className="text-wrap">
            {t('core:orders.detail.title', { orderId: orderQuery?.data?.humanReadableId })}
          </Typography>
        )
      }
      onClose={handleClose}
    >
      {isLoading ? (
        <Spinner
          dataTestId="layoverSpinner"
          className={spinnerStyles({ type: 'layOverSpinner' })}
        />
      ) : null}
      <OrderDetailContent order={orderQuery?.data} />
    </SheetPage>
  )
}

export default OrderDetailPage
