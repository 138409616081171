import { useTranslation } from 'react-i18next'

import { CatalogProductQueryResult, Money } from '@sherweb/core/openapi-generated/index.defs'

import Badge from '@sherweb/core/components/Badge'
import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'
import { Vendor } from '@sherweb/core/modules/marketplace/ui/Vendor'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

type MarketplaceCatalogProductCardProps = {
  product: CatalogProductQueryResult
}

export const MarketplaceCatalogProductCard = ({ product }: MarketplaceCatalogProductCardProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter<Money>(language)

  return (
    <Card
      key={product.id}
      className="flex flex-col justify-between md:p-4"
      data-testid="cardCatalogProduct"
    >
      <div className="flex h-full flex-col justify-between gap-1 md:gap-4">
        <div>
          <Vendor vendorType={product.vendorName} className="mb-2 h-[16px]" />
          <CardTitle className="sm:text-sm" weight="medium">
            {product.name}
          </CardTitle>
        </div>
        <div className="flex flex-col justify-between">
          <div className="mb-4 flex flex-wrap items-center gap-x-2">
            <div className="mt-3 flex flex-col items-start gap-2">
              <div className="flex flex-wrap gap-1">
                {product.offers?.map(offer => (
                  <Badge
                    data-testid="productOffer"
                    key={offer.sku}
                    className="border border-slate-200 bg-slate-200/50 px-2 text-xs font-medium text-slate-600 dark:bg-slate-950 dark:text-slate-200"
                  >
                    {t(`rsp:pages.marketplace.catalogs.billingCycleValues.${offer.billingCycle}`)}
                    {offer.commitmentTermType === 'Open' ? null : (
                      <>
                        &nbsp;{t(`rsp:pages.marketplace.catalogs.for`)}
                        &nbsp;
                        {t(
                          `rsp:pages.marketplace.catalogs.commitmentValues.${offer.commitmentTermType}`
                        )}
                      </>
                    )}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <Typography variant="label" colors="light">
              {t('rsp:pages.marketplace.catalogs.startingPrice')}
            </Typography>
            <Typography variant="body1" weight="semiBold" data-testid="productPrice">
              {formatMoneyAmount(product.startingPrice)}
            </Typography>
          </div>
        </div>
      </div>
    </Card>
  )
}
