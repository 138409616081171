// based on: https://ui.shadcn.com/docs/components/tooltip#installation

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { forwardRef } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

const TooltipContent = forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={mergeClassName(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-i-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1 dark:bg-slate-950 dark:border-slate-800',
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

type TooltipProps = {
  children?: React.ReactNode
  tooltip?: React.ReactNode
  className?: string
  wrapperClassName?: string
} & TooltipPrimitive.TooltipProps

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  tooltip,
  className,
  wrapperClassName,
  ...tooltipProps
}) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root {...tooltipProps}>
        <TooltipPrimitive.Trigger className={wrapperClassName} asChild>
          <div className="inline-flex">{children}</div>
        </TooltipPrimitive.Trigger>
        <TooltipContent className={className} data-testid="tooltip">
          {tooltip}
        </TooltipContent>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export default Tooltip
