import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { OrderSettingsFormType } from './hooks/useOrderSettingsSchema'
import { OrderEmailsNotifications } from './OrderEmailsNotifications'
import { OrderTeamsNotifications } from './OrderTeamsNotifications'

type OrderNotificationsProps = {
  hasConnectionFailed?: boolean
  reset: () => void
  renderTeamsActionsComponent: ({
    teamNotificationsWebHookUrl,
    isWebHookValid,
  }: {
    teamNotificationsWebHookUrl?: string
    isWebHookValid: () => Promise<boolean>
  }) => React.ReactNode
}

export const OrderNotifications = ({
  hasConnectionFailed = false,
  renderTeamsActionsComponent,
  reset,
}: OrderNotificationsProps) => {
  const { t } = useTranslation()

  const form = useFormContext<OrderSettingsFormType>()

  const [teamNotificationsWebHookUrl] = form.watch(['teamNotifications.webHookUrl'])

  const isWebHookValid = async () => await form.trigger('teamNotifications.webHookUrl')

  return (
    <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
      <div className="w-full sm:w-9/12">
        <label className="font-semibold">
          {t('rsp:pages.settings.orderSettings.orders.notifications.title')}
        </label>
        <div className="text-xs text-gray-500 dark:text-slate-300">
          {t('rsp:pages.settings.orderSettings.orders.notifications.description')}
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col gap-8">
          <OrderEmailsNotifications />
          <OrderTeamsNotifications hasConnectionFailed={hasConnectionFailed} reset={reset}>
            {renderTeamsActionsComponent({ teamNotificationsWebHookUrl, isWebHookValid })}
          </OrderTeamsNotifications>
        </div>
      </div>
    </div>
  )
}
