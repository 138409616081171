import { useAvailableOrganizations } from './organization.hooks'
import { Organization } from './organization.model'

export const useAvailableOrganizationsRepository = () => {
  const { data: organizations } = useAvailableOrganizations()
  return {
    findById: findById(organizations),
    findByUniqueName: findByUniqueName(organizations),
  }
}

export const findById = (organizations: Organization[]) => (givenId: string) => {
  return organizations.find(({ id }) => id === givenId)
}

export const findByUniqueName = (organizations: Organization[]) => (givenUniqueName: string) => {
  return organizations.find(({ uniqueName }) => uniqueName === givenUniqueName)
}
