import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/modules/authorization'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { HasAccess } from '@ssp/modules/authorization'
import { getTotalPrice, useSubscription } from '@ssp/modules/subscription'

type PriceSectionProps = {
  subscriptionId?: string
  quantity?: number
}

const PriceSection: React.FC<PriceSectionProps> = ({ subscriptionId, quantity }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatMoneyAmount = useMoneyFormatter(language)

  const { data: subscription } = useSubscription(subscriptionId)

  const total = getTotalPrice(subscription?.price, quantity ?? subscription?.quantity)

  if (!subscription?.price || !total) {
    return null
  }

  return (
    <HasAccess permission={Permission.SubscriptionPrices}>
      <div className="flex flex-col gap-2">
        <label className="text-sm font-medium leading-5 text-slate-500 peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-slate-200">
          {t('ssp:pages.subscription.total')}
        </label>
        <span className="text-slate-800 dark:text-white">
          <div className="inline-flex text-2xl font-bold leading-6 " data-state="closed">
            {formatMoneyAmount(total)}
          </div>
        </span>
      </div>
    </HasAccess>
  )
}

export default PriceSection
