type sortArg<T> = keyof T | `-${string & keyof T}`

/**
 * Returns a comparator for objects of type T that can be used by sort
 * functions, where T objects are compared by the specified T properties.
 *
 * @param sortBy - the names of the properties to sort by, in ascending order.
 *                 Prefix any name with `-` to sort it in descending order.
 * @params options: { ignoreCase } - Allowing the sort to be case sensitive or not
 */
export function byPropertiesOf<T extends object>(
  sortBy: Array<sortArg<T>>,
  options?: { ignoreCase: boolean }
) {
  function compareByProperty(arg: sortArg<T>) {
    let key: keyof T

    let sortOrder = 1

    if (typeof arg === 'string' && arg.startsWith('-')) {
      sortOrder = -1
      // Typescript is not yet smart enough to infer that substring is keyof T
      key = arg.substring(1) as keyof T
    } else {
      // Likewise it is not yet smart enough to infer that arg here is keyof T
      key = arg as keyof T
    }

    return function (a: T, b: T) {
      const item1 = a[key]

      const item2 = b[key]

      const finalItem1: T[keyof T] | string =
        options?.ignoreCase && typeof item1 === 'string' ? item1.toLowerCase() : item1

      const finalItem2: T[keyof T] | string =
        options?.ignoreCase && typeof item2 === 'string' ? item2.toLowerCase() : item2

      const resultBiggerThan = finalItem1 > finalItem2 ? 1 : 0

      const result = finalItem1 < finalItem2 ? -1 : resultBiggerThan

      return result * sortOrder
    }
  }

  return function (obj1: T, obj2: T) {
    let i = 0
    let result = 0
    const numberOfProperties = sortBy?.length
    while (result === 0 && i < numberOfProperties) {
      result = compareByProperty(sortBy[i])(obj1, obj2)
      i++
    }
    return result
  }
}

/**
 * Sorts an array of T by the specified properties of T.
 *
 * @param arr - the array to be sorted, all of the same type T
 * @param sortBy - the names of the properties to sort by, in ascending order.
 *                 Prefix any name with `-` to sort it in descending order.
 * @params options: { ignoreCase } - Allowing the sort to be case sensitive or not
 */
export function sort<T extends object>(
  arr: T[],
  sortBy: Array<sortArg<T>> | sortArg<T>,
  options = { ignoreCase: false }
) {
  if (Array.isArray(sortBy)) {
    return arr.sort(byPropertiesOf<T>(sortBy, options))
  }

  return arr.sort(byPropertiesOf<T>([sortBy], options))
}
