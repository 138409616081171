import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import { Textarea } from '@sherweb/core/components/Textarea'

import { HasAccess } from '@ssp/modules/authorization'
import { OrderDecision, OrderStatus, useProcessOrder } from '@ssp/modules/subscription'

type OrderApprovalProps = {
  orderId?: string
  orderStatus?: OrderStatus | null
}

const OrderApproval: React.FC<OrderApprovalProps> = ({ orderId, orderStatus }) => {
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)
  const [decision, setDecision] = useState<OrderDecision | null>(null)
  const { processOrder } = useProcessOrder()
  const [decisionNote, setDecisionNote] = useState<string | undefined>(undefined)

  const { t } = useTranslation()

  if (orderStatus !== OrderStatus.WaitingForApproval) {
    return null
  }

  const setDecisionAndOpenDialog = (decision: OrderDecision) => {
    setDecision(decision)
    setConfirmationDialogOpened(true)
  }

  const cancelConfirmation = () => {
    setDecisionNote(undefined)
    setDecision(null)
    setConfirmationDialogOpened(false)
  }

  const onSubmit = async (decision: OrderDecision | null) => {
    await processOrder(orderId, decision, decisionNote)
  }

  const dialogBodyKey =
    decision === OrderDecision.Approve
      ? 'ssp:subscription.order.decision.confirmationModal.approveBody'
      : 'ssp:subscription.order.decision.confirmationModal.rejectBody'

  return (
    <HasAccess permission={Permission.OrderApproval}>
      <div className="flex flex-row gap-4">
        <Button
          variant={Variant.Success}
          size="sm"
          className="grow"
          onClick={() => setDecisionAndOpenDialog(OrderDecision.Approve)}
        >
          <CheckIcon className="h-5 w-5 stroke-2" /> {t('ssp:subscription.order.decision.approve')}
        </Button>
        <Button
          variant={Variant.Danger}
          size="sm"
          className="grow"
          onClick={() => setDecisionAndOpenDialog(OrderDecision.Reject)}
        >
          <XMarkIcon className="h-5 w-5 stroke-2" />
          {t('ssp:subscription.order.decision.reject')}
        </Button>
      </div>
      <ConfirmationDialog
        open={confirmationDialogOpened}
        onClose={() => cancelConfirmation()}
        onConfirm={async () => await onSubmit(decision)}
        title={t('ssp:subscription.order.decision.confirmationModal.title')}
        confirmButtonText={t(
          decision === OrderDecision.Approve
            ? 'ssp:subscription.order.decision.approve'
            : 'ssp:subscription.order.decision.reject'
        )}
        cancelButtonText={t('ssp:actions.cancel')}
      >
        <>
          <Trans i18nKey={dialogBodyKey} />
          {decision === OrderDecision.Reject && (
            <Textarea
              value={decisionNote ?? ''}
              onChange={e => setDecisionNote(e.target.value)}
              placeholder={t(
                'ssp:subscription.order.decision.confirmationModal.decisionNotePlaceholder'
              )}
              className="mt-4"
            />
          )}
        </>
      </ConfirmationDialog>
    </HasAccess>
  )
}

export default OrderApproval
