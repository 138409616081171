import { getInitials } from '@sherweb/core/utils/initials'

import { PersonAvatar } from '@ssp/components/Avatars'

export type UserTableNameRowProps = {
  firstName?: string | null
  lastName?: string | null
  email: string
}

export const UserTableNameRow = ({ firstName, lastName, email }: UserTableNameRowProps) => {
  const userMainInfo = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return email
  }

  return (
    <div className="flex flex-row items-center gap-4">
      <PersonAvatar fullName={getInitials(userMainInfo())} />
      <div className="flex flex-col">
        <div className="font-semibold">{userMainInfo()}</div>
        {firstName ? (
          <div className="text-xs text-gray-500 dark:text-slate-300">{email}</div>
        ) : null}
      </div>
    </div>
  )
}
