import { Menu as HLMenu, MenuItemProps as HLMenuItemProps } from '@headlessui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

type MenuItemProps = {
  children?: React.ReactNode
  autoClose?: boolean
} & HLMenuItemProps<any>

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  className,
  autoClose = true,
  onClick: customOnClick,
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!autoClose) {
      e.preventDefault()
    }
    if (customOnClick) {
      customOnClick(e)
    }
  }

  return (
    <HLMenu.Item {...props} onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}>
      {({ active }) => (
        <div
          className={twMerge(
            active && 'bg-gray-100',
            'block cursor-pointer px-4 py-2 text-sm text-gray-900 whitespace-nowrap hover:bg-slate-200 dark:text-white dark:hover:text-slate-900 focus-visible:outline-indigo-300 dark:focus-visible:text-primary',
            className
          )}
        >
          {children}
        </div>
      )}
    </HLMenu.Item>
  )
}

export default MenuItem
