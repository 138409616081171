import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission, UserStatus } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu/DropdownMenu'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess, usePermissionCheck } from '@ssp/modules/authorization'
import { User, useResendInvitationMutation } from '@ssp/modules/user'

export type UserTableActionRowProps = {
  user: User
  setSelectedUser: (user: User | null) => void
  setDeleting: (deleting: boolean) => void
}

export const UserTableActionRow = ({
  user,
  setDeleting,
  setSelectedUser,
}: UserTableActionRowProps) => {
  const { t } = useTranslation()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { hasAccess } = usePermissionCheck()

  const isOptionsVisible = hasAccess(Permission.UsersDelete) || hasAccess(Permission.UsersEdit)

  const resendInvitationMutation = useResendInvitationMutation()

  const handleResendInvitation = () => {
    resendInvitationMutation.mutate(user)
  }

  if (!isOptionsVisible) {
    return null
  }

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger asChild data-testid="dropdownViewUserOptions">
        <Button variant="ghost" className="h-8 w-8 p-0">
          <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {user.status === UserStatus.InvitationSent ? (
          <HasAccess permission={Permission.UsersCreate}>
            <DropdownMenuItem
              disabled={resendInvitationMutation?.isLoading}
              data-testid="dropdownItemResendInvitation"
              onClick={handleResendInvitation}
            >
              {t('ssp:pages.users.list.resendInvitation')}
            </DropdownMenuItem>
          </HasAccess>
        ) : null}
        <HasAccess permission={Permission.UsersEdit}>
          <Link to={Routes.UserEdit} params={{ userId: user.id }}>
            <DropdownMenuItem data-testid="dropdownItemEditUser">
              {t('ssp:pages.users.list.edit')}
            </DropdownMenuItem>
          </Link>
        </HasAccess>
        <HasAccess permission={Permission.UsersDelete}>
          <DropdownMenuItem
            data-testid="dropdownItemDeleteUser"
            onClick={() => {
              setSelectedUser(user)
              setDeleting(true)
            }}
          >
            {t('ssp:pages.users.list.delete')}
          </DropdownMenuItem>
        </HasAccess>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
