import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

interface SheetPageContentProps extends ComponentChildrenType {
  className?: string
}

export const SheetPageContent = ({ children, className }: SheetPageContentProps) => (
  <div className={mergeClassName('flex flex-1 flex-col gap-y-1', className)}>{children}</div>
)
