import clsx from 'clsx'
import { forwardRef } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { useLocation, usePathGenerator } from '@rsp/modules/navigation'

type ReactLink = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export type LinkProps = {
  to: string
  isAbsolute?: boolean
  currentClassName?: string
  params?: Record<string, string | undefined>
} & Omit<ReactLink, 'ref'>

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, children, currentClassName, isAbsolute = false, params, ...linkProps }, ref) => {
    const location = useLocation()

    const generatePath = usePathGenerator()

    const current = location.pathname === to

    if (isAbsolute) {
      return (
        <a href={to} {...linkProps} ref={ref}>
          {children}
        </a>
      )
    }

    return (
      <ReactRouterLink
        ref={ref}
        to={generatePath(to, params)}
        className={clsx(linkProps.className, current && currentClassName)}
        {...linkProps}
      >
        {children}
      </ReactRouterLink>
    )
  }
)

export default Link
