import { Theme } from '@sherweb/core/openapi-generated/index.defs'

export enum ACTION_TYPES {
  SET_THEME = 'SET_THEME',
  SET_DARK_MODE = 'THEME_SET_DARK_MODE',
}

export const setTheme = (theme: Theme) => ({
  type: ACTION_TYPES.SET_THEME as const,
  theme,
})

export const setDarkMode = (darkMode?: boolean) => ({
  type: ACTION_TYPES.SET_DARK_MODE as const,
  darkMode,
})

export type Actions = ReturnType<typeof setTheme> | ReturnType<typeof setDarkMode>
