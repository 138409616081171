import React, { SetStateAction, useCallback, useEffect, useState } from 'react'

import {
  DEFAULT_SERVER_DEBOUNCE_MS,
  DEFAULT_TABLE_PAGE,
  DEFAULT_TABLE_ROWS_PER_PAGE,
  DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS,
} from '@sherweb/core/utils/const'

import { DataTestId } from '../../types/dataTestIdType'
import { Table } from '../Table'
import { DataTableBody, DataTableBodyContentEmpty } from './DataTableBody'
import { DataTableContainer } from './DataTableContainer'
import { DataTableFilterHeader } from './DataTableFilterHeader'
import { DataTableHeader } from './DataTableHeader'
import { useDataTable } from './hooks/useDataTable'
import { useSetTableSearchParams } from './hooks/useSetTableSearchParam'
import { ServerPagination } from './ServerPagination'
import { DataTableProps, PaginatedResults, Pagination, PaginationSortParam } from './types'

export interface DataTableServerPaginationProps<TColumns, TData>
  extends Omit<DataTableProps<TColumns, TData>, 'data'> {
  data?: PaginatedResults<TData>
  isFetching?: boolean
  setPageParams: ({ page, pageSize, searchTerm }: Pagination) => void
  onSortBy?: (params: PaginationSortParam) => void
  currentPage?: number
  setCurrentPage?: React.Dispatch<SetStateAction<number>>
  currentPageSize?: number
  setCurrentPageSize?: React.Dispatch<SetStateAction<number>>
  onSetPaginationParams?: (pageValue: number, pageSizeValue: number) => void
}

const DataTableServerPagination = <TColumns, TData>({
  filterPlaceholder,
  fieldDescription,
  columns,
  data,
  setPageParams,
  isFetching = false,
  rowsPerPageOptions = DEFAULT_TABLE_ROWS_PER_PAGE_OPTIONS,
  emptyMessage,
  className,
  onSortBy,
  dataTestId,
  optionalActions,
  currentPage,
  setCurrentPage,
  currentPageSize,
  setCurrentPageSize,
  onSetPaginationParams,
}: DataTableServerPaginationProps<TColumns, TData> & DataTestId) => {
  const { search: searchTerm, hasValueChanged } = useSetTableSearchParams()

  const [internalCurrentPage, setInternalCurrentPage] = useState(currentPage ?? DEFAULT_TABLE_PAGE)

  const effectiveCurrentPage = currentPage ?? internalCurrentPage
  const setEffectiveCurrentPage = setCurrentPage ?? setInternalCurrentPage

  const [internalCurrentPageSize, setInternalCurrentPageSize] = useState(
    currentPageSize ?? DEFAULT_TABLE_ROWS_PER_PAGE
  )

  const effectiveCurrentPageSize = currentPageSize ?? internalCurrentPageSize
  const setEffectiveCurrentPageSize = setCurrentPageSize ?? setInternalCurrentPageSize

  const tableData = data?.results

  const { table, sorting, hasSortingChanged, onSortByServer } = useDataTable<TColumns, TData>({
    data: tableData,
    rowsPerPage: effectiveCurrentPageSize,
    columns,
    manualFiltering: true,
    manualSorting: true,
    onSortBy,
  })

  const handleSetPageParamsAndCurrentPage = useCallback(
    ({
      pageSize,
      page,
      searchTerm: searchValue,
    }: {
      pageSize?: number
      page?: number
      searchTerm?: string
    } = {}) => {
      const updatedPage = page ?? effectiveCurrentPage
      const updatedPageSize = pageSize ?? effectiveCurrentPageSize

      setEffectiveCurrentPage(updatedPage)

      setPageParams({
        searchTerm: searchValue ?? searchTerm,
        pageSize: updatedPageSize,
        page: updatedPage,
      })

      if (onSetPaginationParams) onSetPaginationParams(updatedPage, updatedPageSize)
    },
    [
      effectiveCurrentPage,
      effectiveCurrentPageSize,
      setEffectiveCurrentPage,
      setPageParams,
      searchTerm,
      onSetPaginationParams,
    ]
  )

  useEffect(() => {
    if (hasValueChanged) {
      handleSetPageParamsAndCurrentPage()
    }
  }, [
    effectiveCurrentPage,
    effectiveCurrentPageSize,
    handleSetPageParamsAndCurrentPage,
    hasValueChanged,
    searchTerm,
  ])

  useEffect(() => {
    if (hasSortingChanged && sorting.length > 0) {
      onSortByServer(sorting)
    }
  }, [hasSortingChanged, onSortByServer, sorting])

  const handleSetPageParams = useCallback(
    ({ pageSize, page }: Pagination) => {
      const currentPage = pageSize !== effectiveCurrentPageSize ? DEFAULT_TABLE_PAGE : page

      setEffectiveCurrentPageSize(pageSize)

      handleSetPageParamsAndCurrentPage({
        page: currentPage,
        pageSize,
      })
    },
    [effectiveCurrentPageSize, handleSetPageParamsAndCurrentPage, setEffectiveCurrentPageSize]
  )

  return (
    <>
      <DataTableFilterHeader
        debounce={DEFAULT_SERVER_DEBOUNCE_MS}
        filterPlaceholder={filterPlaceholder}
        fieldDescription={fieldDescription}
      >
        {optionalActions}
      </DataTableFilterHeader>
      <DataTableContainer className={className}>
        <Table data-testid={dataTestId}>
          <DataTableHeader<TData> table={table} />
          <DataTableBody>
            {table.getRowModel().rows?.length ? (
              <DataTableBody.Content<TData> table={table} dataTestId={dataTestId} />
            ) : (
              <DataTableBodyContentEmpty
                colSpanLength={columns.length}
                emptyMessage={emptyMessage}
                dataTestId={dataTestId}
              />
            )}
          </DataTableBody>
        </Table>

        <ServerPagination<TData>
          result={data}
          isFetching={isFetching}
          currentPage={effectiveCurrentPage}
          currentPageSize={effectiveCurrentPageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          setPageParams={handleSetPageParams}
        />
      </DataTableContainer>
    </>
  )
}

export default DataTableServerPagination
