import { useTranslation } from 'react-i18next'
import * as z from 'zod'

export type OrderSettingsFormType = z.infer<ReturnType<typeof useOrderSettingsSchema>>

export const useOrderSettingsSchema = () => {
  const { t } = useTranslation()

  return z.object({
    isOrderApprovalRequired: z.boolean(),
    teamNotifications: z
      .object({
        enabled: z.boolean().default(false),
        webHookUrl: z.string().optional(),
      })
      .refine(({ enabled, webHookUrl }) => !enabled || webHookUrl !== '', {
        path: ['webHookUrl'],
        message: t('rsp:validations.mixed.required'),
      }),
    emailNotifications: z
      .object({
        enabled: z.boolean().default(false),
        allUsersEnabled: z.boolean().default(true),
        emails: z.string().array().optional(),
      })
      .refine(
        ({ enabled, allUsersEnabled, emails }) =>
          allUsersEnabled || !enabled ? true : emails?.length !== 0,
        {
          path: ['emails'],
          message: 'rsp:validations.mixed.required',
        }
      ),
  })
}
