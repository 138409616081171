import { Square3Stack3DIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Facet } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { useQueryParams } from '@sherweb/core/hooks'
import { ICategory } from '@sherweb/core/modules/marketplace/core/marketplace.model'
import {
  MarketPlaceFilter,
  MarketPlaceFilterTriggerContent,
} from '@sherweb/core/modules/marketplace/ui/Filter'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type MarketplaceProductsCategoryFilterProps = {
  facets?: Facet[]
}

export const MarketplaceProductsCategoryFilter = ({
  facets,
}: MarketplaceProductsCategoryFilterProps) => {
  const { t } = useTranslation()

  const categoryOptions = facets
    ?.find(({ fieldName }) => fieldName === 'CatalogCategory')
    ?.countsByValue?.map(option => ({
      ...option,
      label: t(`core:marketplace.category.${option.key as ICategory}`),
    }))

  const { getArrayParam } = useQueryParams<'productsCategories'>()

  const queryCategoriesSelectedValues = getArrayParam<string>('productsCategories')

  return (
    <MarketPlaceFilter
      dataTestId="categories"
      options={categoryOptions}
      queryParamType="productsCategories"
      trigger={
        <Button
          size="small"
          variant="None"
          className={mergeClassName(
            'flex items-center px-4 py-2 text-slate-900 hover:bg-white/50 dark:text-slate-100',
            {
              'bg-white/50': queryCategoriesSelectedValues.length !== 0,
            }
          )}
        >
          <MarketPlaceFilterTriggerContent
            text={t('rsp:pages.marketplace.addProducts.filters.categories')}
            icon={<Square3Stack3DIcon className="mr-1 h-4 w-4" />}
            value={queryCategoriesSelectedValues}
          />
        </Button>
      }
    />
  )
}
