import { AxiosError } from 'axios'

export const errorInstance = (message: string) => new Error(message)

export const missingParametersError = () => errorInstance('Missing parameters')

export const rejectMissingParameters = async () => await Promise.reject(missingParametersError())

export const getErrorCodeFromAxios = (error?: unknown): string | undefined => {
  if (!error) return undefined

  return (error as AxiosError<any>).response?.data.Errors?.at(0)?.Code
}

export const isCORSError = (error?: unknown) => {
  const errorInstance = error as AxiosError

  return errorInstance?.message?.includes('Network Error') && errorInstance.code === 'ERR_NETWORK'
}
