import { useTranslation } from 'react-i18next'

import { IntegrationType } from '@sherweb/core/openapi-generated/index.defs'

import Routes from '@rsp/app/Routes'

export const useGetIntegrationTypeTitle = (integrationType: IntegrationType): string => {
  const { t } = useTranslation()

  switch (integrationType) {
    case IntegrationType.ConnectWise: {
      return t('rsp:pages.integrations.cards.connectWise.title')
    }
    case IntegrationType.Sherweb: {
      return t('rsp:pages.integrations.cards.sherweb.title')
    }
    default: {
      return 'Undefined'
    }
  }
}

export const useGetIntegrationTypeDescription = (integrationType: IntegrationType): string => {
  const { t } = useTranslation()

  switch (integrationType) {
    case IntegrationType.ConnectWise: {
      return t('rsp:pages.integrations.cards.connectWise.description')
    }
    case IntegrationType.Sherweb: {
      return t('rsp:pages.integrations.cards.sherweb.description')
    }
    default: {
      return 'Undefined'
    }
  }
}

export const useGetIntegrationTypeRoute = (integrationType: IntegrationType): string => {
  switch (integrationType) {
    case IntegrationType.ConnectWise: {
      return Routes.IntegrationsConnectWise
    }
    case IntegrationType.Sherweb: {
      return Routes.SherwebIntegrationsServiceProvider
    }
    default: {
      return '/'
    }
  }
}
