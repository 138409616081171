import {
  ResellerCacheRefreshDto,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ResellerCacheService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static refreshResellerCache(
    params: {
      /**  */
      resellerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResellerCacheRefreshDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/cache/refresh';
      url = url.replace('{resellerId}', params['resellerId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
