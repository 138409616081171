import CoreHeader from '@sherweb/core/components/Header'
import { withLazyLoad } from '@sherweb/core/hoc'
import Notifications, { NotificationsScheme } from '@sherweb/core/modules/notifications'

import UserMenu from '@rsp/components/layout/UserMenu/UserMenu'

import { useThemeDarkModeState } from '@ssp/modules/theme'

type HeaderProps = {
  onHamburgerButtonClick: () => void
}

const Header = ({ onHamburgerButtonClick }: HeaderProps) => {
  const isDarkMode = useThemeDarkModeState()

  return (
    <CoreHeader
      className="lg:bg-transparent lg:shadow-transparent"
      onHamburgerButtonClick={onHamburgerButtonClick}
      rightSection={
        <div className="flex flex-row gap-4">
          {withLazyLoad(
            <Notifications
              className="self-center"
              mode={isDarkMode ? NotificationsScheme.Dark : NotificationsScheme.Light}
            />,
            { showLoader: false }
          )}
          <UserMenu />
        </div>
      }
    />
  )
}

export default Header
