import { Theme } from '@sherweb/core/openapi-generated/index.defs'

import * as themes from '../themes'

type ThemeVariablesProps = {
  theme?: Theme
}

const themeMapper = {
  [Theme.Green]: themes.greenTheme,
  [Theme.Blue]: themes.blueTheme,
  [Theme.Default]: themes.defaultTheme,
}

const ThemeVariables: React.FC<ThemeVariablesProps> = ({ theme = Theme.Default }) => {
  const content = String(themeMapper[theme])

  return <style data-testid={`theme-${theme.toLowerCase()}`}> {`:root{ ${content} }`} </style>
}

export default ThemeVariables
