import {
  HelpDeskTicketStatusMapping,
  TicketStatusMapping,
  IntegrationStatus,
  HelpDeskServiceBoard,
  HelpDeskTicketType,
  SetHelpDeskBoardConfigurationCommand,
  SetHelpDeskMappingTicketStatus,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class HelpDeskBoardConfigurationService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getHelpDeskTicketStatusMappings(
    params: {
      /**  */
      resellerId: string;
      /**  */
      integrationId: string;
      /**  */
      serviceBoardId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketStatusMapping> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/helpdesk/{integrationId}/ticket-status-mappings';
      url = url.replace('{resellerId}', params['resellerId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { serviceBoardId: params['serviceBoardId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getHelpDeskServiceBoards(
    params: {
      /**  */
      resellerId: string;
      /**  */
      integrationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskServiceBoard[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/helpdesk/{integrationId}/service-boards';
      url = url.replace('{resellerId}', params['resellerId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getHelpDeskTicketTypes(
    params: {
      /**  */
      resellerId: string;
      /**  */
      integrationId: string;
      /**  */
      serviceBoardId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/helpdesk/{integrationId}/ticket/types';
      url = url.replace('{resellerId}', params['resellerId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { serviceBoardId: params['serviceBoardId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setHelpDeskBoardConfiguration(
    params: {
      /**  */
      resellerId: string;
      /**  */
      integrationId: string;
      /**  */
      configurationCommand: SetHelpDeskBoardConfigurationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/helpdesk/{integrationId}/configuration';
      url = url.replace('{resellerId}', params['resellerId'] + '');
      url = url.replace('{integrationId}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['configurationCommand'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
