import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button/Button'
import ImageUploaderDialog from '@sherweb/core/components/ImageUploaderDialog'

export type ImageEditorProps = {
  imageUrl?: string
  onSave: (file: File) => Promise<void>
  title: string
}

const ImageEditor = ({ imageUrl, onSave, title }: ImageEditorProps) => {
  const { t } = useTranslation()

  const [editing, setEditing] = useState(false)

  return (
    <>
      <ImageUploaderDialog
        open={editing}
        onClose={() => setEditing(false)}
        title={title}
        onSave={onSave}
        imageWidth={300}
        imageHeight={300}
        trimVertically
      />
      <div
        className="relative flex min-h-[96px] items-center justify-center rounded-lg border border-dotted p-4 dark:border-slate-800 dark:bg-slate-900/75 md:min-h-[128px]"
        data-testid="update-image-container"
      >
        {imageUrl && <img src={imageUrl} alt="" className="w-56 items-center dark:bg-slate-100" />}
        <div
          className={`absolute left-0 top-0 flex h-full w-full items-center justify-center${imageUrl ? ' bg-white/60 opacity-0 backdrop-blur-sm transition-all duration-300 hover:opacity-100' : ''}`}
        >
          <Button
            variant="outline"
            type="button"
            size="sm"
            onClick={() => setEditing(true)}
            data-testid="btnUploadImage"
          >
            {imageUrl && t('rsp:actions.edit')}
            {!imageUrl && t('rsp:actions.upload')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default ImageEditor
