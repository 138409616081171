/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class PaginatedListOfListOfOrdersQueryResult {
  /**  */
  'pageIndex': number;

  /**  */
  'totalPages': number;

  /**  */
  'totalItemsCount': number;

  /**  */
  'results'?: OrdersQueryResult[];

  /**  */
  'hasPreviousPage': boolean;

  /**  */
  'hasNextPage': boolean;

  constructor(data: undefined | any = {}) {
    this['pageIndex'] = data['pageIndex'];
    this['totalPages'] = data['totalPages'];
    this['totalItemsCount'] = data['totalItemsCount'];
    this['results'] = data['results'];
    this['hasPreviousPage'] = data['hasPreviousPage'];
    this['hasNextPage'] = data['hasNextPage'];
  }
}

export class OrdersQueryResult {
  /**  */
  'orderId': string;

  /**  */
  'orderType': OrderType;

  /**  */
  'createdAt': Date;

  /**  */
  'organizationName'?: string;

  /**  */
  'userEmail'?: string;

  /**  */
  'userFirstName'?: string;

  /**  */
  'userLastName'?: string;

  /**  */
  'orderStatus': OrderStatus;

  /**  */
  'organizationUniqueName'?: string;

  /**  */
  'organizationId': string;

  /**  */
  'decisionNote'?: string;

  /**  */
  'humanReadableId'?: string;

  /**  */
  'errorResult'?: OrderErrorResult;

  /**  */
  'value'?: Money;

  /**  */
  'items'?: OrdersLineQueryResult[];

  constructor(data: undefined | any = {}) {
    this['orderId'] = data['orderId'];
    this['orderType'] = data['orderType'];
    this['createdAt'] = data['createdAt'];
    this['organizationName'] = data['organizationName'];
    this['userEmail'] = data['userEmail'];
    this['userFirstName'] = data['userFirstName'];
    this['userLastName'] = data['userLastName'];
    this['orderStatus'] = data['orderStatus'];
    this['organizationUniqueName'] = data['organizationUniqueName'];
    this['organizationId'] = data['organizationId'];
    this['decisionNote'] = data['decisionNote'];
    this['humanReadableId'] = data['humanReadableId'];
    this['errorResult'] = data['errorResult'];
    this['value'] = data['value'];
    this['items'] = data['items'];
  }
}

export class OrderErrorResult {
  /**  */
  'detail'?: string;

  /**  */
  'title'?: string;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['detail'] = data['detail'];
    this['title'] = data['title'];
    this['message'] = data['message'];
  }
}

export class Money {
  /**  */
  'amount': number;

  /**  */
  'currency': Currency;

  constructor(data: undefined | any = {}) {
    this['amount'] = data['amount'];
    this['currency'] = data['currency'];
  }
}

export class OrdersLineQueryResult {
  /**  */
  'quantity': number;

  /**  */
  'productName'?: string;

  /**  */
  'subscriptionId': string;

  /**  */
  'price'?: Money;

  /**  */
  'subscriptionStatus': Status;

  constructor(data: undefined | any = {}) {
    this['quantity'] = data['quantity'];
    this['productName'] = data['productName'];
    this['subscriptionId'] = data['subscriptionId'];
    this['price'] = data['price'];
    this['subscriptionStatus'] = data['subscriptionStatus'];
  }
}

export class ProblemDetails {
  constructor(data: undefined | any = {}) {}
}

export class ErrorDetails {
  /**  */
  'code'?: string;

  /**  */
  'message'?: string;

  /**  */
  'property'?: string;

  /**  */
  'type': ErrorType;

  constructor(data: undefined | any = {}) {
    this['code'] = data['code'];
    this['message'] = data['message'];
    this['property'] = data['property'];
    this['type'] = data['type'];
  }
}

export class ProblemDetails2 {
  /**  */
  'type'?: string;

  /**  */
  'title'?: string;

  /**  */
  'status'?: number;

  /**  */
  'detail'?: string;

  /**  */
  'instance'?: string;

  constructor(data: undefined | any = {}) {
    this['type'] = data['type'];
    this['title'] = data['title'];
    this['status'] = data['status'];
    this['detail'] = data['detail'];
    this['instance'] = data['instance'];
  }
}

export class OrdersQuery {
  /**  */
  'page': number;

  /**  */
  'pageSize': number;

  /**  */
  'searchTerm'?: string;

  /**  */
  'sort'?: OrderSort;

  constructor(data: undefined | any = {}) {
    this['page'] = data['page'];
    this['pageSize'] = data['pageSize'];
    this['searchTerm'] = data['searchTerm'];
    this['sort'] = data['sort'];
  }
}

export class OrderSort {
  /**  */
  'field': OrderSortField;

  /**  */
  'ascending': boolean;

  constructor(data: undefined | any = {}) {
    this['field'] = data['field'];
    this['ascending'] = data['ascending'];
  }
}

export enum OrderType {
  'Amendment' = 'Amendment',
  'Purchase' = 'Purchase'
}

export enum OrderStatus {
  'Completed' = 'Completed',
  'Pending' = 'Pending',
  'Failed' = 'Failed',
  'WaitingForApproval' = 'WaitingForApproval',
  'Rejected' = 'Rejected'
}

export enum Currency {
  'Cad' = 'Cad',
  'Usd' = 'Usd',
  'Eur' = 'Eur',
  'Gbp' = 'Gbp'
}

export enum Status {
  'New' = 'New',
  'Processing' = 'Processing',
  'Active' = 'Active',
  'Suspended' = 'Suspended',
  'Inactive' = 'Inactive'
}

export enum ErrorType {
  'Validation' = 'Validation',
  'Business' = 'Business',
  'System' = 'System'
}

export enum OrderSortField {
  'Name' = 'Name',
  'CreatedOn' = 'CreatedOn',
  'Quantity' = 'Quantity'
}

export enum Permission {
  'SubscriptionList' = 'SubscriptionList',
  'SubscriptionPrices' = 'SubscriptionPrices',
  'SubscriptionDetails' = 'SubscriptionDetails',
  'SubscriptionEdit' = 'SubscriptionEdit',
  'SubscriptionCancel' = 'SubscriptionCancel',
  'OrderApproval' = 'OrderApproval',
  'UsersList' = 'UsersList',
  'UsersEdit' = 'UsersEdit',
  'UsersCreate' = 'UsersCreate',
  'UsersDelete' = 'UsersDelete',
  'UserActivity' = 'UserActivity',
  'UserSubscriptions' = 'UserSubscriptions',
  'UserTickets' = 'UserTickets',
  'DashboardAccess' = 'DashboardAccess',
  'DashboardEdit' = 'DashboardEdit',
  'DashboardAddWidget' = 'DashboardAddWidget',
  'DashboardDeleteWidget' = 'DashboardDeleteWidget',
  'ActivitiesUser' = 'ActivitiesUser',
  'ActivitiesUsers' = 'ActivitiesUsers',
  'ResellerAdmin' = 'ResellerAdmin',
  'ResellerUsersList' = 'ResellerUsersList',
  'ResellerUsersDelete' = 'ResellerUsersDelete',
  'LicenseList' = 'LicenseList',
  'LicenseDetails' = 'LicenseDetails',
  'LicenseAssign' = 'LicenseAssign',
  'IntegrationList' = 'IntegrationList',
  'IntegrationDetails' = 'IntegrationDetails',
  'IntegrationConfigure' = 'IntegrationConfigure',
  'HelpDeskList' = 'HelpDeskList',
  'HelpDeskDetails' = 'HelpDeskDetails',
  'HelpDeskEdit' = 'HelpDeskEdit',
  'AdminOrderList' = 'AdminOrderList',
  'AdminOrderDetails' = 'AdminOrderDetails',
  'OrderList' = 'OrderList',
  'OrderDetails' = 'OrderDetails',
  'AccessToSuspendedOrganization' = 'AccessToSuspendedOrganization',
  'MarketplaceProductList' = 'MarketplaceProductList',
  'MarketplaceProductDetail' = 'MarketplaceProductDetail',
  'MarketplaceProductEdit' = 'MarketplaceProductEdit',
  'CatalogManagementList' = 'CatalogManagementList',
  'CatalogProductsList' = 'CatalogProductsList',
  'CatalogProductsEdit' = 'CatalogProductsEdit',
  'CatalogOrganizationsList' = 'CatalogOrganizationsList',
  'CatalogOrganizationsEdit' = 'CatalogOrganizationsEdit',
  'InvoicesList' = 'InvoicesList',
  'InvoiceDetails' = 'InvoiceDetails',
  'PaymentCreateSetupToken' = 'PaymentCreateSetupToken',
  'OrganizationPaymentMethodList' = 'OrganizationPaymentMethodList'
}

export enum SspHubKeys {
  'RefreshOrganizationSubscriptions' = 'RefreshOrganizationSubscriptions',
  'LogOutUser' = 'LogOutUser',
  'RefreshLicenses' = 'RefreshLicenses',
  'RefreshOrganizations' = 'RefreshOrganizations',
  'RefreshPermissions' = 'RefreshPermissions',
  'Notify' = 'Notify',
  'RefreshOrders' = 'RefreshOrders',
  'RefreshAdminOrders' = 'RefreshAdminOrders',
  'AssignToOrganizationGroup' = 'AssignToOrganizationGroup',
  'AssignToResellerGroup' = 'AssignToResellerGroup'
}

export enum NotificationLevel {
  'Info' = 'Info',
  'Success' = 'Success',
  'Warning' = 'Warning',
  'Error' = 'Error'
}
