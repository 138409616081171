type FullWidthPageProps = {
  children: React.ReactNode
  className?: string
}

const FullWidthPage: React.FC<FullWidthPageProps> = ({ children, className = '' }) => {
  return <main className={className}>{children}</main>
}

export default FullWidthPage
