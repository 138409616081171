import { useCallback, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { HelpDeskApiInformation } from '@sherweb/core/openapi-generated/index.defs'

import { EMPTY_GUID } from '@sherweb/core/utils/const'

import { DEFAULT_VALUES } from '../apiInformation.helper'
import { ApiInformationFormType } from './useApiInformationSchema'

type UseApiInformationProps = {
  form: UseFormReturn<ApiInformationFormType>
}

export const useApiInformation = ({ form }: UseApiInformationProps) => {
  const [isEditMode, setIsEditMode] = useState(false)

  const setFormValues = useCallback(
    (apiInformation?: HelpDeskApiInformation) => {
      if (apiInformation) {
        form.reset({
          ...apiInformation,
          clientId: apiInformation.clientId === EMPTY_GUID ? '' : apiInformation.clientId,
        })
      }
    },
    [form]
  )

  const onEditMode = useCallback(
    (apiInformation?: HelpDeskApiInformation) => {
      form.reset({ ...DEFAULT_VALUES, enabled: apiInformation?.enabled })
      form.clearErrors()
      setIsEditMode(prevIsMode => !prevIsMode)
    },
    [form]
  )

  return { isEditMode, onEditMode, setFormValues, setIsEditMode }
}
