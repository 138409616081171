import { ComponentChildrenType } from '@sherweb/core/types/reactChildrenType'

import { Typography } from '../Typography'

interface SheetPageContentLabelProps extends ComponentChildrenType {}

export const SheetPageContentLabel = ({ children }: SheetPageContentLabelProps) => (
  <Typography variant="body2" colors="light" as="div">
    {children}
  </Typography>
)
