import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export type I18NOptions = {
  locales: Resource
  defaultLanguage?: string
  defaultNs: string
  fallbackNs?: string
}

export const createI18nInstance = ({
  locales,
  defaultLanguage,
  defaultNs,
  fallbackNs,
}: I18NOptions) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      supportedLngs: Object.keys(locales),
      nonExplicitSupportedLngs: true,
      resources: locales,
      fallbackLng: defaultLanguage,
      defaultNS: defaultNs,
      fallbackNS: fallbackNs,
      interpolation: {
        escapeValue: false,
      },
    })

  return i18n
}
