import { QueryKey } from '@tanstack/react-query'

import {
  UnusedLicensesStatistics,
  UsedLicenseStatistics,
} from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationLicensesService } from '@sherweb/core/openapi-generated/OrganizationLicensesService'

import { getLocalizedQueryKey } from '@sherweb/core/modules/reactQuery'

export const licensesByOrganizationId = {
  queryKey: (orgId?: string): QueryKey => getLocalizedQueryKey(['licensesByOrganizationId', orgId]),
  queryFn: async (orgId?: string) =>
    orgId
      ? await OrganizationLicensesService.getLicensesByOrganizationId({ organizationId: orgId })
      : [],
  staleTime: 5 * 1000,
}

export const subscribersByOrganizationId = {
  queryKey: (orgId?: string): QueryKey =>
    getLocalizedQueryKey(['subscribersByOrganizationId', orgId]),
  queryFn: async (orgId?: string) =>
    orgId
      ? await OrganizationLicensesService.getSubscribersByOrganizationId({ organizationId: orgId })
      : [],
  staleTime: 5 * 1000,
}

export const erroneousLicenseUpdatesByOrganizationId = {
  queryKey: (orgId?: string): QueryKey =>
    getLocalizedQueryKey(['erroneousLicenseUpdatesByOrganizationId', orgId]),
  queryFn: async (orgId?: string) =>
    orgId
      ? await OrganizationLicensesService.getErroneousUpdatesByOrganizationId({
          organizationId: orgId,
        })
      : [],
}

export const usedLicenseStatsByOrganizationId = {
  queryKey: (orgId?: string): QueryKey =>
    getLocalizedQueryKey(['usedLicenseStatsByOrganizationId', orgId]),
  queryFn: async (orgId?: string): Promise<UsedLicenseStatistics> =>
    orgId
      ? await OrganizationLicensesService.getUsedLicenseStatsByOrganizationIdAsync({
          organizationId: orgId,
        })
      : await Promise.resolve<UsedLicenseStatistics>({
          isRecalculating: true,
          currentUserLicensesCount: 0,
          currentUserLicenses: [],
        }),
  staleTime: 60 * 1000,
}

export const unusedLicenseStatsByOrganizationId = {
  queryKey: (orgId?: string): QueryKey =>
    getLocalizedQueryKey(['unusedLicenseStatsByOrganizationId', orgId]),
  queryFn: async (orgId?: string): Promise<UnusedLicensesStatistics> =>
    orgId
      ? await OrganizationLicensesService.getUnusedLicenseStatsByOrganizationIdAsync({
          organizationId: orgId,
        })
      : await Promise.resolve<UnusedLicensesStatistics>({
          isRecalculating: true,
          unusedLicensesCount: 0,
          unusedLicenses: [],
        }),
  staleTime: 60 * 1000,
}
