import { XCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission, ResellerUserQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Button from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import { DataTableMobileHeader } from '@sherweb/core/components/DataTable/DataTableInputMobile'
import { useSetTableSearchParams } from '@sherweb/core/components/DataTable/hooks/useSetTableSearchParam'
import { NoResultsMobile } from '@sherweb/core/components/NoResults/NoResultsMobile'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { isRequestLoading, isRequestRefetching } from '@sherweb/core/modules/reactQuery'
import { UserDeleteConfirmationDialog } from '@sherweb/core/modules/user/ui/UserDeleteConfirmationDialog'
import { UserRoles } from '@sherweb/core/modules/user/ui/UserRoles'
import { UserStatus } from '@sherweb/core/modules/user/ui/UserStatus'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { HasAccess, usePermissionCheck } from '@rsp/modules/authorization'
import {
  useDeleteResellerUserMutation,
  useGetResellerAdminsAndGuestsQuery,
} from '@rsp/modules/users'

export const UsersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const resellerAdminsAndGuestsQuery = useGetResellerAdminsAndGuestsQuery()

  const { search: searchText, onSetSearchParams } = useSetTableSearchParams()

  const formatDate = useDateFormatter(language)

  const formatDateTime = useDateFormatter(language, dateFormatTypes.datetime)

  const [selectedUser, setSelectedUser] = useState<ResellerUserQueryResult | null>(null)

  const filteredUsers = resellerAdminsAndGuestsQuery?.data?.filter(user => {
    const fullName = `${String(user.firstName)} ${String(user.lastName)}`.toLowerCase()

    return (
      fullName?.includes(searchText.toLowerCase()) ||
      (user.email?.toLowerCase() ?? '').includes(searchText.toLowerCase()) ||
      user.jobTitle?.toLowerCase().includes(searchText.toLowerCase())
    )
  })

  const deleteUserMutation = useDeleteResellerUserMutation()

  const isUserUpdating =
    isRequestRefetching(resellerAdminsAndGuestsQuery) || deleteUserMutation.isLoading

  const { permissionsLoading } = usePermissionCheck()

  const renderContent = () =>
    filteredUsers?.length === 0 ? (
      <NoResultsMobile>{t('rsp:pages.users.noResult')}</NoResultsMobile>
    ) : (
      filteredUsers?.map(user => {
        const fullName = `${String(user.firstName)} ${String(user.lastName)}`

        return (
          <Accordion key={user.id} type="single" collapsible>
            <AccordionItem value={user.id}>
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                <span>{fullName}</span>
                <AccordionTriggerSubContent>{user.email}</AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('rsp:pages.users.columns.user')}
                    value={user.email}
                    dataTestId="accEmailUser"
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.users.columns.role')}
                    value={<UserRoles roles={user.userRole} />}
                    dataTestId="accRolesUser"
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.users.columns.createdOn')}
                    value={formatDate(user.createdAt)}
                    dataTestId="accCreationUser"
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.users.columns.lastSeen')}
                    value={formatDateTime(user.lastSeen)}
                    dataTestId="accCreationUser"
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.users.columns.status')}
                    value={<UserStatus status={user.status} />}
                    dataTestId="accCreationUser"
                  />
                  <AccordionContentListItem
                    label=" "
                    value={
                      <div className="flex items-center gap-x-2">
                        <HasAccess permission={Permission.ResellerUsersDelete}>
                          <Button
                            className="ml-2 flex flex-row gap-1 text-indigo-500"
                            data-testid="btnDeleteUserMobile"
                            variant="none"
                            onClick={() => {
                              setSelectedUser(user)
                            }}
                          >
                            <XCircleIcon className="inline h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                            {t('core:actions.delete')}
                          </Button>
                        </HasAccess>
                      </div>
                    }
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })
    )

  return (
    <>
      <Card
        padded
        data-testid="mobileLayoutUsers"
        className={isUserUpdating ? spinnerStyles({ type: 'layOverBackground' }) : ''}
      >
        {isUserUpdating ? <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} /> : null}
        <div className="flex flex-col gap-4">
          <DataTableMobileHeader>
            <DataTableMobileHeader.Input
              placeholder={t('rsp:pages.users.search.placeholder')}
              filteredText={searchText}
              setFilteredText={onSetSearchParams}
            />
          </DataTableMobileHeader>
          {isRequestLoading(resellerAdminsAndGuestsQuery) || permissionsLoading ? (
            <SkeletonTable />
          ) : (
            renderContent()
          )}
        </div>
      </Card>
      {selectedUser ? (
        <UserDeleteConfirmationDialog
          open
          selectedUser={selectedUser}
          onClose={() => setSelectedUser(null)}
          onConfirm={() => {
            deleteUserMutation.mutate(selectedUser?.id)
          }}
        />
      ) : null}
    </>
  )
}
