import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import Switch from '@sherweb/core/components/Switch'

import resellerSettingsSchema from '../validationSchema/resellerSettingsSchema'

export const ResellerSettingsUserManagement = () => {
  const { t } = useTranslation()

  const form = useFormContext<z.infer<typeof resellerSettingsSchema>>()

  return (
    <div className="grid-col-1 grid gap-y-4 md:grid-cols-2 md:gap-y-0">
      <div className="w-full sm:w-9/12">
        <label className="font-semibold">
          {t('rsp:pages.settings.resellerSettings.userManagement.title')}
        </label>
        <div className="text-xs text-gray-500 dark:text-slate-300">
          {t('rsp:pages.settings.resellerSettings.userManagement.description')}
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-col gap-8">
          <div className="grid grid-cols-1 gap-y-4">
            <FormField
              control={form.control}
              name="orgAdminCanCreateUsers"
              render={({ field }) => (
                <FormItem className="settings-form-item">
                  <FormLabel>
                    {t('rsp:pages.settings.resellerSettings.userManagement.labels.createNewUsers')}
                  </FormLabel>
                  <FormControl className="settings-form-control">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      data-testid="orgAdminCanCreateUsers"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
