import Spinner from '@sherweb/core/components/Spinner'

import { useReseller } from '@rsp/modules/reseller'

import OrderSettingsForm from './OrderSettingsForm'

const OrderSettings = () => {
  const { isLoading, isFetching, data: reseller } = useReseller()

  if (isLoading || !reseller) {
    return <Spinner floating />
  }

  return <OrderSettingsForm isFetching={isFetching} reseller={reseller} />
}

export default OrderSettings
