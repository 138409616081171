import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  OrderStatus,
  OrderType,
  ProcessOrderDecision,
} from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'
import { Permission } from '@sherweb/core/modules/authorization'
import { usePathGenerator } from '@sherweb/core/modules/browserRouter'
import { Order } from '@sherweb/core/modules/orders/core/orders.model'

import Routes from '@rsp/app/Routes'
import Link from '@rsp/components/Link'
import { HasAccess } from '@rsp/modules/authorization'
import { useNavigate } from '@rsp/modules/navigation'
import { useProcessOrderMutation } from '@rsp/modules/orders'

import { SubscriptionStatus } from '@ssp/modules/subscription'

import { OrderAprovalConfirmationDialog } from '../../components/OrderAprovalConfirmationDialog'

export type OrdersTableRowActionsProps = {
  order: Order
}

export const OrdersTableRowActions = ({ order }: OrdersTableRowActionsProps) => {
  const { t } = useTranslation()

  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [decision, setDecision] = useState<ProcessOrderDecision | null>(null)

  const { navigate } = useNavigate()

  const pathGenerator = usePathGenerator()

  const decisionFlag = order?.orderStatus === OrderStatus.WaitingForApproval

  const viewSubscriptionFlag =
    order.orderType === OrderType.Amendment &&
    order.firstSubscriptionStatus !== SubscriptionStatus.Inactive

  const processOrderMutation = useProcessOrderMutation()

  const handleShowDetails = () => {
    navigate(Routes.Order, { orderId: order.id })
  }

  const setDecisionAndOpenDialog = (decision: ProcessOrderDecision) => {
    setDecision(decision)
    setConfirmationDialogOpened(true)
    setDropdownOpen(false)
  }

  const cancelConfirmation = () => {
    setDecision(null)
    setConfirmationDialogOpened(false)
    setDropdownOpen(false)
  }

  const onSubmit = (decision: ProcessOrderDecision, decisionNote?: string) => {
    processOrderMutation.mutate({
      orderIds: [order.id],
      organizationId: order.organizationId ?? '',
      decision,
      decisionNote,
    })
  }

  return (
    <>
      <div className="flex gap-x-4">
        <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild data-testid="dropdownViewOrderOptions">
            <Button variant="ghost" className="h-8 w-8 p-0">
              <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem data-testid="dropdownItemViewDetail" onClick={handleShowDetails}>
              {t('rsp:pages.orders.list.actions.viewDetails')}
            </DropdownMenuItem>
            {viewSubscriptionFlag && (
              <DropdownMenuItem asChild>
                <Link
                  isAbsolute
                  data-testid="dropdownItemViewSubscription"
                  to={pathGenerator(Routes.Subscription, {
                    subscriptionId: order?.firstSubscriptionId ?? '',
                    SubscriptionStatus: order?.firstSubscriptionStatus ?? '',
                    organizationUniqueName: order?.organizationUniqueName ?? '',
                  })}
                  target="_blank"
                >
                  {t('rsp:pages.orders.list.actions.viewSubscription')}
                </Link>
              </DropdownMenuItem>
            )}
            {decisionFlag && (
              <HasAccess permission={Permission.OrderApproval}>
                <DropdownMenuItem
                  data-testid="dropdownItemApprove"
                  onClick={() => setDecisionAndOpenDialog(ProcessOrderDecision.Approve)}
                >
                  {t('rsp:pages.orders.list.actions.approve')}
                </DropdownMenuItem>
                <DropdownMenuItem
                  data-testid="dropdownItemReject"
                  onClick={() => setDecisionAndOpenDialog(ProcessOrderDecision.Reject)}
                >
                  {t('rsp:pages.orders.list.actions.reject')}
                </DropdownMenuItem>
              </HasAccess>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {decisionFlag && decision && (
        <OrderAprovalConfirmationDialog
          decision={decision}
          order={order}
          cancelConfirmation={cancelConfirmation}
          onSubmit={onSubmit}
          confirmationDialogOpened={confirmationDialogOpened}
        />
      )}
    </>
  )
}
