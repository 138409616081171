export const getInitials = (value?: string) => {
  if (!value || value.trim().length === 0) {
    return ''
  }

  const words = value.trim().split(/\s+/)
  const initials: string[] = []

  if (words.length > 0) {
    initials.push(words[0][0].toUpperCase())
  }

  if (words.length === 1) {
    initials.push(words[0][1].toUpperCase())
  }

  if (words.length > 1 && initials.length < 2) {
    initials.push(words[1][0].toUpperCase())
  }

  return initials.join('').slice(0, 2)
}
