import { useTranslation } from 'react-i18next'

import { usePortalMetadata } from '@sherweb/core/modules/portalMetadata'

import { useGetIdp } from './hooks/useGetIdp'

type LoginHeaderProps = {
  resellerName?: string
  resellerLogoURL?: string
}
export const LoginHeader = ({ resellerName, resellerLogoURL }: LoginHeaderProps) => {
  const { t } = useTranslation()
  const { data: portalMetadata } = usePortalMetadata()
  const idp = useGetIdp()

  return (
    <>
      <div className="flex items-center justify-center">
        {portalMetadata && resellerLogoURL ? (
          <img
            src={resellerLogoURL}
            className="max-h-32 w-fit object-contain align-middle italic"
            alt={t('ssp:pages.login.resellerLogoAlternative', { resellerName })}
            data-testid="logo"
          />
        ) : (
          <p className="flex h-28 w-28 items-center rounded-md p-2 align-middle font-medium italic text-slate-900" />
        )}
      </div>
      <div className="flex flex-col gap-8">
        <h1
          className="text-3xl font-semibold text-slate-900 dark:text-slate-200"
          data-testid="resellerName"
        >
          {t('ssp:pages.login.signInTo', { resellerName })}
        </h1>
        <p
          className="text-sm text-slate-500 dark:text-slate-400"
          data-testid="resellerNameParagraph"
        >
          {t(`ssp:pages.login.loginform.${idp}.welcomeBack`, { resellerName })}
        </p>
      </div>
    </>
  )
}
