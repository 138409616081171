import { useTranslation } from 'react-i18next'
import * as z from 'zod'

const VALIDATION_MINIMUM_TRANSLATION_KEY = 'ssp:validations.string.min'
const VALIDATION_MAXIMUM_TRANSLATION_KEY = 'ssp:validations.string.max'

export type CreateSubscriberFormType = z.infer<ReturnType<typeof useCreateSubscriberSchema>>

export const useCreateSubscriberSchema = () => {
  const { t } = useTranslation()

  return z.object({
    firstName: z
      .string()
      .min(2, {
        message: `${t(VALIDATION_MINIMUM_TRANSLATION_KEY, { path: t('ssp:pages.licenses.createSubcriberForm.placeholders.firstName'), min: 2 })}`,
      })
      .max(30, {
        message: `${t(VALIDATION_MAXIMUM_TRANSLATION_KEY, { path: t('ssp:pages.licenses.createSubcriberForm.placeholders.firstName'), max: 30 })}`,
      }),
    lastName: z
      .string()
      .min(2, {
        message: `${t(VALIDATION_MINIMUM_TRANSLATION_KEY, { path: t('ssp:pages.licenses.createSubcriberForm.placeholders.lastName'), min: 2 })}`,
      })
      .max(30, {
        message: `${t(VALIDATION_MAXIMUM_TRANSLATION_KEY, { path: t('ssp:pages.licenses.createSubcriberForm.placeholders.lastName'), max: 30 })}`,
      }),
    username: z
      .string()
      .min(2, {
        message: `${t(VALIDATION_MINIMUM_TRANSLATION_KEY, { path: t('ssp:pages.licenses.createSubcriberForm.placeholders.username'), min: 2 })}`,
      })
      .max(30, {
        message: `${t(VALIDATION_MAXIMUM_TRANSLATION_KEY, { path: t('ssp:pages.licenses.createSubcriberForm.placeholders.username'), max: 30 })}`,
      }),
    currentDomain: z.string(),
  })
}
