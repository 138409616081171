import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Badge, { Variant } from '@sherweb/core/components/Badge'
import { Form } from '@sherweb/core/components/Form'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import { usePrevious } from '@sherweb/core/hooks'

import { Reseller } from '@rsp/modules/reseller'

import { SubmitButton } from '../components/SubmitButton'
import { OrderSettingsFormType, useOrderSettingsSchema } from './hooks/useOrderSettingsSchema'
import { useSubmitOrderSettings } from './hooks/useSubmitOrderSettings'
import { OrderApproval } from './OrderApproval'
import { OrderNotifications } from './OrderNotifications'

type OrderSettingsFormProps = {
  isFetching?: boolean
  reseller: Reseller
}

const OrderSettingsForm = ({ reseller }: OrderSettingsFormProps) => {
  const { t } = useTranslation()

  const orderSettingsSchema = useOrderSettingsSchema()

  const form = useForm<OrderSettingsFormType>({
    resolver: zodResolver(orderSettingsSchema),
    defaultValues: reseller.orderSettings,
  })

  const webHookUrl = reseller?.orderSettings?.teamNotifications?.webHookUrl

  const previousResellerOrderSettings = usePrevious(reseller.orderSettings)

  const submitOrderSettings = useSubmitOrderSettings({ webHookUrl })

  useEffect(() => {
    if (reseller.orderSettings !== previousResellerOrderSettings) {
      form.reset(reseller.orderSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousResellerOrderSettings, reseller.orderSettings])

  const handleSubmit = async (values: OrderSettingsFormType) => {
    await submitOrderSettings.onSubmit(values)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="my-4 flex flex-col gap-y-16 rounded-xl p-4 dark:bg-slate-900 md:px-8 md:py-16"
      >
        <OrderApproval />
        <OrderNotifications
          reset={submitOrderSettings.onResetMutationResult}
          hasConnectionFailed={submitOrderSettings.hasConnectionFailed}
          renderTeamsActionsComponent={({ teamNotificationsWebHookUrl, isWebHookValid }) => {
            const hasConnectionPassed =
              (webHookUrl === teamNotificationsWebHookUrl && webHookUrl !== '') ||
              submitOrderSettings.isTestConnectionValid

            return hasConnectionPassed ? (
              <Badge className="whitespace-nowrap" variant={Variant.Success}>
                {t(
                  'rsp:pages.settings.orderSettings.orders.notifications.teams.webhook.connectionSuccess'
                )}
              </Badge>
            ) : (
              <LoaderButton
                type="button"
                variant="None"
                className="h-full gap-x-2 whitespace-nowrap bg-indigo-600 hover:bg-indigo-700"
                onClick={async () => {
                  await submitOrderSettings.onTestWebHookConnection(
                    isWebHookValid,
                    teamNotificationsWebHookUrl
                  )
                }}
                isLoading={submitOrderSettings.isTestConnectionLoading}
                loadingChildren={t(
                  'rsp:pages.settings.orderSettings.orders.notifications.teams.webhook.testConnection'
                )}
              >
                {t(
                  'rsp:pages.settings.orderSettings.orders.notifications.teams.webhook.testConnection'
                )}
              </LoaderButton>
            )
          }}
        />
        <SubmitButton
          disabled={submitOrderSettings.isSaveDisabled}
          showLoader={submitOrderSettings.isOrderSettingsLoading}
        />
      </form>
    </Form>
  )
}

export default OrderSettingsForm
