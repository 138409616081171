/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StepWizardChildProps } from 'react-step-wizard'
import * as z from 'zod'

import Button from '@sherweb/core/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@sherweb/core/components/Select'

import {
  usePostServiceProviderConfigurationMutation,
  useServiceProviders,
  useServiceProvidersConfigurations,
} from '@rsp/modules/integrations/serviceProvider'

import serviceProviderConfigurationSchema from '../validationSchema/serviceProviderConfigurationSchema'

export type ServiceProviderConfigurationStepProps = {
  setValid: (value: boolean | ((prevVar: boolean) => boolean)) => void
} & Partial<StepWizardChildProps>

const ServiceProviderConfigurationStep: React.FC<ServiceProviderConfigurationStepProps> = ({
  ...props
}) => {
  const { t } = useTranslation()
  const { data: serviceProviders } = useServiceProviders()
  const serviceProvidersConfigurationsQuery = useServiceProvidersConfigurations()

  const serviceProviderConfigurationMutation = usePostServiceProviderConfigurationMutation()

  const form: UseFormReturn<z.infer<typeof serviceProviderConfigurationSchema>> = useForm<
    z.infer<typeof serviceProviderConfigurationSchema>
  >({
    resolver: zodResolver(serviceProviderConfigurationSchema),
    defaultValues: {
      publicApiKey: '',
      privateApiKey: '',
      subscriptionKey: '',
    },
  })
  const serviceProvider = form.watch('serviceProvider')

  useEffect(() => {
    if (
      !serviceProvider &&
      serviceProviders &&
      serviceProviders?.length > 0 &&
      !serviceProvidersConfigurationsQuery?.isLoading
    ) {
      form.setValue(
        'serviceProvider',
        serviceProvidersConfigurationsQuery?.data &&
          serviceProvidersConfigurationsQuery?.data?.length !== 0
          ? serviceProvidersConfigurationsQuery?.data?.[0]?.serviceProviderId
          : serviceProviders?.[0]?.id
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProvider, serviceProviders, serviceProvidersConfigurationsQuery?.data])

  useEffect(() => {
    if (serviceProvider) {
      const configuration = serviceProvidersConfigurationsQuery?.data?.find(
        x => x.serviceProviderId === serviceProvider
      )

      form.setValue('publicApiKey', configuration?.clientId ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProvider, serviceProvidersConfigurationsQuery?.data])

  useEffect(() => {
    if (serviceProviderConfigurationMutation?.isSuccess) {
      props.setValid(true)
      props?.nextStep?.()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProviderConfigurationMutation?.isSuccess])

  async function onSubmit(values: z.infer<typeof serviceProviderConfigurationSchema>) {
    if (props?.nextStep !== undefined) {
      serviceProviderConfigurationMutation.mutate({
        serviceProviderId: values?.serviceProvider,
        clientId: values?.publicApiKey,
        clientSecret: values?.privateApiKey,
        subscriptionKey: values?.subscriptionKey,
      })
    }
  }

  const loaderButtonContent = t('rsp:pages.onboarding.nextStep')

  return (
    <div className="mt-6 flex flex-col gap-4 md:mt-12" data-testid="service-provider-step">
      <div className="flex flex-col gap-4 md:gap-12">
        <h1 className="text-3xl font-medium text-slate-900 dark:text-slate-200">
          {t('rsp:pages.onboarding.serviceProviderConfiguration.title')}
        </h1>
        <p className="text-sm text-slate-500 dark:text-slate-200" data-testid="description">
          <Trans i18nKey="rsp:pages.onboarding.serviceProviderConfiguration.description">
            <Link
              to="https://github.com/sherweb/Public-Apis/blob/master/README.md#prerequisite-for-using-the-apis"
              target="_blank"
              className="font-semibold text-indigo-600 hover:underline"
            />
          </Trans>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="mt-2 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="serviceProvider"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.serviceProviderConfiguration.labels.serviceProvider')}
                  </FormLabel>
                  <Select value={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t(
                            'rsp:pages.onboarding.serviceProviderConfiguration.placeholders.serviceProvider'
                          )}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent side="bottom">
                      {serviceProviders?.map(provider => {
                        return (
                          <SelectItem key={provider.name ?? ''} value={provider.id}>
                            {provider.name ?? ''}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="publicApiKey"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.serviceProviderConfiguration.labels.clientIdKey')}
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      {...field}
                      data-testid="inputClientId"
                      placeholder={t(
                        'rsp:pages.onboarding.serviceProviderConfiguration.labels.clientIdKeyPlaceholder'
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="privateApiKey"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.serviceProviderConfiguration.labels.clientSecretKey')}
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      {...field}
                      data-testid="inputClientSecret"
                      placeholder={t(
                        'rsp:pages.onboarding.serviceProviderConfiguration.labels.clientSecretKeyPlaceholder'
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="subscriptionKey"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.serviceProviderConfiguration.labels.subscriptionKey')}
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      {...field}
                      data-testid="inputSubscriptionKey"
                      placeholder={t(
                        'rsp:pages.onboarding.serviceProviderConfiguration.labels.subscriptionKeyPlaceholder'
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-4 flex flex-row justify-between">
              <Button
                variant="outline"
                type="button"
                onClick={props.previousStep}
                data-testid="btnServiceProviderConfigurationPreviousStep"
              >
                {t('rsp:pages.onboarding.previousStep')}
              </Button>
              <LoaderButton
                type="submit"
                dataTestId="btnServiceProviderConfigurationNextStep"
                disabled={serviceProviderConfigurationMutation?.isLoading}
                className="w-1/3"
                isLoading={serviceProviderConfigurationMutation?.isLoading}
                loaderDataTestId="loaderFormSubmit"
                loadingChildren={loaderButtonContent}
              >
                {loaderButtonContent}
              </LoaderButton>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default ServiceProviderConfigurationStep
