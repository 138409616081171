import { InvoiceSortField } from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationInvoicesService } from '@sherweb/core/openapi-generated/OrganizationInvoicesService'
import { OrganizationPaymentMethodsService } from '@sherweb/core/openapi-generated/OrganizationPaymentMethodsService'

import { Pagination } from '@sherweb/core/components/DataTable/types'
import { getQueryKey } from '@sherweb/core/modules/reactQuery'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error/error'

import { getOrganizationInvoicesQueryOptions } from './invoices.helpers'

export const organizationInvoicesQueryPrefix =
  'OrganizationInvoicesService/queryOrganizationInvoicesAsync'

const getInvoicesQueryFn = async (
  organizationId?: string,
  queryOptions = getOrganizationInvoicesQueryOptions()
) => {
  if (
    !organizationId ||
    !queryOptions?.sort?.field ||
    queryOptions?.sort?.ascending === undefined
  ) {
    return await rejectMissingParameters()
  }

  return await OrganizationInvoicesService.queryOrganizationInvoicesAsync({
    organizationId,
    query: queryOptions,
  })
}

export const queryOrganizationInvoicesAsync = {
  queryKey: (organizationId?: string, queryOptions?: Pagination<InvoiceSortField>) =>
    getQueryKey([organizationInvoicesQueryPrefix, organizationId, queryOptions]),
  queryFn: getInvoicesQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const queryOrganizationInvoicesAsyncWithInfiniteScroll = {
  queryKey: (organizationId?: string, queryOptions?: Pagination<InvoiceSortField>) =>
    getQueryKey([organizationInvoicesQueryPrefix, 'infiniteScroll', organizationId, queryOptions]),
  queryFn: getInvoicesQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const invoiceDetailsAsyncQueryOptions = {
  queryKey: (organizationId?: string, invoiceId?: string, integrationType?: string) =>
    getQueryKey([
      'OrganizationInvoicesService/getInvoiceDetailsAsync',
      organizationId,
      invoiceId,
      integrationType,
    ]),
  queryFn: async (organizationId?: string, invoiceId?: string, integrationType?: string) => {
    if (!organizationId || !invoiceId || !integrationType) {
      return await rejectMissingParameters()
    }

    return await OrganizationInvoicesService.getInvoiceDetailsAsync({
      organizationId,
      invoiceId,
      integrationType,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const paymentMethodsByOrganizationIdQueryOptions = {
  queryKey: (organizationId?: string) =>
    getQueryKey([
      'OrganizationPaymentMethodsService/getPaymentMethodsByOrganizationId',
      organizationId,
    ]),
  queryFn: async (organizationId?: string) => {
    if (!organizationId) {
      return await rejectMissingParameters()
    }

    return await OrganizationPaymentMethodsService.getPaymentMethodsByOrganizationId({
      organizationId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}
