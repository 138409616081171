import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import React from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root className={mergeClassName('grid', className)} {...props} ref={ref} />
  )
})

RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

export { RadioGroup }
