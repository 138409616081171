import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UserStatus } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import DataTable from '@sherweb/core/components/DataTable'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { Permission } from '@sherweb/core/modules/authorization'
import { isRequestLoading, isRequestRefetching } from '@sherweb/core/modules/reactQuery'
import { UserDeleteConfirmationDialog } from '@sherweb/core/modules/user/ui/UserDeleteConfirmationDialog'
import { useDateFormatter } from '@sherweb/core/utils/date'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess, usePermissionCheck } from '@ssp/modules/authorization'
import { User, UserRole, useUserRolesTranslation, useUserStatusBadge } from '@ssp/modules/user'
import { useDeleteUserMutation, useUsersByOrganization } from '@ssp/modules/user/core/user.hooks'

import { UserTableActionRow, UserTableActionRowProps } from './UserTableActionRow'
import { UserTableNameRow, UserTableNameRowProps } from './UserTableNameRow'

const CLASSNAME = 'text-center'

const renderEmailRowContent = ({ firstName, lastName, email }: UserTableNameRowProps) => (
  <UserTableNameRow firstName={firstName} lastName={lastName} email={email} />
)

const renderActionRowContent = ({
  user,
  setDeleting,
  setSelectedUser,
}: UserTableActionRowProps) => (
  <UserTableActionRow user={user} setDeleting={setDeleting} setSelectedUser={setSelectedUser} />
)

export const UsersTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const usersByOrganization = useUsersByOrganization()

  const getUserRoles = useUserRolesTranslation()

  const deleteUserMutation = useDeleteUserMutation()

  const userStatusBadge = useUserStatusBadge()

  const formatDate = useDateFormatter(language)

  const { permissionsLoading } = usePermissionCheck()

  const [deleting, setDeleting] = useState(false)

  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const isUserUpdating = deleteUserMutation?.isLoading || isRequestRefetching(usersByOrganization)

  const columns: Columns<User> = [
    {
      id: 'email',
      accessorFn: row => `${row.firstName ?? ''} ${row.lastName ?? ''}  ${row.email ?? ''}`,
      header: t('ssp:pages.users.list.columns.user'),
      enableSorting: true,
      cell: ({ cell, row }) =>
        renderEmailRowContent({
          firstName: row.original.firstName,
          lastName: row.original.lastName,
          email: row.original.email ?? '',
        }),
      minSize: 417,
    },
    {
      accessorKey: 'jobTitle',
      header: t('ssp:pages.users.list.columns.jobTitle'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      minSize: 278,
    },
    {
      accessorKey: 'roles',
      header: t('ssp:pages.users.list.columns.roles'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      cell: ({ getValue }) => getUserRoles(getValue<UserRole[]>()),
      minSize: 278,
    },
    {
      accessorKey: 'createdAt',
      header: t('ssp:pages.users.list.columns.createdOn'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      cell: ({ getValue }) => formatDate(getValue<string>()),
      minSize: 278,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableSorting: true,
      meta: { className: CLASSNAME },
      cell: ({ getValue }) => userStatusBadge(getValue<UserStatus>()),
      minSize: 278,
    },
    {
      id: 'actions',
      cell: ({ row }) =>
        renderActionRowContent({
          user: row.original,
          setDeleting,
          setSelectedUser,
        }),
      size: 139,
    },
  ]

  return (
    <>
      <Card padded className={isUserUpdating ? spinnerStyles({ type: 'layOverBackground' }) : ''}>
        {isUserUpdating ? <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} /> : null}
        {isRequestLoading(usersByOrganization) || permissionsLoading ? (
          <SkeletonTable />
        ) : (
          <DataTable
            columns={columns}
            data={usersByOrganization?.data}
            dataTestId="tableUsers"
            filterableFields={['email', 'jobTitle']}
            filterPlaceholder={t('ssp:pages.users.list.filterPlaceholder')}
            fieldDescription={t('ssp:pages.users.list.searchFieldDescription')}
            optionalActions={
              <HasAccess permission={Permission.UsersCreate}>
                <Button
                  variant="primary"
                  data-testid="btnAddUser"
                  size="sm"
                  onClick={() => {}}
                  asChild
                >
                  <Link
                    to={Routes.UsersInvite}
                    className="rounded-lg focus-visible:outline-indigo-300"
                  >
                    {t('ssp:pages.users.inviteUser')}
                  </Link>
                </Button>
              </HasAccess>
            }
          />
        )}
      </Card>
      {selectedUser ? (
        <UserDeleteConfirmationDialog
          selectedUser={selectedUser}
          open={deleting}
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deleteUserMutation.mutate(selectedUser?.id)
          }}
        />
      ) : null}
    </>
  )
}
