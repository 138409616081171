import { Permission, usePermissionsByReseller } from '@sherweb/core/modules/authorization'

export const usePermissionCheck = () => {
  const permissions = usePermissionsByReseller()

  return {
    permissionsLoading: permissions.isLoading,
    permissionsFetched: permissions.data.length > 0,
    hasAccess: (allowedPermission: Permission | Permission[]) => {
      if (permissions.data.length > 0) {
        if (
          Array.isArray(allowedPermission) &&
          allowedPermission.every(permission => permissions.data.includes(permission))
        ) {
          return true
        }

        if (permissions.data.some(permission => permission === allowedPermission)) {
          return true
        }
      }

      return false
    },
  }
}
