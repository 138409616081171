export const acceptedFiles = {
  'image/jpeg': [],
  'image/png': [],
  'image/bmp': [],
  'image/gif': [],
  'image/webp': [],
  'image/svg+xml': [],
}

export const editorPadding = 50

export const transparentImageData =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAIAAAC0Ujn1AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwwAADsMBx2+oZAAAADlJREFUSEvtzaENAEAMw8DfMUP8/huUFBm1sJKPhDkvG3/DNJgG02AajqZ7Z/phxjSYBtNgGi6mkwI2N9UKFp3MkAAAAABJRU5ErkJggg=='
