import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.scss'
import './datadog'

import Main from './Main'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
)

reportWebVitals()
