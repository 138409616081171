import { Loader2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import { DataTestId } from '@sherweb/core/types/dataTestIdType'

type LoaderButtonProps = {
  // Mobile view is used for mobile components and will render the icon if provided
  mobileView?: boolean
  // Icon used for mobile
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>
  // Mutation isLoading property
  isLoading: boolean
  loaderDataTestId?: string
  loaderClassName?: string
  loadingChildren?: React.ReactNode
  children?: React.ReactNode
} & React.ComponentProps<typeof Button> &
  DataTestId

const LoaderButton = ({
  Icon,
  mobileView,
  variant = 'primary',
  size = 'sm',
  className = 'h-full',
  isLoading,
  loaderDataTestId = 'loader',
  loaderClassName = 'mr-2 h-4 w-4',
  dataTestId = 'btnLoader',
  loadingChildren,
  onClick,
  children,
  ...buttonProps
}: LoaderButtonProps) => {
  const { t } = useTranslation()

  const getContent = () => {
    // Loading animation
    const loader = (
      <Loader2 className={`${loaderClassName} animate-spin`} data-testid={loaderDataTestId} />
    )

    // Loading state
    if (isLoading) {
      return mobileView ? (
        loader
      ) : (
        <>
          {loader}
          {loadingChildren ?? children ?? t('core:loader.loading')}
        </>
      )
    }

    // Waiting state
    return mobileView && Icon ? (
      <Icon className="h-4 w-4" data-testid="loaderMobileIcon" />
    ) : (
      <>{children ?? t('core:loader.title')}</>
    )
  }

  return (
    <Button
      variant={variant}
      size={size}
      className={className}
      data-testid={dataTestId}
      onClick={onClick}
      disabled={isLoading}
      {...buttonProps}
    >
      {getContent()}
    </Button>
  )
}

export default LoaderButton
