import { mergeClassName } from '../../../utils/mergeClassName'
import Card, { CardProps } from '../Card'

const TableCard: React.FC<CardProps> = ({ children, ...cardProps }) => {
  return (
    <Card
      padded={false}
      {...cardProps}
      className={mergeClassName('withTable relative', cardProps?.className)}
    >
      {children}
    </Card>
  )
}

export default TableCard
