import { SortingState } from '@tanstack/react-table'
import { useCallback } from 'react'

import { usePrevious } from '@sherweb/core/hooks'

import { PaginationSortParam } from '../types'

type UseSetSortingProps = {
  sorting: SortingState
  onSortBy?: (params: PaginationSortParam) => void
}

export const useSetSorting = ({ sorting, onSortBy }: UseSetSortingProps) => {
  const previousSorting = usePrevious(sorting)

  const onSortByServer = useCallback(
    (sorting: SortingState) => {
      const [selectedSortingColumn] = sorting

      onSortBy?.({
        sortField: selectedSortingColumn?.id,
        sortAscending: !selectedSortingColumn?.desc,
      })
    },
    [onSortBy]
  )

  return { previousSorting, onSortByServer }
}
