import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { useReseller } from '@ssp/modules/reseller'

export const SubscriptionHelp = () => {
  const { t } = useTranslation()

  const { data: reseller } = useReseller()

  const supportInformationEmail = reseller?.supportInformation?.email

  if (!supportInformationEmail) {
    return null
  }

  return (
    <a href={'mailto:' + (supportInformationEmail ?? '')} className="flex flex-row gap-4 px-4 pb-4">
      <div className="inline-flex h-11 w-11 items-center justify-center rounded-lg bg-indigo-100">
        <QuestionMarkCircleIcon className="h-7 w-7 text-indigo-500" />
      </div>
      <div className="flex flex-col">
        <p className="text-sm font-medium text-slate-900 dark:text-slate-200">
          {t('ssp:pages.subscription.details.tabs.manageSubscription.getHelp.title')}
        </p>
        <p className="text-sm text-gray-500 dark:text-slate-500">
          {t('ssp:pages.subscription.details.tabs.manageSubscription.getHelp.description')}
        </p>
      </div>
    </a>
  )
}
