import { BuildingOfficeIcon } from '@heroicons/react/24/outline'
import { t } from 'i18next'

import Badge, { Variant } from '@sherweb/core/components/Badge'

import { Organization } from '@ssp/modules/organization/core/organization.model'

type OrganizationNameProps = {
  organization: Organization
}

export const OrganizationName = ({ organization }: OrganizationNameProps) => (
  <div
    className="dark:bg-slate-950s flex items-center bg-transparent p-2 text-sm capitalize text-gray-900 dark:text-white"
    data-testid="textOrganizationName"
  >
    <BuildingOfficeIcon className="mr-2 h-4 w-4 text-white md:text-gray-900 dark:md:text-white" />
    {organization?.name}
    {organization?.isSuspended ? (
      <Badge variant={Variant.Warning} className="ml-4">
        {t('ssp:organization.suspended')}
      </Badge>
    ) : null}
  </div>
)
