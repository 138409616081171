import { sort } from '@sherweb/core/common/dynamicSort'

import { Organization } from './organization.model'

export const sortOrganizationsByNameOrSubreseller = (
  org1: Organization,
  org2: Organization,
  key: 'name' | 'subreseller' = 'name'
) => {
  if (org1[key].toLocaleLowerCase() < org2[key].toLocaleLowerCase()) {
    return -1
  }

  if (org1[key].toLocaleLowerCase() > org2[key].toLocaleLowerCase()) {
    return 1
  }

  return 0
}

export const sortOrganizations = (organizations: Organization[]) => {
  let organizationsWithSubReseller: Organization[] = []
  let organizationsWithoutSubReseller: Organization[] = []

  ;[...organizations]
    .sort((a, b) => sortOrganizationsByNameOrSubreseller(a, b))
    .forEach(organization => {
      if (organization.subreseller) {
        organizationsWithSubReseller = [...organizationsWithSubReseller, organization]
      } else {
        organizationsWithoutSubReseller = [...organizationsWithoutSubReseller, organization]
      }
    })

  const sortedOrganizationsWithSubReseller = [...organizationsWithSubReseller].sort((a, b) =>
    sortOrganizationsByNameOrSubreseller(a, b, 'subreseller')
  )

  return [...organizationsWithoutSubReseller, ...sortedOrganizationsWithSubReseller]
}

export const getFirstPreferredOrganization = (orgs: Organization[]) => {
  if (!orgs) {
    return null
  }

  const copied = [...orgs]

  sort(copied, ['isSuspended', 'name'])

  return copied[0]
}
