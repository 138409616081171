import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { useEffect, useState } from 'react'

import { useAuthenticationLoggedInState } from '../../authentication'

export type HubServiceProps = {
  children: React.ReactNode
  connection: null | HubConnection
}

type HubProviderProps = {
  children: React.ReactNode
  bffUrl: string
  hubService: React.FC<HubServiceProps>
}

export const HubProvider = (props: HubProviderProps) => {
  const [connection, setConnection] = useState<null | HubConnection>(null)

  const { isLoggedIn } = useAuthenticationLoggedInState()

  useEffect(() => {
    if (isLoggedIn) {
      // eslint-disable-next-line no-void
      void configConnection()
    }

    return () => {
      if (connection && connection.state === HubConnectionState.Connected) {
        connection.stop().catch(error => {
          console.error(error)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bffUrl, isLoggedIn])

  const configConnection = async () => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${props.bffUrl}/ssp-hub`)
      .withAutomaticReconnect()
      .build()
    await connect.start().catch(error => console.error(error))

    setConnection(connect)
  }

  const HubService = props.hubService

  return <HubService connection={connection}>{props.children}</HubService>
}
