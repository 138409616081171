import { useFormContext } from 'react-hook-form'
import Color from 'tailwindcss/colors'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'

import { mergeClassName } from '../../utils/mergeClassName'
import { RadioGroup, RadioGroupItem } from '../Radio'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from './Form'

interface Option<T> extends DataTestId {
  value: T
  title: React.ReactNode
  description?: React.ReactNode
}

type ColorType = keyof typeof Color | 'primary' | 'secondary'

type FormRadioListProps<T> = {
  label: string
  field: string
  options: Array<Option<T>>
  color?: ColorType
}

export const FormRadioList = <T extends string>({
  label,
  field,
  options,
  color = 'indigo',
}: FormRadioListProps<T>) => {
  const form = useFormContext()

  return (
    <FormField
      control={form.control}
      name={field}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex flex-col"
            >
              {options.map(option => (
                <FormItem
                  key={option.value}
                  className="border-x border-t border-slate-300 first:rounded-t last:rounded-b last:border"
                  data-testid={option.dataTestId}
                >
                  <FormLabel
                    className={mergeClassName(
                      'flex space-x-3 rounded-[inherit] border-inherit p-4 hover:cursor-pointer',
                      {
                        [`bg-${color}-100`]: option.value === field.value,
                      }
                    )}
                  >
                    <FormControl>
                      <RadioGroupItem
                        className={option.value === field.value ? `bg-${color}-500` : ''}
                        value={option.value}
                      />
                    </FormControl>
                    <div className="flex flex-col gap-1">
                      <div
                        className={mergeClassName(
                          'font-medium leading-4 text-gray-800 dark:text-slate-200',
                          {
                            'dark:text-gray-900': option.value === field.value,
                          }
                        )}
                      >
                        {option.title}
                      </div>
                      <div className="text-xs font-normal text-slate-500">{option.description}</div>
                    </div>
                  </FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
