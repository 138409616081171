/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

import { HubServiceProps } from '@sherweb/core/modules/signalR'

import { useReceivers } from './hub.receivers.hooks'
import { useHubSender } from './hub.senders.hooks'

export const HubService: React.FC<HubServiceProps> = ({
  connection,
  children,
}: HubServiceProps) => {
  const receivers = useReceivers()
  useEffect(() => {
    if (connection?.connectionId) {
      receivers.forEach(r => {
        connection.on(r.key, r.invokeMethod)
      })
    }
  }, [connection?.connectionId])

  useHubSender(connection)

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  )
}
