import { useTranslation } from 'react-i18next'

import { OrderStatus as OrdersStatusType } from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'

type OrderStatusProps = {
  orderStatus?: OrdersStatusType | null
}

export const OrderStatus = ({ orderStatus }: OrderStatusProps) => {
  const { t } = useTranslation()

  const statusBadgeMapper = {
    [OrdersStatusType.Failed]: (
      <Badge variant={Variant.Danger}>{t('core:orders.status.failed')}</Badge>
    ),
    [OrdersStatusType.Pending]: (
      <Badge variant={Variant.Warning}>{t('core:orders.status.pending')}</Badge>
    ),
    [OrdersStatusType.Completed]: (
      <Badge variant={Variant.Success}>{t('core:orders.status.completed')}</Badge>
    ),
    [OrdersStatusType.WaitingForApproval]: (
      <Badge variant={Variant.Warning}>{t('core:orders.status.waitingForApproval')}</Badge>
    ),
    [OrdersStatusType.Rejected]: (
      <Badge variant={Variant.Danger}>{t('core:orders.status.rejected')}</Badge>
    ),
  }

  return orderStatus ? statusBadgeMapper[orderStatus] : null
}
