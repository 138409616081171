import Routes from '@ssp/app/Routes'
import { useNavigate } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'

export const useRedirectToInvoicesPage = () => {
  const { navigate } = useNavigate()

  const selectedOrganization = useSelectedOrganization()

  return () => {
    navigate(Routes.Invoices, {
      organizationUniqueName: selectedOrganization?.uniqueName,
    })
  }
}
