import { buildTheme } from '@sherweb/core/modules/theme'

export const defaultTheme = buildTheme({
  primary: { light: 'rgb(15, 23, 42)', dark: 'rgb(15, 23, 42)' },
  secondary: { light: 'rgb(55, 65, 81)', dark: 'rgb(137, 154, 181)' },
  bgGradient: { light: 'rgb(195, 237, 245)', dark: 'rgb(24, 52, 98)' },
})

export const greenTheme = buildTheme({
  primary: { light: 'rgb(4, 60, 25)', dark: 'rgb(7, 67, 30)' },
  secondary: { light: 'rgb(9, 87, 39)', dark: 'rgb(203, 240, 216)' },
  bgGradient: { light: 'rgb(168, 243, 197)', dark: 'rgb(7, 67, 30)' },
})

export const blueTheme = buildTheme({
  primary: { light: 'rgb(32, 43, 79)', dark: 'rgb(18, 52, 106)' },
  secondary: { light: 'rgb(50, 62, 101)', dark: 'rgb(194, 210, 231)' },
  bgGradient: { light: 'rgb(145, 166, 237)', dark: 'rgb(18, 52, 106)' },
})
