import config from '@config'

import { PortalMetadata } from './portalMetadata.models'

export enum PortalMode {
  RSP = 'RSP',
  SSP = 'SSP',
}

export const setManifestAndDocumentMeta = (
  mode: PortalMode,
  data?: PortalMetadata,
  loadingDocumentTitle?: string
) => {
  if (config?.bff?.sspUrl) {
    setAttribute('manifest-placeholder', 'href', `${config?.bff?.sspUrl}/ssp-manifest.webmanifest`)
  }

  if (data?.icon) {
    setAttribute('icon-placeholder', 'href', data.icon)
  }

  if (data?.themeColor) {
    setAttribute('theme-color-placeholder', 'content', data.themeColor)
  }

  if (data?.name) {
    document.title = data.name ?? loadingDocumentTitle ?? mode
  }
}

const setAttribute = (elemId: string, attribute: string, value: string) => {
  const elem = document.getElementById(elemId)

  if (elem) {
    elem.setAttribute(attribute, value)
  }
}
