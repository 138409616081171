import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'

import Routes from '@rsp/app/Routes'
import { useNavigate } from '@rsp/modules/navigation'

type OrderAlreadyReviewedProps = {
  open: boolean
}

export const OrderAlreadyReviewed = ({ open = false }: OrderAlreadyReviewedProps) => {
  const { t } = useTranslation()

  const { navigate } = useNavigate()

  const handleClose = useCallback(() => {
    navigate(Routes.Orders)
  }, [navigate])

  return (
    <ConfirmationDialog
      open={open}
      title={t('rsp:pages.orders.orderAlreadyReviewed.title')}
      cancelButtonText={t('rsp:actions.cancel')}
      onCancel={handleClose}
      onClose={handleClose}
    >
      {t('rsp:pages.orders.orderAlreadyReviewed.content')}
    </ConfirmationDialog>
  )
}
