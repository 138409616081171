import { useTranslation } from 'react-i18next'

import { UserWithLicensesQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@sherweb/core/components/Tabs'

import { ProductAvatarWithName } from '@ssp/components/Avatars'

type UserLicensesProps = {
  user?: UserWithLicensesQueryResult
}

export const UserLicenses = ({ user }: UserLicensesProps) => {
  const { t } = useTranslation()

  return (
    <Tabs defaultValue="licenses" className="w-full">
      <TabsList>
        <TabsTrigger value="licenses" className="focus-visible:ring-indigo-300">
          {t('ssp:pages.user.edit.tab.licenses')}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="licenses" className="focus-visible:ring-indigo-300">
        <div className="flex pt-10">
          <div className="grid grid-cols-4 gap-8">
            {user?.licenses?.map(license => (
              <div className="col-span-4 md:col-span-2 lg:col-span-1" key={license.id}>
                <ProductAvatarWithName fullName={license?.productName} />
              </div>
            ))}
          </div>
        </div>
      </TabsContent>
    </Tabs>
  )
}
