import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { ResellerUsersService } from '@sherweb/core/openapi-generated/ResellerUsersService'

import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { missingParametersError } from '@sherweb/core/utils/error'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { resellerAdminsAndGuests } from './users.queries'

export const useGetResellerAdminsAndGuestsQuery = () => {
  const resellerId = useSelectedResellerId()

  const { isLoggedIn } = useAuthenticationLoggedInState()

  return useQuery({
    queryKey: resellerAdminsAndGuests.queryKey(resellerId),
    queryFn: async () => await resellerAdminsAndGuests.queryFn(resellerId),
    enabled: !!resellerId && !!isLoggedIn,
    staleTime: resellerAdminsAndGuests.staleTime,
  })
}

export const useDeleteResellerUserMutation = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const resellerId = useSelectedResellerId()

  return useMutation({
    mutationFn: async (userId: string | undefined) => {
      if (!resellerId || !userId) {
        throw missingParametersError()
      }

      await ResellerUsersService.deleteResellerUser({
        resellerId,
        userId,
      })
    },
    onSuccess: async () => {
      successNotification(t('core:user.userDeletionDialog.success'))

      await queryClient.invalidateQueries({
        queryKey: resellerAdminsAndGuests.queryKey(resellerId),
      })
    },
  })
}
