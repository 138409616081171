// based on: https://shadcn-extension.vercel.app/docs/multi-select

import { CheckIcon } from '@heroicons/react/24/outline'
import { Command as CommandPrimitive } from 'cmdk'
import { X as RemoveIcon } from 'lucide-react'
import React, {
  createContext,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { usePrevious } from '@sherweb/core/hooks'
import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { arrayToObject } from '@sherweb/core/utils/array'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import Badge from '../Badge'
import { Command, CommandEmpty, CommandItem, CommandList } from '../Command'
import { Typography } from '../Typography'

type MultiSelectProps = {
  values: string[] | undefined
  onValuesChange: (value: string[] | undefined) => void
  options?: Array<{
    label: string | React.ReactNode
    value?: string
    disabled?: false
  }>
} & React.ComponentPropsWithoutRef<typeof CommandPrimitive>

interface MultiSelectContextProps {
  value: string[] | undefined
  onValueChange: (value: string) => void
  open: boolean
  setOpen: (value: boolean) => void
  mappedOptions: Record<string, { label: string | React.ReactNode; value?: string }>
  setMappedOptions: React.Dispatch<
    React.SetStateAction<
      Record<
        string,
        {
          label: string | React.ReactNode
          value?: string
        }
      >
    >
  >
  options: Array<{
    label: string | React.ReactNode
    value?: string
    disabled?: boolean
  }>
  inputValue: string
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  activeIndex: number
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>
}

const MultiSelectContext = createContext<MultiSelectContextProps | null>(null)

const useMultiSelect = () => {
  const context = useContext(MultiSelectContext)

  if (!context) {
    throw new Error('useMultiSelect must be used within MultiSelectProvider')
  }

  return context
}

const MultiSelect = ({
  values: value = [],
  onValuesChange: onValueChange,
  options = [],
  className,
  children,
  ...props
}: MultiSelectProps) => {
  const [inputValue, setInputValue] = useState('')

  const [open, setOpen] = useState<boolean>(false)

  const [activeIndex, setActiveIndex] = useState<number>(-1)

  const previousOptions = usePrevious(options)

  const [mappedOptions, setMappedOptions] = useState<
    Record<string, { label: string | React.ReactNode; value?: string }>
  >({})

  const onValueChangeHandler = useCallback(
    (currentValue: string) => {
      if (value?.includes(currentValue)) {
        onValueChange(value?.filter(item => item !== currentValue))

        return
      }

      onValueChange([...(value ?? []), currentValue])
    },
    [onValueChange, value]
  )

  useEffect(() => {
    const values = options?.map(option => option.value)

    if (
      !previousOptions &&
      previousOptions !== options &&
      Object.keys(mappedOptions)?.map(item => !values.includes(item))
    ) {
      setMappedOptions(arrayToObject(options, 'value'))
    }
  }, [mappedOptions, options, previousOptions])

  const moveNext = useCallback(() => {
    const nextIndex = activeIndex + 1

    if (value?.length !== 0) {
      setActiveIndex(nextIndex > value?.length - 1 ? -1 : nextIndex)
    }
  }, [activeIndex, value?.length])

  const movePrev = useCallback(() => {
    const prevIndex = activeIndex - 1

    setActiveIndex(prevIndex < 0 ? value?.length - 1 : prevIndex)
  }, [activeIndex, value?.length])

  const handleValueChange = useCallback(() => {
    if (activeIndex !== -1 && activeIndex < value?.length) {
      onValueChange(value?.filter(item => item !== value[activeIndex]))

      const newIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1

      setActiveIndex(newIndex)

      return
    }

    onValueChange(value?.filter(item => item !== value[value?.length - 1]))
  }, [activeIndex, onValueChange, value])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (
        (e.key === 'Backspace' || e.key === 'Delete') &&
        value?.length > 0 &&
        inputValue.length === 0
      ) {
        handleValueChange()
      }

      if (e.key === 'Enter') {
        setOpen(true)
      }

      if (e.key === 'Escape') {
        if (activeIndex !== -1) {
          setActiveIndex(-1)

          return
        }

        setOpen(false)
      }

      if (e.key === 'ArrowLeft') {
        movePrev()
      }

      if (e.key === 'ArrowRight' && activeIndex !== -1) {
        moveNext()
      }
    },
    [activeIndex, handleValueChange, inputValue.length, moveNext, movePrev, value?.length]
  )

  const memoOptions = useMemo(() => options, [options])

  const multiSelectContextValue = useMemo(() => {
    return {
      value,
      onValueChange: onValueChangeHandler,
      open,
      setOpen,
      mappedOptions,
      options: memoOptions,
      setMappedOptions,
      inputValue,
      setInputValue,
      activeIndex,
      setActiveIndex,
    }
  }, [activeIndex, inputValue, mappedOptions, memoOptions, onValueChangeHandler, open, value])

  return (
    <MultiSelectContext.Provider value={multiSelectContextValue}>
      <Command
        onKeyDown={handleKeyDown}
        className={mergeClassName(
          'overflow-visible bg-transparent flex flex-col space-y-2 !max-w-[100%]',
          className
        )}
        {...props}
      >
        {children}
        <MultiSelectContent />
      </Command>
    </MultiSelectContext.Provider>
  )
}

const MultiSelectTrigger = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    const { value, onValueChange, activeIndex, open, mappedOptions } = useMultiSelect()

    const mousePreventDefault = useCallback((e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
    }, [])

    return (
      <div
        ref={ref}
        tabIndex={0}
        className={mergeClassName(
          'flex flex-wrap gap-2 p-2 py-2 items-center border border-muted rounded-lg bg-background',
          'dark:bg-slate-950 dark:text-slate-200',
          open && 'border-blue-600 dark:ring-blue-600 dark:border-blue-600',
          className
        )}
        {...props}
      >
        {value?.map((item, index) => (
          <Badge
            key={item}
            data-testid="multiSelectSelectedItem"
            className={mergeClassName(
              'pl-2 pr-1 flex items-center gap-1 h-[28px]',
              activeIndex === index && 'ring-2 ring-muted-foreground '
            )}
            variant="secondary"
          >
            <Typography variant="body2">{mappedOptions?.[item]?.label}</Typography>
            <button
              aria-roledescription="button to remove option"
              type="button"
              data-testid="multiSelectRemoveOption"
              onMouseDown={mousePreventDefault}
              onClick={() => onValueChange(item)}
            >
              <span className="sr-only">Remove {mappedOptions?.[item]?.label} option</span>
              <RemoveIcon className="h-3 w-3 dark:text-white" />
            </button>
          </Badge>
        ))}
        {children}
      </div>
    )
  }
)

MultiSelectTrigger.displayName = 'MultiSelectTrigger'

const MultiSelectInput = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & DataTestId
>(({ className, dataTestId, ...props }, ref) => {
  const { setOpen, inputValue, setInputValue, activeIndex, setActiveIndex } = useMultiSelect()

  return (
    <CommandPrimitive.Input
      {...props}
      ref={ref}
      value={inputValue}
      onValueChange={activeIndex === -1 ? setInputValue : undefined}
      data-testid={dataTestId ?? 'multiSelectInput'}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
      onClick={() => setActiveIndex(-1)}
      className={mergeClassName(
        'text-sm rounded-md border border-slate-300 px-3 py-1.5 flex-2 placeholder:text-gray-400 text-slate-900 flex-1',
        'dark:border-gray-700 dark:bg-slate-950 dark:text-slate-200 dark:placeholder:text-gray-600 pl-0',
        'dark:text-white',
        'border-none focus:[box-shadow:none] focus:outline-none',
        'disabled:bg-slate-100 disabled:select-none',
        className,
        activeIndex !== -1 && 'caret-transparent'
      )}
    />
  )
})

MultiSelectInput.displayName = 'MultiSelectInput'

const MultiSelectContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children }, ref) => {
    const { open, options } = useMultiSelect()
    return (
      <div ref={ref} className="dark:border-1 relative dark:border-white">
        {open ? (
          <MultiSelectList>
            {options?.map(option => (
              <MultiSelectItem
                key={option?.value}
                value={option?.value ?? ''}
                disabled={option?.disabled}
                data-testid="multiSelectItem"
              >
                {option.label}
              </MultiSelectItem>
            ))}
          </MultiSelectList>
        ) : null}
      </div>
    )
  }
)

MultiSelectContent.displayName = 'MultiSelectContent'

const MultiSelectList = forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, children }, ref) => {
  const { t } = useTranslation()

  return (
    <CommandList
      ref={ref}
      className={mergeClassName(
        'p-1 flex flex-col gap-2 rounded-md scrollbar-thin scrollbar-track-transparent transition-colors',
        'scrollbar-thumb-muted-foreground dark:scrollbar-thumb-muted scrollbar-thumb-rounded-lg w-full',
        'absolute bg-background shadow-md z-10 border dark:border-white top-0',
        className
      )}
    >
      {children}
      <CommandEmpty data-testid="multiSelectNoResult">
        <span className="text-muted-foreground">{t('core:dataTable.noResults')}</span>
      </CommandEmpty>
    </CommandList>
  )
})

MultiSelectList.displayName = 'MultiSelectList'

const MultiSelectItem = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  { value: string } & React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, value, children, ...props }, ref) => {
  const { value: Options, onValueChange, setInputValue } = useMultiSelect()

  const mousePreventDefault = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const isIncluded = value?.length !== 0 ? Options?.includes(value) : false

  return (
    <CommandItem
      ref={ref}
      {...props}
      onSelect={() => {
        onValueChange(value)
        setInputValue('')
      }}
      className={mergeClassName(
        'relative items-center flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none hover:bg-accent hover:text-accent-foreground aria-selected:bg-accent aria-selected:dark:text-white aria-selected:text-accent-foreground  data-[disabled]:pointer-events-none data-[disabled]:opacity-50 disabled:bg-slate-100',
        className,
        props.disabled && 'opacity-50 cursor-not-allowed'
      )}
      onMouseDown={mousePreventDefault}
    >
      {isIncluded && (
        <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
          <CheckIcon className="h-4 w-4" />
        </span>
      )}

      {children}
    </CommandItem>
  )
})

MultiSelectItem.displayName = 'MultiSelectItem'

export {
  MultiSelect,
  MultiSelectTrigger,
  MultiSelectInput,
  MultiSelectContent,
  MultiSelectList,
  MultiSelectItem,
}
