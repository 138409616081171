import { mergeClassName } from '../../utils/mergeClassName'

type SidebarMenuProps = {
  logo?: string
  onLogoClick?: () => void
  logoContainerClassName?: string
  companyName?: string
  children?: React.ReactNode
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  logo,
  onLogoClick,
  logoContainerClassName,
  companyName,
  children,
}) => {
  return (
    <aside
      className="z-50 flex h-screen w-64 flex-none flex-col gap-16 overflow-auto bg-slate-900 dark:border-r dark:border-slate-800 dark:bg-slate-950 lg:fixed lg:h-full lg:w-64"
      aria-label="Sidebar"
      data-testid="sidebar"
    >
      <div
        className={mergeClassName(
          'flex shrink h-24 lg:h-40 items-center p-2 lg:shrink-0 justify-center mt-4 mx-6 rounded-lg',
          onLogoClick && 'cursor-pointer',
          logoContainerClassName
        )}
        onClick={onLogoClick}
      >
        <img
          src={logo}
          className="block max-h-full max-w-full object-contain align-middle italic"
          alt={companyName}
          data-testid="logo"
        />
      </div>

      <div className="mt-1 flex-1 px-2">
        <nav className="flex-1 space-y-1">{children}</nav>
      </div>
    </aside>
  )
}

export default SidebarMenu
