import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate } from 'react-router-dom'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import Backlink from '@sherweb/core/components/Backlink'
import Card from '@sherweb/core/components/Card'
import { Markdown, useGetMarkdownPreviewer } from '@sherweb/core/components/Markdown'
import PageTitle from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@sherweb/core/components/Tabs'
import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'
import { Permission } from '@sherweb/core/modules/authorization'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import Link from '@ssp/components/Link/Link'
import { HasAccess } from '@ssp/modules/authorization'
import { useSelectedOrganization } from '@ssp/modules/organization'
import { useReseller } from '@ssp/modules/reseller'
import { useDismissOrderError, useUpdateQuantityAction } from '@ssp/modules/subscription'

import { useGetSubscription } from './hooks/useGetSubscription'
import LastOrder from './LastOrders/LastOrders'
import MetaInformation from './MetaInformation/MetaInformation'
import OrderApproval from './OrderApproval'
import PriceSection from './PriceSection'
import QuantityForm from './QuantityForm'
import { useIsSubscriptionCancellationAllowed } from './subscription.helpers'
import { SubscriptionCancel } from './SubscriptionCancel'
import { SubscriptionCancelConfirmation } from './SubscriptionCancelConfirmation'
import { SubscriptionFailedAlert } from './SubscriptionFailedAlert'
import { SubscriptionHelp } from './SubscriptionHelp'
import Summary from './Summary'

const SubscriptionPage = () => {
  const { t } = useTranslation()

  const organizationUniqueName = useSelectedOrganization()?.uniqueName ?? ''

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)

  const { isLoading: isLoadingReseller, data: reseller } = useReseller()

  const supportInformationEmail = reseller?.supportInformation?.email

  const { convertMarkdown } = useGetMarkdownPreviewer()

  const subscription = useGetSubscription()
  const [quantity, setQuantity] = useState(subscription?.quantity)

  const isSubscriptionCancellationAllowed = useIsSubscriptionCancellationAllowed(
    subscription?.committedMinimalQuantity
  )

  const hasManagedSubscriptionTab = isSubscriptionCancellationAllowed || supportInformationEmail

  useEffect(() => {
    if (!quantity) {
      setQuantity(subscription?.quantity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription?.quantity])

  const { updateQuantity, isLoading: updating } = useUpdateQuantityAction(
    subscription?.subscriptionId
  )

  const { dismissOrderError, isLoading: updatingError } = useDismissOrderError()

  const showSpinner = subscription?.isLoading || updating || isLoadingReseller

  const onSubmit = async (quantity: number) => {
    try {
      await updateQuantity(quantity)
      successNotification(t('ssp:pages.subscription.updateSuccess'))
    } catch (error) {
      errorNotification(t('ssp:errors.generic'))
    }
  }

  const onDismissError = async () => {
    try {
      await dismissOrderError(subscription?.lastPendingOrFailedOrder?.orderId, subscription?.id)
    } catch (error) {
      errorNotification(t('ssp:errors.generic'))
    }
  }

  const handleCloseCancelConfirmation = () => {
    setShowCancelConfirmation(false)
  }

  const handleShowCancelConfirmation = () => {
    setShowCancelConfirmation(true)
  }

  if (subscription?.notFoundError) {
    return <Navigate replace to={generatePath(Routes.Subscriptions, { organizationUniqueName })} />
  }

  return (
    <>
      <ContainedPage>
        {showSpinner ? <Spinner floating /> : null}
        {subscription && !subscription.isLoading ? (
          <div className="flex flex-col gap-8 md:gap-16">
            <div>
              <Link to={Routes.Subscriptions} data-testid="linkBackToSubscriptions">
                <Backlink>{t('ssp:pages.subscription.backlink')}</Backlink>
              </Link>
              <PageTitle data-testid="pageSubscriptionTitle">{subscription?.productName}</PageTitle>
            </div>
            <div className="flex flex-col gap-8">
              {subscription?.hasLastOrderPendingQuantity ? (
                <div>
                  <Alert variant={Variant.Warning}>
                    {t('ssp:pages.subscription.quantityForm.pendingQuantityWarning')}
                  </Alert>
                </div>
              ) : null}
              <SubscriptionFailedAlert
                supportEmail={supportInformationEmail}
                updatingError={updatingError}
                onDismissError={onDismissError}
              />
              <Card padded className="grid w-full grid-cols-6   gap-12 xl:w-full xl:gap-32">
                <div className="col-span-6 flex flex-col gap-8 md:gap-12 lg:col-span-3">
                  <MetaInformation subscriptionId={subscription?.subscriptionId} />
                  <HasAccess permission={Permission.SubscriptionEdit}>
                    <QuantityForm
                      onQuantityChange={setQuantity}
                      onShowCancelConfirmation={
                        isSubscriptionCancellationAllowed ? handleShowCancelConfirmation : undefined
                      }
                      onSubmit={onSubmit}
                      isSubscriptionCancellationAllowed={isSubscriptionCancellationAllowed}
                    />
                  </HasAccess>
                </div>
                <div className="col-span-6 lg:col-span-3">
                  <div className="flex flex-col gap-6 md:gap-10">
                    <PriceSection
                      subscriptionId={subscription?.subscriptionId}
                      quantity={quantity}
                    />
                    {subscription?.showLastOrder && (
                      <LastOrder
                        humanReadableid={subscription?.lastPendingOrFailedOrder?.humanReadableId}
                        createdAt={subscription?.lastPendingOrFailedOrder?.createdAt}
                        deltaQuantity={subscription?.lastOrderFailedQuantityDelta}
                        operationType={subscription?.subscriptionUpdateOperation}
                        orderStatus={subscription?.lastPendingOrFailedOrder?.status}
                        decisionNote={subscription?.lastPendingOrFailedOrder?.decisionNote}
                      />
                    )}
                    <OrderApproval
                      orderId={subscription?.lastPendingOrFailedOrder?.orderId}
                      orderStatus={subscription?.lastPendingOrFailedOrder?.status}
                    />
                  </div>
                </div>
              </Card>
            </div>
            <Card padded>
              <Tabs defaultValue="details" className="w-full">
                <TabsList>
                  <TabsTrigger
                    data-testid="tabsTriggerSubscriptionDetails"
                    value="details"
                    className="focus-visible:ring-indigo-300"
                  >
                    {t('ssp:pages.subscription.details.tabs.subscriptionDetails.title')}
                  </TabsTrigger>
                  {hasManagedSubscriptionTab ? (
                    <TabsTrigger
                      value="manage"
                      data-testid="tabsTriggerManageSubscription"
                      className="focus-visible:ring-indigo-300"
                    >
                      {t('ssp:pages.subscription.details.tabs.manageSubscription.title')}
                    </TabsTrigger>
                  ) : null}
                </TabsList>
                <TabsContent
                  data-testid="tabsContentSubscriptionDetails"
                  value="details"
                  className="focus-visible:ring-indigo-300"
                >
                  <div className="flex flex-col gap-8 px-4 pb-4 pt-10">
                    {subscription?.hasSummary ? (
                      <div className="w-full lg:w-6/12">
                        <Summary
                          subscriptionId={subscription?.subscriptionId}
                          quantity={quantity}
                        />
                      </div>
                    ) : null}
                    <div className="flex flex-col gap-4">
                      <p className="text-base font-semibold leading-none text-slate-800 dark:text-slate-200">
                        {t('ssp:pages.subscription.details.descriptionTitle')}
                      </p>
                      <Markdown
                        content={convertMarkdown(subscription?.description)}
                        className="product_description"
                      />
                    </div>
                  </div>
                </TabsContent>
                <TabsContent
                  value="manage"
                  className="focus-visible:ring-indigo-300"
                  data-testid="tabsContentManageSubscription"
                >
                  <div className="flex flex-col gap-y-6">
                    <HasAccess permission={Permission.SubscriptionCancel}>
                      <SubscriptionCancel onShowCancelConfirmation={handleShowCancelConfirmation} />
                    </HasAccess>
                    <SubscriptionHelp />
                  </div>
                </TabsContent>
              </Tabs>
            </Card>
          </div>
        ) : null}
      </ContainedPage>
      <SubscriptionCancelConfirmation
        visible={showCancelConfirmation}
        onSubmit={onSubmit}
        onClose={handleCloseCancelConfirmation}
      />
    </>
  )
}

export default protectPage(SubscriptionPage, Permission.SubscriptionDetails)
