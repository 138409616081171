import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import Routes from '@ssp/app/Routes'
import {
  OrganizationEmptyContainer,
  useOrganizationsBeenActivated,
} from '@ssp/modules/organization'

const OrganizationsNotActivated = () => {
  const { t } = useTranslation()

  const { isLoading, hasNoActivatedOrganizations } = useOrganizationsBeenActivated()

  const redirectToDashboard = !isLoading && !hasNoActivatedOrganizations

  if (redirectToDashboard) {
    return <Navigate replace to={Routes.Root} />
  }

  return (
    <OrganizationEmptyContainer
      title={t('ssp:pages.organizationsNotActivated.title')}
      content={t('ssp:pages.organizationsNotActivated.content')}
    />
  )
}

export default OrganizationsNotActivated
