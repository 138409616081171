import { mergeClassName } from '../../utils/mergeClassName'

type ReactDiv = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type MenuIconProps = {
  Icon: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, 'ref'>>
} & ReactDiv

const MenuIcon: React.FC<MenuIconProps> = ({ Icon, className, ...props }) => {
  return (
    <div
      className={mergeClassName(
        'flex justify-center items-center h-9 w-9 md:h-10 md:w-10 p-2 text-gray-400 dark:bg-stone-900 dark:text-gray-100 md:text-gray-400',
        className
      )}
      {...props}
    >
      <Icon className="h-7 w-7" />
    </div>
  )
}

export default MenuIcon
