import {
  PortalMetadataResponse,
  PortalMetadataImage,
  ImageType,
  Theme,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class PortalMetadataService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getPortalMetadata(
    params: {
      /**  */
      portalType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalMetadataResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/PortalMetadata';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { portalType: params['portalType'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}
