import { useQuery } from '@tanstack/react-query'

import { useCurrentUser } from '../../../modules/user'
import { canLocationMakeCallForCheckIsUserAuth } from '../../reactQuery'
import { isLoggedIn } from './authentication.queries'

export const useAuthenticationLoggedInState = (reload = false) => {
  const isEnabled = canLocationMakeCallForCheckIsUserAuth() || reload

  const { data, isLoading, isFetching } = useQuery({
    queryKey: isLoggedIn.queryKey(),
    queryFn: async () => await isLoggedIn.queryFn(),
    staleTime: isLoggedIn.staleTime,
    enabled: isEnabled,
    refetchOnWindowFocus: 'always',
  })

  return {
    isLoggedIn: !!data,
    isLoading,
    isFetching,
  }
}

export const useAuthenticationState = () => {
  const { data: user, isFetched, isLoading } = useCurrentUser()

  const { isLoggedIn } = useAuthenticationLoggedInState()

  return {
    isAuthenticated: !!user && isLoggedIn,
    isFetched: isFetched && !isLoading,
  }
}
