import { validateUuid } from '@sherweb/core/utils/uuid'

import { useParams } from '@rsp/modules/navigation'

export const useGetCatalogId = () => {
  const params = useParams()

  const catalogId = validateUuid(params.catalogId) ? String(params.catalogId) : undefined

  return { catalogId }
}
