import { EyeIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { OrganizationInvoiceQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'

export type InvoiceActionsProps = {
  invoice: OrganizationInvoiceQueryResult
  className?: string
}

export const InvoiceActions = ({ invoice, className }: InvoiceActionsProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={mergeClassName('flex flex-row gap-4', className)}
      data-testid="divInvoiceActions"
    >
      <Button asChild variant={Variant.Outline} size="sm" data-testid="btnInvoicePay">
        <Link
          to={Routes.InvoiceDetail}
          params={{
            invoiceId: invoice?.externalInvoiceId,
            integrationType: invoice?.integration,
          }}
        >
          <EyeIcon className="mr-1 h-5 w-5  hover:bg-slate-50" />
          {t('ssp:pages.invoices.list.actions.viewInvoiceDetail')}
        </Link>
      </Button>
    </div>
  )
}
