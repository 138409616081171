import { OrderSortField } from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationOrdersService } from '@sherweb/core/openapi-generated/OrganizationOrdersService'

import { Pagination } from '@sherweb/core/components/DataTable/types'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error/error'

export const organizationOrdersQueryPrefix =
  'OrganizationOrdersService/queryOrganizationOrdersAsync'

const getOrdersQueryFn = async (
  organizationId?: string,
  queryOptions?: Pagination<OrderSortField>
) => {
  if (
    !organizationId ||
    !queryOptions?.sort?.field ||
    queryOptions?.sort?.ascending === undefined
  ) {
    return await rejectMissingParameters()
  }

  return await OrganizationOrdersService.queryOrganizationOrdersAsync({
    organizationId,
    query: queryOptions,
  })
}

export const queryOrganizationOrdersAsync = {
  queryKey: (organizationId?: string, queryOptions?: Pagination<OrderSortField>) => [
    organizationOrdersQueryPrefix,
    organizationId,
    queryOptions,
  ],
  queryFn: getOrdersQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const queryOrganizationOrdersAsyncWithInfiniteScroll = {
  queryKey: (organizationId?: string, queryOptions?: Pagination<OrderSortField>) => [
    organizationOrdersQueryPrefix,
    'infiniteScroll',
    organizationId,
    queryOptions,
  ],
  queryFn: getOrdersQueryFn,
  staleTime: DEFAULT_STALE_TIME,
}

export const organizationOrderDetailsById = {
  queryKey: (organizationId?: string, orderId?: string) => [
    'OrganizationOrdersService/getOrganizationOrderDetailsById',
    organizationId,
    orderId,
  ],
  queryFn: async (organizationId?: string, orderId?: string) => {
    if (!organizationId || !orderId) {
      return await rejectMissingParameters()
    }

    return await OrganizationOrdersService.getOrganizationOrderDetailsById({
      orderId,
      organizationId,
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}
