import {
  BoardConfigurationFormType,
  BoardConfigurationTicketStatusMappingListType,
} from './hooks/useBoardConfigurationSchema'

export const DEFAULT_VALUES: BoardConfigurationFormType = {
  serviceBoard: undefined,
  ticketStatusMappingList: {},
  ticketTypes: {},
}

export const hasTicketBeenConfigured = (id?: string, configuredTicketTypes?: string[]) => {
  if (!id) {
    return false
  }

  return configuredTicketTypes?.includes?.(id) ?? false
}

export const getSelectedTicketStatusMapping = (
  ticketStatusMappingList: BoardConfigurationTicketStatusMappingListType,
  serviceBoard?: string
): Array<string | undefined> => {
  if (!serviceBoard) {
    return []
  }

  return Array.from(new Set(Object.values(ticketStatusMappingList?.[serviceBoard])))
    .flat()
    ?.filter(item => item !== undefined)
}
