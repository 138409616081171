import {
  LicensesByVendorQueryResult,
  LicenseQueryResult,
  UpdateLicenseSubscribersCommand,
  SubscriberUserDto,
  SubscribersByVendorQueryResult,
  VendorDomainResult,
  SubscriberQueryResult,
  SubscriberType,
  LicenseUpdateDto,
  LicenseUpdateStatus,
  UsedLicenseStatistics,
  UnusedLicensesStatistics,
  UnusedLicense,
  ErroneousLicenseUpdateDto,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationLicensesService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getLicensesByOrganizationId(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LicensesByVendorQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateLicenseSubscribers(
    params: {
      /**  */
      organizationId: string;
      /**  */
      command: UpdateLicenseSubscribersCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSubscribersByOrganizationId(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubscribersByVendorQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses/Subscribers';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsedLicenseStatsByOrganizationIdAsync(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UsedLicenseStatistics> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses/used-stats';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUnusedLicenseStatsByOrganizationIdAsync(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UnusedLicensesStatistics> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses/unused-stats';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refresh(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses/refresh';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getErroneousUpdatesByOrganizationId(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ErroneousLicenseUpdateDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses/erroneous-updates';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static dismissErroneousUpdate(
    params: {
      /**  */
      organizationId: string;
      /**  */
      licenseUpdateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/licenses/erroneous-updates/{licenseUpdateId}/dismiss';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{licenseUpdateId}', params['licenseUpdateId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
