import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import Spinner from '@sherweb/core/components/Spinner'
import { useLoadGoogleAnalytics } from '@sherweb/core/hooks'
import { setManifestAndDocumentMeta, usePortalMetadata } from '@sherweb/core/modules/portalMetadata'
import { PortalMode } from '@sherweb/core/modules/portalMetadata/core/portalMetadata.utils'
import { useCurrentUser } from '@sherweb/core/modules/user'

import Routes from '@rsp/app/Routes'
import { useNavigate } from '@rsp/modules/navigation'
import { useReseller, useResellerActions } from '@rsp/modules/reseller/core/reseller.hooks'

import { setDatadogUserSession } from '../../datadog'

type AppGateProps = {
  children?: React.ReactNode
}

/**
 * App Gate make sure that the required data is available before rendering app
 */
const AppGate: React.FC<AppGateProps> = ({ children }) => {
  const { t } = useTranslation()

  const user = useCurrentUser()

  const portalMetadata = usePortalMetadata({ portalMode: PortalMode.RSP })

  const { navigate } = useNavigate()

  const { isLoading: isLoadingReseller, data: reseller } = useReseller()

  const { setSelectedReseller } = useResellerActions()

  const { sendPageViewEvent } = useLoadGoogleAnalytics()

  useEffect(() => {
    sendPageViewEvent()
  }, [sendPageViewEvent])

  useEffect(() => {
    if (reseller) {
      setSelectedReseller(reseller.id)
    }
  }, [reseller, setSelectedReseller])

  /* Set user session for datadog */
  useEffect(() => {
    if (user?.data) {
      setDatadogUserSession(
        user.data?.id ?? '',
        `${user?.data.firstName ?? ''} ${user.data?.lastName ?? ''}`,
        user.data.email ?? ''
      )
    }
  }, [user?.data])

  useEffect(() => {
    if (!!reseller?.id && !reseller?.isOnboarded && !isLoadingReseller) {
      navigate(Routes.Onboarding)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reseller?.isOnboarded, isLoadingReseller])

  /* set manifest */
  useEffect(() => {
    setManifestAndDocumentMeta(PortalMode.RSP, portalMetadata?.data, t('core:title.rsp'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalMetadata?.data?.icon, portalMetadata?.data?.name])

  const loadingApp = user.isLoading && isLoadingReseller

  if (loadingApp) {
    return <Spinner floating />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : <Outlet />
}

export default AppGate
