import { ArrowLeftIcon } from '@heroicons/react/24/outline'

type BacklinkProps = {
  children: React.ReactNode
}

const Backlink: React.FC<BacklinkProps> = ({ children }) => {
  return (
    <div className="mb-4 inline-flex flex-nowrap text-sm font-medium text-gray-500 dark:text-zinc-500 md:mb-2">
      <ArrowLeftIcon
        className="mr-0.5 w-4 text-gray-500 hover:bg-slate-50"
        data-testid="backlink-icon"
      />
      {children}
    </div>
  )
}

export default Backlink
