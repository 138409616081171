/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* istanbul ignore next ; ignore image manipulation */
export const getTrimmedCanvas = (canvas: HTMLCanvasElement) => {
  const context = canvas.getContext('2d')
  const copy = document.createElement('canvas').getContext('2d')

  if (!context || !copy) {
    throw new Error()
  }

  const pixels = context.getImageData(0, 0, canvas.width, canvas.height)
  const length = pixels.data.length
  const bound: Record<string, number | null> = {
    top: null,
    bottom: null,
  }

  let i, y

  for (i = 0; i < length; i += 4) {
    if (pixels.data[i + 3] !== 0) {
      y = ~~(i / 4 / canvas.width)

      if (bound.top === null) {
        bound.top = y
      }

      if (bound.bottom === null || bound.bottom < y) {
        bound.bottom = y
      }
    }
  }

  const trimHeight = bound.bottom! - bound.top!
  const trimmed = context.getImageData(0, bound.top!, canvas.width, trimHeight + 1)

  copy.canvas.height = trimHeight
  copy.putImageData(trimmed, 0, 0)

  return copy.canvas
}
