import { AnyAction } from 'redux'
import { ThunkAction as ReduxThunkAction, ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk'

import config from '@config'

import { createStore as coreCreateStore } from '@sherweb/core/modules/redux'

import mainReducer from '@ssp/app/mainReducer'

const { isProduction } = config

export const createStore = (defaultState?: any) => {
  return coreCreateStore({
    mainReducer,
    devTools: !isProduction,
    defaultState,
  })
}

export const { store, persistor } = createStore()

/* infer store type */

export type StoreState = ReturnType<typeof store.getState>

export type ThunkAction<ReturnType> = ReduxThunkAction<ReturnType, StoreState, unknown, AnyAction>
export type ThunkDispatch = ReduxThunkDispatch<StoreState, unknown, AnyAction>

export default store
