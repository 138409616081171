export enum ACTION_TYPES {
  SET_SELECTED_RESELLER = 'SET_SELECTED_RESELLER',
}

export const setSelectedReseller = (id: string) => ({
  type: ACTION_TYPES.SET_SELECTED_RESELLER as const,
  id,
})

export type Actions = ReturnType<typeof setSelectedReseller>
