import DOMPurify from 'dompurify'

import { mergeClassName } from '../../utils/mergeClassName'
import { TypographyProps, typographyVariants } from '../Typography'

interface MarkdownProps extends Omit<TypographyProps, 'children'> {
  content: string
}

export const Markdown = ({ className, content, ...restProps }: MarkdownProps) => {
  const sanitizedHTML = DOMPurify.sanitize(content, {
    ADD_ATTR: ['target'],
  })

  return (
    <div
      data-testid="markdown-viewer"
      className={mergeClassName('markdown-content', typographyVariants(restProps), className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
    />
  )
}
