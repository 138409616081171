import {
  SubscriptionQueryResult,
  CommitmentTermType,
  BillingCycle,
  Status,
  Money,
  Currency,
  Order,
  OrderStatus,
  OrderError,
  OrderApprovalSettings,
  CountResult,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationSubscriptionsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getSubscriptionsByOrganizationId(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubscriptionQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/subscriptions';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSubscriptionCountByOrganizationAsync(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CountResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/subscriptions/count';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}
