import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'
import { ElementType } from 'react'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type ListItemProps = {
  children?: React.ReactNode
  className?: string
  asChild?: boolean
  as?: ElementType
}

type ListProps = {
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

export const listItemVariants = cva(
  'font-regular flex w-full space-x-3 border-t border-slate-300 p-4 text-sm text-gray-900 first:rounded-t-[0.2rem] first:border-none last:rounded-b-[0.2rem] hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:border-slate-600 dark:text-white'
)

const ListActions = ({ children }: ListItemProps) => {
  return <div className="mr-4 flex items-center">{children}</div>
}

const List = ({ children, className = '', onClick, dataTestId }: ListProps & DataTestId) => {
  return (
    <ul
      data-testid={dataTestId}
      className={mergeClassName('border border-t border-slate-300 rounded', className)}
      onClick={onClick}
    >
      {children}
    </ul>
  )
}

const ListItem = ({
  asChild,
  as,
  children,
  className = '',
  ...props
}: ListItemProps & React.HTMLAttributes<HTMLLIElement>) => {
  const Component = asChild ? Slot : as ?? 'li'

  return (
    <Component {...props} className={mergeClassName(listItemVariants(), className)}>
      {children}
    </Component>
  )
}

List.Item = ListItem
List.Actions = ListActions

export default List
