import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import LoaderButton from '@sherweb/core/components/LoaderButton'
import Tooltip from '@sherweb/core/components/Tooltip'

type CreateSubscriberFormActionsProps = {
  isDisabled?: boolean
  isUpdating?: boolean
  onCancel: () => void
}

export const CreateSubscriberFormActions = ({
  isDisabled,
  isUpdating = false,
  onCancel,
}: CreateSubscriberFormActionsProps) => {
  const { t } = useTranslation()

  const createSubscriberButtonContent = () => {
    const createSubscriberTranslateElement = (
      <div>
        {isUpdating
          ? t('ssp:pages.licenses.subscribersDialog.creatingSubscriberButton')
          : t('ssp:pages.licenses.subscribersDialog.createSubscriberButton')}
      </div>
    )

    // Create subscriber button is disabled
    if (isDisabled) {
      if (isUpdating) {
        return createSubscriberTranslateElement
      } else {
        return (
          <Tooltip
            data-testid="tooltip"
            tooltip={t('ssp:pages.licenses.subscribersDialog.confirmationButtonDisableMessage')}
          >
            <span>{t('ssp:pages.licenses.subscribersDialog.createSubscriberButton')}</span>
            <InformationCircleIcon
              data-testid="information-circle-icon"
              className="ml-1 h-5 w-5 stroke-1 dark:stroke-2"
            />
          </Tooltip>
        )
      }
    }

    // Create subscriber button is enabled
    return createSubscriberTranslateElement
  }

  return (
    <div className="mt-4 grid w-full grid-flow-col">
      <div className="flex gap-4 sm:flex-row sm:justify-between">
        <Button
          variant="outline"
          className="w-full"
          size="sm"
          type="button"
          data-testid="btnCancelCreateSubscriber"
          onClick={() => {
            onCancel()
          }}
        >
          {t('ssp:actions.cancel')}
        </Button>
        <LoaderButton
          type="submit"
          className="w-full"
          disabled={isDisabled}
          dataTestId="btnCreateSubscriber"
          isLoading={isUpdating}
        >
          {createSubscriberButtonContent()}
        </LoaderButton>
      </div>
    </div>
  )
}
