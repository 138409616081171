import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ResellerUserQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import Card from '@sherweb/core/components/Card'
import DataTable from '@sherweb/core/components/DataTable'
import { Columns } from '@sherweb/core/components/DataTable/types'
import { SkeletonTable } from '@sherweb/core/components/Skeleton'
import Spinner from '@sherweb/core/components/Spinner'
import { spinnerStyles } from '@sherweb/core/components/Spinner/spinner.styles'
import { isRequestLoading, isRequestRefetching } from '@sherweb/core/modules/reactQuery'
import { UserDeleteConfirmationDialog } from '@sherweb/core/modules/user/ui/UserDeleteConfirmationDialog'
import { UserName } from '@sherweb/core/modules/user/ui/UserName'
import { UserRoles } from '@sherweb/core/modules/user/ui/UserRoles'
import { UserStatus } from '@sherweb/core/modules/user/ui/UserStatus'
import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

import { usePermissionCheck } from '@rsp/modules/authorization'
import {
  useDeleteResellerUserMutation,
  useGetResellerAdminsAndGuestsQuery,
} from '@rsp/modules/users'

import { UserTableActionRow, UserTableActionRowProps } from './UserTableActionRow'

const COMMON_PROPS = {
  enableSorting: true,
  meta: { className: 'text-center' },
}

const renderEmailRowContent = (user?: ResellerUserQueryResult) => (
  <UserName firstName={user?.firstName} lastName={user?.lastName} email={user?.email} />
)

const renderRole = (user?: ResellerUserQueryResult) => <UserRoles roles={user?.userRole} />

const renderStatus = (user?: ResellerUserQueryResult) => <UserStatus status={user?.status} />

const renderActionRowContent = ({
  user,
  deleteUserMutation,
  setSelectedUser,
}: UserTableActionRowProps) => (
  <UserTableActionRow
    user={user}
    setSelectedUser={setSelectedUser}
    deleteUserMutation={deleteUserMutation}
  />
)

export const UsersTable = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [selectedUser, setSelectedUser] = useState<ResellerUserQueryResult | null>(null)

  const resellerAdminsAndGuestsQuery = useGetResellerAdminsAndGuestsQuery()

  const deleteUserMutation = useDeleteResellerUserMutation()

  const formatDate = useDateFormatter(language)

  const formatDateTime = useDateFormatter(language, dateFormatTypes.datetime)

  const { permissionsLoading } = usePermissionCheck()

  const isUserUpdating =
    deleteUserMutation?.isLoading || isRequestRefetching(resellerAdminsAndGuestsQuery)

  const columns: Columns<ResellerUserQueryResult> = [
    {
      id: 'email',
      accessorFn: row => `${String(row.firstName)} ${String(row.lastName)}  ${String(row.email)}`,
      header: t('rsp:pages.users.columns.user'),
      enableSorting: true,
      cell: ({ row }) => renderEmailRowContent(row.original),
      minSize: 400,
    },
    {
      ...COMMON_PROPS,
      accessorKey: 'userRole',
      header: t('rsp:pages.users.columns.role'),
      cell: ({ row }) => renderRole(row.original),
      minSize: 278,
    },
    {
      ...COMMON_PROPS,
      accessorKey: 'createdAt',
      header: t('rsp:pages.users.columns.createdOn'),
      cell: ({ getValue }) => formatDate(getValue<string>()),
      minSize: 278,
    },
    {
      ...COMMON_PROPS,
      accessorKey: 'lastSeen',
      header: t('rsp:pages.users.columns.lastSeen'),
      cell: ({ getValue }) => formatDateTime(getValue<string>()),
      minSize: 350,
    },
    {
      ...COMMON_PROPS,
      accessorKey: 'status',
      header: t('rsp:pages.users.columns.status'),
      cell: ({ row }) => renderStatus(row.original),
      minSize: 278,
    },
    {
      ...COMMON_PROPS,
      enableSorting: false,
      id: 'actions',
      cell: ({ row }) =>
        renderActionRowContent({
          user: row.original,
          deleteUserMutation,
          setSelectedUser,
        }),
      size: 139,
    },
  ]

  return (
    <>
      <Card padded className={isUserUpdating ? spinnerStyles({ type: 'layOverBackground' }) : ''}>
        {isUserUpdating ? <Spinner className={spinnerStyles({ type: 'layOverSpinner' })} /> : null}
        {isRequestLoading(resellerAdminsAndGuestsQuery) || permissionsLoading ? (
          <SkeletonTable />
        ) : (
          <DataTable
            dataTestId="tableUsers"
            columns={columns}
            data={resellerAdminsAndGuestsQuery?.data}
            emptyMessage={t('rsp:pages.users.noResult')}
            filterableFields={['email', 'jobTitle']}
            filterPlaceholder={t('rsp:pages.users.search.placeholder')}
            fieldDescription={t('rsp:pages.users.search.placeholderDescription')}
          />
        )}
      </Card>
      {selectedUser ? (
        <UserDeleteConfirmationDialog
          open
          selectedUser={selectedUser}
          onClose={() => setSelectedUser(null)}
          onConfirm={() => {
            deleteUserMutation.mutate(selectedUser?.id)
          }}
        />
      ) : null}
    </>
  )
}
