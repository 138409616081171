/* eslint-disable tailwindcss/classnames-order */
import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'
import { ElementType, ReactNode } from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

export const typographyVariants = cva('m-0 p-0', {
  variants: {
    variant: {
      heading1: 'text-5xl sm:text-6xl leading-none tracking-tight',
      heading2: 'text-[2.5rem] sm:text-5xl leading-12 sm:leading-none tracking-tight',
      heading3:
        'text-[2rem] sm:text-[2.5rem] leading-10 sm:leading-12 tracking-tight sm:tracking-tight',
      heading4: 'text-2xl sm:text-[2rem] leading-8 sm:leading-10 tracking-tight',
      heading5: 'text-xl sm:text-2xl leading-6 sm:leading-8',
      heading6: 'text-base sm:text-xl leading-6 tracking-normal sm:tracking-tight',
      body1: 'text-base leading-6 tracking-wide',
      body2: 'text-sm leading-5 tracking-wide',
      label: 'text-xs leading-4 tracking-normal',
    },
    weight: {
      default: 'font-normal',
      medium: 'font-medium',
      semiBold: 'font-semibold',
      bold: 'font-bold',
      extraBold: 'font-extrabold',
    },
    colors: {
      default: 'text-slate-900 dark:text-slate-200',
      light: 'text-slate-500 dark:text-slate-200',
      white: 'text-white',
    },
  },
  defaultVariants: {
    variant: 'body1',
    colors: 'default',
    weight: 'default',
  },
})

type VariantPropType = VariantProps<typeof typographyVariants>

export interface TypographyProps extends VariantPropType {
  className?: string
  children: ReactNode
  asChild?: boolean
  as?: ElementType
}

export const Typography = ({
  className,
  children,
  variant,
  as,
  asChild,
  colors,
  weight,
  ...restProps
}: TypographyProps) => {
  const Component = asChild ? Slot : as ?? 'p'

  return (
    <Component
      className={mergeClassName(typographyVariants({ variant, weight, colors }), className)}
      {...restProps}
    >
      {children}
    </Component>
  )
}

Typography.displayName = 'Typography'
