import { OrganizationLicensesService } from '@sherweb/core/openapi-generated/OrganizationLicensesService'

import { UpdateLicenseSubscribersPayload } from './license.type'

export const refreshData = {
  mutationFn: async (organizationId: string) => {
    return await OrganizationLicensesService.refresh({
      organizationId,
    })
  },
}

export const updateLicenseSubscribers = {
  mutationFn: async (organizationId: string, data: UpdateLicenseSubscribersPayload) => {
    return await OrganizationLicensesService.updateLicenseSubscribers({
      organizationId,
      command: {
        ...data,
      },
    })
  },
}

export const dismissLicenseUpdate = {
  mutationFn: async (organizationId: string, licenseUpdateId: string) => {
    return await OrganizationLicensesService.dismissErroneousUpdate({
      organizationId,
      licenseUpdateId,
    })
  },
}
