import { useTranslation } from 'react-i18next'

import { InvoiceStatus } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import { DrawerFooter } from '@sherweb/core/components/Drawer'
import LoaderButton from '@sherweb/core/components/LoaderButton'

import { useGetInvoiceDetailsAsyncQuery } from '@ssp/modules/invoices/core/invoices.hooks'

import { useGetPagetParams } from './hooks/useGetPagetParams'
import { useRedirectToInvoicesPage } from './hooks/useRedirectToInvoicesPage'

export const InvoiceDetailFooter = () => {
  const { t } = useTranslation()

  const { invoiceId, integrationType } = useGetPagetParams()

  const invoiceDetailsAsyncQuery = useGetInvoiceDetailsAsyncQuery(invoiceId, integrationType)

  const isInvoiceNotPaid = invoiceDetailsAsyncQuery?.data?.status !== InvoiceStatus.Paid

  const handleRedirectToInvoicesPage = useRedirectToInvoicesPage()

  return (
    <DrawerFooter className="sticky bottom-2 p-4 pt-6 md:pr-12">
      <div className="flex justify-end gap-4">
        <Button
          variant={Variant.Outline}
          size="sm"
          data-testid="btnCancelInvoiceDetail"
          onClick={handleRedirectToInvoicesPage}
        >
          {t('core:actions.cancel')}
        </Button>
        {isInvoiceNotPaid ? (
          <LoaderButton
            isLoading={false}
            size="sm"
            onClick={handleRedirectToInvoicesPage}
            data-testid="btnPayInvoice"
          >
            {t('ssp:pages.invoices.detail.actions.payInvoice')}
          </LoaderButton>
        ) : null}
      </div>
    </DrawerFooter>
  )
}
