export type DataTableCellContentProps<T = string> = {
  content: T
  className?: string
}

export const DataTableCellContent = <T extends string>({
  content,
  className = '',
}: DataTableCellContentProps<T>) => {
  return <div className={className}>{content}</div>
}
