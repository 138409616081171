import { mergeClassName } from '../../../utils/mergeClassName'
import { Typography, TypographyProps } from '../../Typography'

type CardTitleProrps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> &
  TypographyProps

const CardTitle = ({ children, ...props }: CardTitleProrps) => {
  return (
    <Typography
      variant="body1"
      weight="semiBold"
      className={mergeClassName(
        'truncate text-sm sm:text-base font-semibold text-slate-900 dark:text-slate-200 text-wrap',
        props?.className
      )}
      as="h3"
      {...props}
    >
      {children}
    </Typography>
  )
}

export default CardTitle
