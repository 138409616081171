import CoreHeader from '@sherweb/core/components/Header'
import { withLazyLoad } from '@sherweb/core/hoc'
import Notifications, { NotificationsScheme } from '@sherweb/core/modules/notifications'

import UserMenu from '@ssp/components/layout/UserMenu'
import { useOrganizationsBeenActivated } from '@ssp/modules/organization/core/organization.hooks'
import OrganizationSwitcher from '@ssp/modules/organization/ui/OrganizationSwitcher'
import { useThemeDarkModeState } from '@ssp/modules/theme'

type HeaderProps = {
  onHamburgerButtonClick: () => void
}

const Header = ({ onHamburgerButtonClick }: HeaderProps) => {
  const isDarkMode = useThemeDarkModeState()

  const { hasNoActivatedOrganizations } = useOrganizationsBeenActivated()

  return (
    <CoreHeader
      onHamburgerButtonClick={!hasNoActivatedOrganizations ? onHamburgerButtonClick : undefined}
      rightSection={
        <div className="flex flex-row gap-4">
          {withLazyLoad(
            <Notifications
              className="self-center"
              mode={isDarkMode ? NotificationsScheme.Dark : NotificationsScheme.Light}
            />,
            { showLoader: false }
          )}
          <UserMenu />
        </div>
      }
    >
      {!hasNoActivatedOrganizations ? <OrganizationSwitcher /> : null}
    </CoreHeader>
  )
}

export default Header
