import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'

import { API_INFORMATION_HELP_LINK } from './apiInformation.helper'

type ApiInformationConfigurationBlockProps = {
  className?: string
  children?: React.ReactNode
}

export const ApiInformationConfigurationBlock = ({
  className = '',
  children,
}: ApiInformationConfigurationBlockProps) => {
  const { t } = useTranslation()

  return (
    <FormSplitScreen.Wrapper>
      <FormSplitScreen.RowCard
        contentContainerClassName={mergeClassName(
          'md:max-w-md max-w-full bg-white/50 gap-y-2 dark:bg-slate-950',
          className
        )}
        leftContent={
          <FormSplitScreen.LeftContent
            label={t(
              'rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.title'
            )}
            subLabel={
              <Trans i18nKey="rsp:pages.integrations.helpdesk.connectWise.apiInformation.configuration.description">
                <Link
                  to={API_INFORMATION_HELP_LINK}
                  target="_blank"
                  data-testid="linkApiInformationHelp"
                  className="font-semibold text-indigo-600 hover:underline"
                />
              </Trans>
            }
          />
        }
      >
        {children}
      </FormSplitScreen.RowCard>
    </FormSplitScreen.Wrapper>
  )
}
