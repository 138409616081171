import { useTranslation } from 'react-i18next'

import Backlink from '@sherweb/core/components/Backlink'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'

import { protectPage } from '@rsp/app/ProtectedPage'
import Routes from '@rsp/app/Routes'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import Link from '@rsp/components/Link'

import SherwebServiceProvider from './SherwebServiceProvider'

export const SherwebServiceProviderPage = () => {
  const { t } = useTranslation()

  return (
    <ContainedPage>
      <div className="mb-8 sm:mb-16">
        <Link to={Routes.Integrations} data-testid="linkBackToIntegrations">
          <Backlink>{t('rsp:pages.integrations.backLink')}</Backlink>
        </Link>
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageSherwebServiceProviderTitle">
              {t('rsp:pages.integrations.serviceProvider.sherweb.title')}
            </PageTitle>
          }
          subTitle={
            <PageSubTitle>
              {t('rsp:pages.integrations.serviceProvider.sherweb.subTitle')}
            </PageSubTitle>
          }
        />
      </div>
      <SherwebServiceProvider />
    </ContainedPage>
  )
}

export default protectPage(SherwebServiceProviderPage, [])
