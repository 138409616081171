import { ArrowTopRightOnSquareIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DetailedOrganizationListResult } from '@sherweb/core/openapi-generated/index.defs'

import Button, { Variant } from '@sherweb/core/components/Button'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import Routes from '@rsp/app/Routes'
import Link from '@rsp/components/Link'
import { useResetOrganizationSettings } from '@rsp/modules/organizations'
import { GoToOrganizationLink } from '@rsp/pages/organizations/components/GoToOrganizationLink'
import { ResetOrganizationSettingsConfirmationDialog } from '@rsp/pages/organizations/components/ResetOrganizationSettingsConfirmationDialog'

export type OrganizationActionsProps = {
  organization: DetailedOrganizationListResult
  className?: string
}

export const OrganizationActions = ({ organization, className }: OrganizationActionsProps) => {
  const { t } = useTranslation()

  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)

  const resetOrganizationSettings = useResetOrganizationSettings(organization?.organizationId)

  const openConfirmationDialog = () => {
    setConfirmationDialogOpened(true)
  }

  const cancelConfirmation = () => {
    setConfirmationDialogOpened(false)
  }

  const onSubmit = () => {
    resetOrganizationSettings.mutate()
  }

  return (
    <>
      <div
        className={mergeClassName('flex flex-row gap-4', className)}
        data-testid="actionsOrganizationsMobile"
      >
        {!organization?.usesDefaultSettings && (
          <Button
            variant={Variant.Outline}
            size="sm"
            data-testid="btnResetSettings"
            onClick={openConfirmationDialog}
          >
            {t('rsp:pages.organizations.list.actions.resetSettings')}
          </Button>
        )}
        <Button asChild variant={Variant.Outline} size="sm" data-testid="btnViewSettings">
          <Link
            to={Routes.Organization}
            params={{ organizationId: organization.organizationId ?? '' }}
          >
            <Cog6ToothIcon className="mr-1 h-5 w-5  hover:bg-slate-50" />
            {t('rsp:pages.organizations.list.actions.viewSettingsMobile')}
          </Link>
        </Button>
        <Button asChild variant={Variant.Outline} size="sm">
          <GoToOrganizationLink
            dataTestId="btnOpenOrganization"
            organizationUniqueName={organization?.uniqueName}
          >
            <>
              <ArrowTopRightOnSquareIcon className="mr-1 h-5 w-5  hover:bg-slate-50" />
              {t('rsp:pages.organizations.list.actions.openOrganizationMobile')}
            </>
          </GoToOrganizationLink>
        </Button>
      </div>
      <ResetOrganizationSettingsConfirmationDialog
        cancelConfirmation={cancelConfirmation}
        onSubmit={onSubmit}
        confirmationDialogOpened={confirmationDialogOpened}
      />
    </>
  )
}
