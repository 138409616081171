import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import { useAuthenticationState } from '@sherweb/core/modules/authentication'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import FullWidthPage from '@ssp/components/layout/FullWidthPage/FullWidthPage'
import Link from '@ssp/components/Link'

type Error404Props = {
  className?: string
  fullScreen?: boolean
  showSidebar?: boolean
}

const Error404: React.FC<Error404Props> = ({
  className,
  fullScreen = false,
  showSidebar = false,
}) => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuthenticationState()

  const Wrapper = fullScreen || !isAuthenticated ? FullWidthPage : ContainedPage

  return (
    <Wrapper className="dark:bg-slate-900" showSidebar={showSidebar}>
      <div data-testid="error-404" className={mergeClassName('h-full', className)}>
        <div className="mx-auto flex max-w-2xl flex-col items-center text-center">
          <ExclamationTriangleIcon className="mx-auto mt-20 w-12 text-red-600 sm:mt-24" />

          <p className="text-base font-semibold leading-8 dark:text-white">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
            {t('ssp:pages.404.title')}
          </h1>
          <p className="mt-4 text-base leading-7 text-gray-600 dark:text-white sm:mt-6 sm:text-lg sm:leading-8">
            {t('ssp:pages.404.description')}
          </p>

          <Button asChild className="mt-8">
            <Link to={Routes.Root}>{t('ssp:pages.404.backToHomepage')}</Link>
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default Error404
