import React from 'react'

import { mergeClassName } from '../../utils/mergeClassName'

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder'> {
  placeholder?: string | null
}

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type = 'text', placeholder, value, ...props }, ref) => (
    <input
      type={type}
      className={mergeClassName(
        'w-full text-sm rounded-md border border-slate-300 px-3 py-1.5 placeholder:text-gray-400 text-slate-900',
        'dark:border-gray-700 dark:bg-slate-950 dark:text-slate-200 dark:placeholder:text-gray-600',
        'disabled:bg-slate-100 disabled:select-none',
        className
      )}
      ref={ref}
      placeholder={placeholder ?? ''}
      value={value ?? ''}
      {...props}
    />
  )
)
FormInput.displayName = 'Input'

export { FormInput }
