import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button/Button'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import FullWidthPage from '@ssp/components/layout/FullWidthPage'

type Error500Props = {
  className?: string
}

const Error500 = ({ className }: Error500Props) => {
  const { t } = useTranslation()

  return (
    <FullWidthPage className="dark:bg-slate-900">
      <div data-testid="error-500" className={mergeClassName('h-full', className)}>
        <div className="mx-auto flex max-w-2xl flex-col items-center text-center">
          <ExclamationTriangleIcon className="mx-auto mt-20 w-12 text-red-600 sm:mt-24" />

          <p className="text-base font-semibold leading-8 dark:text-white">500</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">
            {t('ssp:pages.500.title')}
          </h1>
          <p className="mt-4 text-base leading-7 text-gray-600 dark:text-white sm:mt-6 sm:text-lg sm:leading-8">
            {t('ssp:pages.500.description')}
          </p>

          <Button asChild className="mt-8">
            <a href="/">{t('ssp:pages.500.reloadApplication')}</a>
          </Button>
        </div>
      </div>
    </FullWidthPage>
  )
}

export default Error500
