import * as z from 'zod'

import { looseOptional } from '@sherweb/core/types/zod'

import { DESCRIPTION_MAX_CHARACTERS, TITLE_MAX_CHARACTERS } from '../../helpdesk.const'

export type HelpDeskCreateTicketFormType = z.infer<ReturnType<typeof useHelpDeskCreateTicketSchema>>

export const useHelpDeskCreateTicketSchema = () => {
  return z.object({
    ticketTypeId: looseOptional(z.string()).refine(
      value => value !== undefined,
      'core:formValidation.required'
    ),
    title: z
      .string()
      .trim()
      .min(1, { message: 'core:formValidation.required' })
      .max(TITLE_MAX_CHARACTERS, { message: 'ssp:pages.helpdesk.create.titleMaxCharactersError' }),
    description: looseOptional(
      z.string().trim().max(DESCRIPTION_MAX_CHARACTERS, {
        message: 'ssp:pages.helpdesk.create.descriptionMaxCharactersError',
      })
    ),
  })
}
