import { useCallback } from 'react'
import { Location, useBlocker } from 'react-router-dom'

interface BlockerProps {
  nextLocation: Location
  currentLocation: Location
}

type UseCustomBlockerProps = {
  isDirty: boolean
  noConfirmationWithChangeOfQueryParamter: boolean
}

export const useCustomBlocker = ({
  isDirty,
  noConfirmationWithChangeOfQueryParamter,
}: UseCustomBlockerProps) => {
  const shouldBlock = useCallback(
    ({ nextLocation, currentLocation }: BlockerProps) => {
      if (noConfirmationWithChangeOfQueryParamter) {
        const pathChanged = currentLocation.pathname !== nextLocation.pathname

        return pathChanged && isDirty
      }

      return isDirty
    },
    [noConfirmationWithChangeOfQueryParamter, isDirty]
  )

  return useBlocker(shouldBlock)
}
